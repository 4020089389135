import dayjs from 'dayjs'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'

import { Schemas } from '~/clients/api-client'
import { Button, HeroDialog } from '~/components'
import { HeroWeekPicker } from '~/hero-ui/stories/HeroWeekPicker/HeroWeekPicker'
import { createPlanningPeriod, updatePlanningPeriod } from '~/store/di-entity.api'
import { selectPlanningPeriods, selectUnavailableDates } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'

import { getNumberOfDistinctWeeks } from './utils'

type Props = {
    id?: number
    open: boolean
    onClose: () => void
}

const initialData: Partial<Schemas['PlanningPeriodCreate']> = {
    name: '',
    is_published: false,
    start_date: undefined,
    end_date: undefined,
}

export function CreatePeriodDialog({ id, open, onClose }: Props) {
    const addToast = useStore(state => state.toaster.actions.addToast)
    const unavailableDates = useStore(selectUnavailableDates)
    const planningPeriods = useStore(selectPlanningPeriods)
    const planningPeriod = id ? planningPeriods.byId(id) : undefined

    const defaultMonth = planningPeriod ? dayjs(planningPeriod.start_date).toDate() : undefined

    function getInitialData() {
        if (!planningPeriod) {
            return initialData
        }

        return {
            name: planningPeriod.name,
            is_published: planningPeriod.is_published,
            start_date: planningPeriod.start_date,
            end_date: planningPeriod.end_date,
        }
    }

    const [data, setData] = useState<Partial<Schemas['PlanningPeriodCreate']>>(getInitialData())

    function onRangeSelected({ from, to }: DateRange) {
        setData({
            ...data,
            name: `Periode U${dayjs(from).isoWeek()}`,
            start_date: dayjs(from).format('YYYY-MM-DD'),
            end_date: dayjs(to).format('YYYY-MM-DD'),
        })
    }

    async function submit() {
        const { name, start_date, end_date, is_published } = data

        if (!start_date) {
            addToast({ type: 'error', message: 'Du må velge en startdato' })
            return
        }

        if (!end_date) {
            addToast({ type: 'error', message: 'Du må velge en sluttdato' })
            return
        }

        if (!name) {
            addToast({ type: 'error', message: 'Du må gi perioden et navn' })
            return
        }

        // Updates existing planning period if id is provided
        if (id && planningPeriod) {
            // The initial form data is used for tracking the previous dates
            const previousDates = {
                previous_start_date: planningPeriod.start_date ?? '',
                previous_end_date: planningPeriod.end_date ?? '',
            }

            await updatePlanningPeriod(id, {
                name,
                is_published,
                start_date,
                end_date,
            })

            void trackUserEvent('PP_PLANNING_PERIOD_EDITED', {
                ...previousDates,
                updated_start_date: start_date,
                updated_end_date: end_date,
            })

            addToast({ type: 'success', message: 'Periode oppdatert' })
        } else {
            await createPlanningPeriod({
                name,
                is_published,
                start_date,
                end_date,
            })

            void trackUserEvent('PP_PLANNING_PERIOD_ADDED', {
                start_date,
                end_date,
                number_of_weeks: getNumberOfDistinctWeeks(start_date, end_date),
            })

            addToast({ type: 'success', message: 'Periode opprettet' })
            setData(initialData)
        }

        onClose()
    }

    return (
        <HeroDialog isOpen={open} onCloseDialog={onClose} title={`${planningPeriod ? 'Rediger' : 'Ny'} planleggingsperiode`}>
            <div data-test="planning-period-form" className="flex min-w-[640px] max-w-full flex-col gap-4">
                <p className="text-lg">Periode U{dayjs(data.start_date).isoWeek()}</p>
                <div className="rounded-lg border">
                    <HeroWeekPicker
                        mode="range"
                        weekdaysOnly
                        defaultMonth={defaultMonth}
                        disabled={id ? unavailableDates.excludeById(id) : unavailableDates.all()}
                        selected={{
                            from: data.start_date ? new Date(data.start_date) : undefined,
                            to: data.end_date ? new Date(data.end_date) : undefined,
                        }}
                        onSelect={onRangeSelected}
                        numberOfMonths={2}
                        required
                    />
                </div>
                <div className="flex items-center justify-end gap-4">
                    <Button color="white" onClick={onClose}>
                        Avbryt
                    </Button>
                    <Button onClick={submit} data-test="planning-period-submit-button">
                        {id ? 'Oppdater' : 'Opprett'}
                    </Button>
                </div>
            </div>
        </HeroDialog>
    )
}
