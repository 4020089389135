import { ComponentProps, FC } from 'react'

export const CalendarIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33327 1.35001C5.69225 1.35001 5.98327 1.64102 5.98327 2.00001V2.68334H10.0166V2.00001C10.0166 1.64102 10.3076 1.35001 10.6666 1.35001C11.0256 1.35001 11.3166 1.64102 11.3166 2.00001V2.68334H11.9999C12.5259 2.68334 13.0304 2.8923 13.4024 3.26424C13.7743 3.63619 13.9833 4.14066 13.9833 4.66667V12.6667C13.9833 13.1927 13.7743 13.6972 13.4024 14.0691C13.0304 14.441 12.5259 14.65 11.9999 14.65H3.99993C3.47392 14.65 2.96945 14.441 2.59751 14.0691C2.22556 13.6972 2.0166 13.1927 2.0166 12.6667V4.66667C2.0166 4.14066 2.22556 3.63619 2.59751 3.26424C2.96945 2.8923 3.47392 2.68334 3.99993 2.68334H4.68327V2.00001C4.68327 1.64102 4.97428 1.35001 5.33327 1.35001ZM4.68327 3.98334H3.99993C3.8187 3.98334 3.64489 4.05533 3.51675 4.18348C3.3886 4.31163 3.3166 4.48544 3.3166 4.66667V6.68334H12.6833V4.66667C12.6833 4.48544 12.6113 4.31163 12.4831 4.18348C12.355 4.05533 12.1812 3.98334 11.9999 3.98334H11.3166V4.66667C11.3166 5.02566 11.0256 5.31667 10.6666 5.31667C10.3076 5.31667 10.0166 5.02566 10.0166 4.66667V3.98334H5.98327V4.66667C5.98327 5.02566 5.69225 5.31667 5.33327 5.31667C4.97428 5.31667 4.68327 5.02566 4.68327 4.66667V3.98334ZM12.6833 7.98334H3.3166V12.6667C3.3166 12.8479 3.3886 13.0217 3.51675 13.1499C3.6449 13.278 3.8187 13.35 3.99993 13.35H11.9999C12.1812 13.35 12.355 13.278 12.4831 13.1499C12.6113 13.0217 12.6833 12.8479 12.6833 12.6667V7.98334Z"
            fill="currentColor"
        />
    </svg>
)
