import { ComponentProps, FC } from 'react'

export const CaretRightIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.6665 9.58579C6.6665 10.4767 7.74365 10.9229 8.37361 10.2929L9.9594 8.70711C10.3499 8.31658 10.3499 7.68342 9.9594 7.29289L8.37361 5.70711C7.74364 5.07714 6.6665 5.52331 6.6665 6.41421V9.58579Z"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
