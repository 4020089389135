import { ComponentProps, FC } from 'react'

export const CircleCheckIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.45525 1.85618C6.26207 1.52198 7.12681 1.34998 8.0001 1.34998C8.87339 1.34998 9.73813 1.52198 10.5449 1.85618C11.3518 2.19037 12.0848 2.68021 12.7024 3.29772C13.3199 3.91522 13.8097 4.64832 14.1439 5.45513C14.4781 6.26195 14.6501 7.12669 14.6501 7.99998C14.6501 8.87327 14.4781 9.738 14.1439 10.5448C13.8097 11.3516 13.3199 12.0847 12.7024 12.7022C12.0848 13.3197 11.3518 13.8096 10.5449 14.1438C9.73813 14.478 8.87339 14.65 8.0001 14.65C7.12681 14.65 6.26207 14.478 5.45525 14.1438C4.64844 13.8096 3.91535 13.3197 3.29784 12.7022C2.68033 12.0847 2.19049 11.3516 1.8563 10.5448C1.5221 9.73801 1.3501 8.87327 1.3501 7.99998C1.3501 7.12669 1.5221 6.26195 1.8563 5.45513C2.19049 4.64832 2.68033 3.91523 3.29784 3.29772C3.91535 2.68021 4.64844 2.19037 5.45525 1.85618ZM8.0001 2.64998C7.29753 2.64998 6.60183 2.78836 5.95274 3.05722C5.30365 3.32608 4.71387 3.72016 4.21708 4.21695C3.72028 4.71375 3.3262 5.30353 3.05734 5.95262C2.78848 6.60171 2.6501 7.2974 2.6501 7.99998C2.6501 8.70255 2.78848 9.39824 3.05734 10.0473C3.3262 10.6964 3.72028 11.2862 4.21708 11.783C4.71387 12.2798 5.30365 12.6739 5.95274 12.9427C6.60183 13.2116 7.29753 13.35 8.0001 13.35C8.70267 13.35 9.39836 13.2116 10.0475 12.9427C10.6965 12.6739 11.2863 12.2798 11.7831 11.783C12.2799 11.2862 12.674 10.6964 12.9429 10.0473C13.2117 9.39824 13.3501 8.70255 13.3501 7.99998C13.3501 7.2974 13.2117 6.60171 12.9429 5.95262C12.674 5.30353 12.2799 4.71375 11.7831 4.21695C11.2863 3.72016 10.6965 3.32608 10.0475 3.05722C9.39836 2.78836 8.70267 2.64998 8.0001 2.64998ZM10.4597 6.20702C10.7136 6.46086 10.7136 6.87242 10.4597 7.12626L7.79305 9.79293C7.53921 10.0468 7.12765 10.0468 6.87381 9.79293L5.54048 8.4596C5.28664 8.20575 5.28664 7.7942 5.54048 7.54036C5.79432 7.28652 6.20588 7.28652 6.45972 7.54036L7.33343 8.41407L9.54048 6.20702C9.79432 5.95318 10.2059 5.95318 10.4597 6.20702Z"
            fill="currentColor"
        />
    </svg>
)
