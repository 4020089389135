import { useEffect, useMemo, useRef } from 'react'

import { useStore } from '~/store/store'

import { BookingDrawerAvailability } from './BookingDrawerAvailability'
import { BookingDrawerBookings } from './BookingDrawerBookings'
import { BookingDrawerHeader } from './BookingDrawerHeader'

export const BookingDrawer = () => {
    const drawerProps = useStore(state => state.operationalPlanner.drawerProps)
    const scrollRef = useRef<HTMLDivElement | null>(null)

    // This key makes sure that the BookingDrawer component state is reset when the user clicks on a different date/location
    const key = useMemo(() => JSON.stringify(drawerProps), [drawerProps])

    // Reset the scroll position when the user clicks on a different date/location
    // This makes sure we always trigger the IntersectionObserver in the BookingDrawerAvailability component
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0)
        }
    }, [drawerProps])

    return (
        <div className="flex h-full w-[30rem] flex-col gap-6 pl-4 pr-2" data-test="BookingDrawer" key={key}>
            <BookingDrawerHeader />
            <div className="flex flex-col gap-6 overflow-auto" ref={scrollRef}>
                <BookingDrawerBookings />
                <BookingDrawerAvailability />
            </div>
        </div>
    )
}
