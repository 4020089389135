import { immer } from 'zustand/middleware/immer'

import { PagePaths } from '~/app/routes'
import { BreadcrumbOption } from '~/hero-ui/stories/HeroBreadcrumbs/HeroBreadcrumbs'

import { Slice } from '../store'

type State = {
    activeViews: PagePaths
    breadcrumbs: BreadcrumbOption[]
    isNavSidebarOpen: boolean
}

const initialState: State = {
    activeViews: {
        ADMIN_PANEL: '/admin-panel',
        DOWNLOAD: '/download',
        SURGERY_SCHEDULER: '/surgery-scheduler',
        OPERATIONAL_PLANNER: '/or-schedule/calendar',
        SURGEON_SCHEDULER: '/surgeon-scheduler/practitioners',
        WAITING_LIST: '/patients/unscheduled',
    },
    breadcrumbs: [],
    isNavSidebarOpen: true,
}

type Actions = {
    actions: {
        setActiveViews(activeViews: Partial<State['activeViews']>): void
        setBreadcrumbs(breadcrumbs: State['breadcrumbs']): void
        setIsNavSidebarOpen(isSideBarOpen: State['isNavSidebarOpen']): void
    }
}

export type AppSlice = {
    app: State & Actions
}

export const createAppSlice: Slice<AppSlice> = immer(set => ({
    app: {
        ...initialState,
        actions: {
            setActiveViews(activeViews: Partial<State['activeViews']>) {
                set(state => {
                    state.app.activeViews = { ...state.app.activeViews, ...activeViews }
                })
            },
            setBreadcrumbs(breadcrumbs: State['breadcrumbs']) {
                set(state => {
                    state.app.breadcrumbs = breadcrumbs
                })
            },
            setIsNavSidebarOpen(isSideBarOpen: State['isNavSidebarOpen']) {
                set(state => {
                    state.app.isNavSidebarOpen = isSideBarOpen
                })
            },
        },
    },
}))
