import { ComponentProps, FC } from 'react'

export const ArrowUpIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.54048 2.8737C7.79432 2.61986 8.20588 2.61986 8.45972 2.8737L12.4597 6.8737C12.7136 7.12754 12.7136 7.5391 12.4597 7.79294C12.2059 8.04678 11.7943 8.04678 11.5405 7.79294L8.6501 4.90256V12.6667C8.6501 13.0256 8.35908 13.3167 8.0001 13.3167C7.64111 13.3167 7.3501 13.0256 7.3501 12.6667V4.90256L4.45972 7.79294C4.20588 8.04678 3.79432 8.04678 3.54048 7.79294C3.28664 7.5391 3.28664 7.12754 3.54048 6.8737L7.54048 2.8737Z"
            fill="currentColor"
        />
    </svg>
)
