import { Show } from '~/components'
import { ClockFastIcon, HomeHealthOutlined, LetterNotSentIcon, StarIcon } from '~/icons'

const formattedRemarkToRemarkKey = {
    'kort varsel': 'shortNotice',
    prioritert: 'prioritized',
    'ugyldig dag/døgn': 'dayOvernight',
    'brev ikke sendt': 'letterNotSent',
} as const

export type FormattedRemark = keyof typeof formattedRemarkToRemarkKey // Norwegian to be searchable in the table
type RemarkKey = (typeof formattedRemarkToRemarkKey)[FormattedRemark]

const iconStyles = 'h-4 w-4 shrink-0'

function getIconAndTooltip(remark: RemarkKey, iconColor: string) {
    switch (remark) {
        case 'dayOvernight':
            return {
                icon: <HomeHealthOutlined className={`${iconStyles} ${iconColor}`} />,
                tooltip: 'Ugyldig dag/døgn status. Dette bør endres i DIPS slik at videre oppfølging blir korrekt.',
            }
        case 'shortNotice':
            return { icon: <ClockFastIcon className={`${iconStyles} ${iconColor}`} />, tooltip: 'Kort varsel' }
        case 'prioritized':
            return { icon: <StarIcon className={`${iconStyles} ${iconColor}`} />, tooltip: 'Prioritert' }
        case 'letterNotSent':
            return { icon: <LetterNotSentIcon className={`${iconStyles} ${iconColor}`} />, tooltip: 'Brev ikke sendt' }
    }
}

const colors = {
    on: { bgColor: 'bg-sky-100', iconColor: 'text-black' },
    off: { bgColor: 'bg-neutral-100', iconColor: 'text-stone-400' },
    warning: { bgColor: 'bg-yellow-100', iconColor: 'text-black' },
}

type ColorKey = keyof typeof colors

type RemarkProps = {
    remark: RemarkKey
    color: ColorKey
}

export const Remark = ({ remark, color }: RemarkProps) => {
    const { bgColor, iconColor } = colors[color]
    const { icon, tooltip } = getIconAndTooltip(remark, iconColor)

    return (
        <div className={`flex h-6 w-6 items-center justify-center rounded ${bgColor}`} data-tooltip={tooltip}>
            {icon}
        </div>
    )
}

type RemarksProps = {
    remarks: FormattedRemark[]
}

export const Remarks = ({ remarks }: RemarksProps) => (
    <Show condition={remarks.length > 0}>
        <div className="flex flex-row gap-1">
            {remarks.map(remark => {
                const remarkKey = formattedRemarkToRemarkKey[remark]
                const isWarning = ['dayOvernight', 'letterNotSent'].includes(remarkKey)

                return <Remark key={`remark-${remarkKey}`} remark={remarkKey} color={isWarning ? 'warning' : 'on'} />
            })}
        </div>
    </Show>
)
