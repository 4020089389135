import { ComponentProps, FC } from 'react'

export const LetterNotSentIcon: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.64645 1.64645C1.84171 1.45118 2.15829 1.45118 2.35355 1.64645L3.6868 2.9797C3.68686 2.97975 3.68675 2.97964 3.6868 2.9797L14.3536 13.6464C14.5488 13.8417 14.5488 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3536L12.4596 13.1667H3.33333C2.8471 13.1667 2.38079 12.9735 2.03697 12.6297C1.69315 12.2859 1.5 11.8196 1.5 11.3333V4.66667C1.5 4.18044 1.69315 3.71412 2.03697 3.3703C2.14341 3.26387 2.26159 3.17187 2.38846 3.09557L1.64645 2.35355C1.45118 2.15829 1.45118 1.84171 1.64645 1.64645ZM3.14726 3.85437C2.99578 3.88907 2.85572 3.96577 2.74408 4.07741C2.64666 4.17483 2.57585 4.29389 2.53633 4.42329L6.0759 6.78301L3.14726 3.85437ZM8.33627 9.04338L8.27752 9.08258C8.10955 9.19465 7.89066 9.1947 7.72265 9.08269L2.5 5.60093V11.3333C2.5 11.5543 2.5878 11.7663 2.74408 11.9226C2.90036 12.0789 3.11232 12.1667 3.33333 12.1667H11.4596L8.33627 9.04338ZM5.5 3.33333C5.5 3.05719 5.72386 2.83333 6 2.83333H12.6667C13.1529 2.83333 13.6192 3.02649 13.963 3.3703C14.3068 3.71412 14.5 4.18044 14.5 4.66667V11.3333C14.5 11.6095 14.2761 11.8333 14 11.8333C13.7239 11.8333 13.5 11.6095 13.5 11.3333V5.60093L10.2774 7.74936C10.0476 7.90253 9.73715 7.84045 9.58398 7.61068C9.4308 7.38092 9.49289 7.07048 9.72265 6.91731L13.4637 4.42329C13.4242 4.29389 13.3533 4.17483 13.2559 4.07741C13.0996 3.92113 12.8877 3.83333 12.6667 3.83333H6C5.72386 3.83333 5.5 3.60948 5.5 3.33333Z"
        />
    </svg>
)
