import { Dayjs } from 'dayjs'

import { ChevronLeftIcon, ChevronRightIcon } from '~/icons'
import { useStore } from '~/store/store'

import { Button } from './Button'
import { MonthPicker, WeekPicker } from './Inputs/DatePickers'
import { moveByUnit, Unit } from './Inputs/DatePickers/helpers/DateSwitches'
import { TextInputSize } from './Inputs/TextInput'

type Props = {
    unit: Unit
    size?: TextInputSize
}

export const DIDatePicker = ({ unit, size = 'md' }: Props) => {
    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const { setSelectedDate } = useStore(state => state.appFilters.actions)
    const ComponentToRender = unit === 'month' ? MonthPicker : WeekPicker

    function onChange(value?: Dayjs) {
        if (value) setSelectedDate(value)
    }

    return (
        <div className="flex items-stretch justify-center gap-x-2">
            <Button size="custom" color="white" onClick={() => onChange(moveByUnit(selectedDate, 'backward', unit))}>
                <ChevronLeftIcon />
            </Button>
            <ComponentToRender placement="bottom" value={selectedDate} onChange={onChange} size={size} />
            <Button size="custom" color="white" onClick={() => onChange(moveByUnit(selectedDate, 'forward', unit))} data-test="next-week-button">
                <ChevronRightIcon />
            </Button>
        </div>
    )
}
