import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { selectEntities } from './entities'

export const selectGetRuleDefinitions = createSelector(selectEntities, ({ ruleDefinitions }) => {
    const byId = keyBy(ruleDefinitions, 'id')
    const byRuleId = keyBy(ruleDefinitions, 'rule_id')

    return {
        byId: (id: number) => byId[id],
        byRuleId: (ruleId: string) => byRuleId[ruleId],
    }
})

export const selectRuleDefinitionOptions = createSelector(selectEntities, ({ ruleDefinitions }) => {
    return ruleDefinitions.map(ruleDefinition => ({
        label: ruleDefinition.name,
        value: ruleDefinition.rule_id,
    }))
})

export type GetSelectRuleDefinitionOptions = ReturnType<typeof selectRuleDefinitionOptions>
