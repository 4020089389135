import { useState } from 'react'

import { UnpublishDialog } from '~/app/pages/PlanningPeriods/shared/UnpublishDialog'
import { getDaysUntilPeriodStarts } from '~/app/pages/PlanningPeriods/shared/utils'
import { Button } from '~/components'
import { HeroDropdownItem, HeroDropdownMenu } from '~/hero-ui/stories/HeroDropdownMenu/HeroDropdownMenu'
import { ArrowBackUpIcon, ArrowUpIcon, CheckIcon, ChevronDownIcon, EditIcon } from '~/icons'
import { updatePlanningPeriod } from '~/store/di-entity.api'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'

const iconStyles = 'w-4 h-4'

export const PublishPeriodDropdown = () => {
    const [unpublishDialogOpen, setUnpublishDialogOpen] = useState(false)

    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const period = useStore(selectPlanningPeriods).byDate(selectedDate)
    const addToast = useStore(state => state.toaster.actions.addToast)

    if (!period) return null

    async function publish() {
        if (!period) return

        await updatePlanningPeriod(period.id, { ...period, is_published: true })

        void trackUserEvent('PP_PLANNING_PERIOD_PUBLICATION_STATUS_CHANGED', {
            start_date: period.start_date,
            is_published: true,
            days_before_period_starts: getDaysUntilPeriodStarts(period),
        })

        addToast({ type: 'success', message: 'Perioden er publisert' })
    }

    const DropdownContent = () => (
        <div className="w-40">
            <HeroDropdownItem
                option={{
                    title: 'Publiser',
                    isHidden: !!period.is_published,
                    icon: () => <ArrowUpIcon className={iconStyles} />,
                    action: publish,
                }}
            />
            <HeroDropdownItem
                option={{
                    title: 'Avpubliser',
                    isHidden: !period.is_published,
                    icon: () => <ArrowBackUpIcon className={iconStyles} />,
                    action: () => setUnpublishDialogOpen(true),
                    color: 'diRed-500',
                }}
            />
        </div>
    )

    return (
        <>
            <HeroDropdownMenu align="start" className="mt-1 border" dropdownContent={<DropdownContent />}>
                <Button color={period.is_published ? 'primary' : 'white'}>
                    <div className="flex items-center gap-2">
                        {period.is_published ? <CheckIcon className={iconStyles} /> : <EditIcon className={iconStyles} />}
                        <p>{period.is_published ? 'Publisert' : 'Utkast'}</p>
                        <ChevronDownIcon className="h-4 w-4" />
                    </div>
                </Button>
            </HeroDropdownMenu>
            <UnpublishDialog id={period.id} open={unpublishDialogOpen} onClose={() => setUnpublishDialogOpen(false)} />
        </>
    )
}
