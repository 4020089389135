import { Dayjs } from 'dayjs'

import { getClient } from '~/clients/dips-client'

function errorResponse(errorMessage: string, ...optionalArgs: unknown[]) {
    console.error(errorMessage, optionalArgs)
    return { isError: true, errorMessage }
}

// should not throw
export async function findBookedSurgery(bookingId: string | undefined, surgeryDate: Dayjs): Promise<{ isError: boolean; errorMessage: string }> {
    let response
    try {
        response = await getClient().GET('/api/v1/surgery/scheduled', {
            params: {
                query: {
                    BookingId: bookingId,
                    StartTime: surgeryDate.startOf('day').format(),
                    EndTime: surgeryDate.add(1, 'day').startOf('day').format(),
                    View: 'Minimal',
                },
            },
        })
    } catch (e) {
        return errorResponse('Booking verification failed')
    }

    if (!response.data) {
        return errorResponse('Booking verification failed: no data in response')
    }

    if (response.data.length === 0) {
        return errorResponse(`Booking verification failed: booking ${bookingId} not found on ${surgeryDate.format('YYYY-MM-DD')}`)
    }

    return { isError: false, errorMessage: '' }
}
