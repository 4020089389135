import { Show } from '~/components'
import { isNotNullish } from '~/utils/guards'
import { getCheckInTime } from '~/utils/transformations/surgeryInfo'

import { columns, FormattedWaitingListItem, isUnScheduledSurgery, WaitingListItem } from '../shared/columns'

const bgColors = {
    success: 'bg-green-100 border border-green-300',
    warning: 'bg-yellow-100 border border-yellow-300',
    none: '',
}

type InfoWithLabelProps = {
    label: string
    value: string
    background?: keyof typeof bgColors
}

const InfoWithLabel = ({ label, value, background = 'none' }: InfoWithLabelProps) => (
    <div className="flex flex-col">
        <p className="font-semibold">{label}</p>
        <p className={`w-fit rounded first-letter:capitalize ${bgColors[background]} py-[1px] ${background !== 'none' ? 'px-1' : ''}`}>{value}</p>
    </div>
)

type PatientInfoProps = {
    item: FormattedWaitingListItem
    isUnscheduledItem: boolean
    surgery: WaitingListItem
}

export const PatientInfo = ({ item, isUnscheduledItem, surgery }: PatientInfoProps) => {
    const dateLabel = isUnscheduledItem ? columns.TentativeDate.label : columns.OperationDate.label
    const dateValue = isUnscheduledItem ? item.TentativeDate : item.OperationDate

    const checkInTime = getCheckInTime(surgery)

    const hasLetterStatus = !isUnScheduledSurgery(surgery) && isNotNullish(surgery.letterStatus)
    const isLetterSent = hasLetterStatus && surgery.letterStatus === 2

    const hasPostponementCount = !isUnScheduledSurgery(surgery) && isNotNullish(surgery.postponementCount)
    const postponementCount = hasPostponementCount && surgery.postponementCount

    return (
        <div className="flex flex-row gap-4 rounded-s bg-gray-50 px-2 py-4">
            <Show condition={isUnscheduledItem}>
                <InfoWithLabel label="Ventet" value={`${item.WaitingTime} dager`} />
            </Show>
            <InfoWithLabel label={dateLabel} value={dateValue || '—'} />
            <Show condition={!isUnscheduledItem}>
                <InfoWithLabel label="Oppmøtetid" value={checkInTime} />
                <Show condition={hasLetterStatus}>
                    <InfoWithLabel label="Brevstatus" value={isLetterSent ? 'Sendt' : 'Ikke sendt'} background={isLetterSent ? 'success' : 'warning'} />
                </Show>
            </Show>
            <Show condition={hasPostponementCount}>
                <InfoWithLabel label="Utsatt" value={`${postponementCount} ganger`} />
            </Show>
        </div>
    )
}
