import { ComponentProps, FC } from 'react'

export const AlertSquareRoundedIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.65972 3.6596C2.96642 4.35289 2.6501 5.59533 2.6501 7.99998C2.6501 10.4046 2.96642 11.6471 3.65972 12.3404C4.35301 13.0337 5.59545 13.35 8.0001 13.35C10.4047 13.35 11.6472 13.0337 12.3405 12.3404C13.0338 11.6471 13.3501 10.4046 13.3501 7.99998C13.3501 5.59533 13.0338 4.35289 12.3405 3.6596C11.6472 2.9663 10.4047 2.64998 8.0001 2.64998C5.59545 2.64998 4.35301 2.9663 3.65972 3.6596ZM2.74048 2.74036C3.84718 1.63365 5.60474 1.34998 8.0001 1.34998C10.3955 1.34998 12.153 1.63365 13.2597 2.74036C14.3664 3.84706 14.6501 5.60462 14.6501 7.99998C14.6501 10.3953 14.3664 12.1529 13.2597 13.2596C12.153 14.3663 10.3955 14.65 8.0001 14.65C5.60474 14.65 3.84718 14.3663 2.74048 13.2596C1.63377 12.1529 1.3501 10.3953 1.3501 7.99998C1.3501 5.60462 1.63377 3.84706 2.74048 2.74036ZM8.0001 4.68331C8.35908 4.68331 8.6501 4.97432 8.6501 5.33331V7.99998C8.6501 8.35896 8.35908 8.64998 8.0001 8.64998C7.64111 8.64998 7.3501 8.35896 7.3501 7.99998V5.33331C7.3501 4.97432 7.64111 4.68331 8.0001 4.68331ZM7.3501 10.6666C7.3501 10.3077 7.64111 10.0166 8.0001 10.0166H8.00676C8.36575 10.0166 8.65676 10.3077 8.65676 10.6666C8.65676 11.0256 8.36575 11.3166 8.00676 11.3166H8.0001C7.64111 11.3166 7.3501 11.0256 7.3501 10.6666Z"
            fill="currentColor"
        />
    </svg>
)
