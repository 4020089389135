import { Button } from '~/components'
import { ChevronLeftIcon, ChevronRightIcon } from '~/icons'

type PageButtonProps = {
    onClick: (pageNumber: number) => void
    pageNumber: number
    selectedPage: number
}

export const PageButton = ({ onClick, pageNumber, selectedPage }: PageButtonProps) => {
    const isSelectedPage = pageNumber === selectedPage

    return (
        <Button
            onClick={() => onClick(pageNumber)}
            color={isSelectedPage ? 'custom' : 'secondary'}
            className={isSelectedPage ? 'border border-diSecondary bg-violet-100' : ''}
        >
            {pageNumber}
        </Button>
    )
}

type NavigatePageButtonProps = {
    onClick: () => void
    disabled: boolean
    children: React.ReactNode
}

const NavigatePageButton = ({ children, onClick, disabled }: NavigatePageButtonProps) => (
    <Button onClick={onClick} disabled={disabled} size="custom" className="p-1" color="secondary">
        {children}
    </Button>
)

type PreviousNextPageButtonProps = Omit<NavigatePageButtonProps, 'children'>

export const PreviousPageButton = (props: PreviousNextPageButtonProps) => (
    <NavigatePageButton {...props}>
        <ChevronLeftIcon />
    </NavigatePageButton>
)

export const NextPageButton = (props: PreviousNextPageButtonProps) => (
    <NavigatePageButton {...props}>
        <ChevronRightIcon />
    </NavigatePageButton>
)
