import { ComponentProps, FC } from 'react'

export const NotificationIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.45927 2.79289C9.95624 2.29592 10.6303 2.01672 11.3331 2.01672C12.0359 2.01672 12.71 2.29592 13.2069 2.79289C13.7039 3.28986 13.9831 3.9639 13.9831 4.66672C13.9831 5.36955 13.7039 6.04359 13.2069 6.54056C12.71 7.03753 12.0359 7.31672 11.3331 7.31672C10.6303 7.31672 9.95624 7.03753 9.45927 6.54056C8.9623 6.04359 8.68311 5.36955 8.68311 4.66672C8.68311 3.9639 8.9623 3.28986 9.45927 2.79289ZM11.3331 3.31672C10.9751 3.31672 10.6317 3.45896 10.3785 3.71213C10.1253 3.9653 9.98311 4.30868 9.98311 4.66672C9.98311 5.02477 10.1253 5.36814 10.3785 5.62132C10.6317 5.87449 10.9751 6.01672 11.3331 6.01672C11.6911 6.01672 12.0345 5.87449 12.2877 5.62132C12.5409 5.36814 12.6831 5.02477 12.6831 4.66672C12.6831 4.30868 12.5409 3.9653 12.2877 3.71213C12.0345 3.45896 11.6911 3.31672 11.3331 3.31672ZM3.26401 3.93096C3.63596 3.55901 4.14043 3.35006 4.66644 3.35006H6.66644C7.02542 3.35006 7.31644 3.64107 7.31644 4.00006C7.31644 4.35904 7.02542 4.65006 6.66644 4.65006H4.66644C4.48521 4.65006 4.3114 4.72205 4.18325 4.8502C4.0551 4.97835 3.98311 5.15216 3.98311 5.33339V11.3334C3.98311 11.5146 4.0551 11.6884 4.18325 11.8166C4.3114 11.9447 4.48521 12.0167 4.66644 12.0167H10.6664C10.8477 12.0167 11.0215 11.9447 11.1496 11.8166C11.2778 11.6884 11.3498 11.5146 11.3498 11.3334V9.33339C11.3498 8.97441 11.6408 8.68339 11.9998 8.68339C12.3588 8.68339 12.6498 8.97441 12.6498 9.33339V11.3334C12.6498 11.8594 12.4408 12.3639 12.0689 12.7358C11.6969 13.1078 11.1925 13.3167 10.6664 13.3167H4.66644C4.14043 13.3167 3.63596 13.1078 3.26401 12.7358C2.89206 12.3639 2.68311 11.8594 2.68311 11.3334V5.33339C2.68311 4.80738 2.89206 4.30291 3.26401 3.93096Z"
            fill="currentColor"
        />
    </svg>
)
