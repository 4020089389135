import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

const cache = new Map<string, string>()

export function getFormattedDuration(duration: string, asClock: boolean) {
    const durationCandidate = dayjs.duration(duration)

    if (dayjs.isDuration(durationCandidate)) {
        const hours = Math.trunc(durationCandidate.hours())
        const minutes = durationCandidate.minutes() + Math.round((durationCandidate.hours() % 1) * 60)

        if (asClock) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }

        if (hours !== 0 && minutes !== 0) {
            return `${hours}t ${minutes}min`
        } else if (hours === 1) {
            return `${hours} time`
        } else if (hours !== 0) {
            return `${hours} timer`
        } else if (minutes !== 0) {
            return `${minutes} min`
        }
    }

    console.warn(`You have provided an invalid duration '${duration}'`)
    return ''
}

export function formatDurationFromIso(isoDuration: string, asClock: boolean = false): string {
    if (isoDuration === 'P0D' || isoDuration === 'P0DT0H0M0.000000S' || isoDuration === 'PT0S') {
        return '0 min'
    }

    const cached = cache.get(isoDuration)
    if (cached) return cached

    const formattedDuration = getFormattedDuration(isoDuration, asClock)

    cache.set(isoDuration, formattedDuration)

    return formattedDuration
}

export function formatDuration(duration: Duration, asClock: boolean = false): string {
    return formatDurationFromIso(duration.toISOString(), asClock)
}
