import { HomeHealthOutlined } from '~/icons'
import { getDayOvernightTooltipText, isDayOvernightLabel } from '~/store/slices/filterSlice'
import { AnyScheduledSurgery } from '~/utils/dips'

import { columns } from '../shared/columns'
import { Remark } from '../shared/Remarks'
import { Payload } from '../shared/saveSurgeryMetadata'
import { SidebarSwitch } from './shared/SidebarSwitch'

type Props = {
    surgery: AnyScheduledSurgery
    dayOvernight: string | undefined
    saveMetadata: (metadata: Omit<Payload, 'booking_id'>) => void
}

export const Remarks = ({ surgery, dayOvernight, saveMetadata }: Props) => {
    const showDayOvernightWarning = dayOvernight && !isDayOvernightLabel(dayOvernight)

    const isShortNotice = Boolean(surgery.surgeryMetadata?.patient_short_notice)
    const isPrio = Boolean(surgery.surgeryMetadata?.patient_prioritized)

    return (
        <>
            <p className="font-bold">{columns.Remarks.label}</p>
            {showDayOvernightWarning && (
                <div className="bg-yellow-100 px-4 py-4">
                    <p className="flex gap-2">
                        <HomeHealthOutlined className="shrink-0" />
                        {getDayOvernightTooltipText(dayOvernight)}
                    </p>
                </div>
            )}
            <div className="flex gap-2">
                <SidebarSwitch label="Kort varsel" checked={isShortNotice} onCheckedChange={checked => saveMetadata({ patient_short_notice: checked })}>
                    <Remark remark="shortNotice" color={isShortNotice ? 'on' : 'off'} />
                </SidebarSwitch>
                <SidebarSwitch label="Prioritet" checked={isPrio} onCheckedChange={checked => saveMetadata({ patient_prioritized: checked })}>
                    <Remark remark="prioritized" color={isPrio ? 'on' : 'off'} />
                </SidebarSwitch>
            </div>
        </>
    )
}
