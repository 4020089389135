import { Duration } from 'dayjs/plugin/duration'

import { Tag } from '~/components'
import { OccupancyData } from '~/store/selectors'
import { formatSurgeryTypeGroup } from '~/store/utils/blockEvaluation'
import { formatDuration } from '~/utils/extendedDayjs'

type Props = {
    usableAvailableRoomTime: Duration
    occupancyData: OccupancyData
}

/** Formats as comma separated list with 'og' between the last two. */
function formatWithOg(strings: string[]) {
    if (strings.length === 0) {
        return ''
    } else if (strings.length === 1) {
        return strings[0]
    } else {
        return `${strings.slice(0, strings.length - 1).join(', ')} og ${strings[strings.length - 1]}`
    }
}

export function formatPlan(occupancyData: OccupancyData): string {
    const surgeryTypeNames = occupancyData.evaluations
        .map(evaluation => evaluation.filteredByPatientGroup)
        .filter(Boolean)
        .map(formatSurgeryTypeGroup)

    if (surgeryTypeNames.length === 0) return ''

    return 'Plan: ' + formatWithOg(surgeryTypeNames)
}

export const UsableTime = ({ usableAvailableRoomTime, occupancyData }: Props) => (
    <>
        {occupancyData.evaluations.length > 0 && (
            <Tag color="primary" data-test="usable-available-room-time">
                <span className="text-xs font-medium text-diBlue-700">{formatPlan(occupancyData)}</span>
            </Tag>
        )}
        <span className="text-xs font-medium text-diBlue-700">Ledig stuetid: {formatDuration(usableAvailableRoomTime)}</span>
    </>
)
