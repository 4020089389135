import { ViewSwitcher } from '~/app/MainLayout/ViewSwitcher'
import { Bar } from '~/components'
import { HeroSideNavTrigger } from '~/hero-ui/stories/HeroSideNav/HeroSideNav'

import { BarSearch } from './BarSearch'
import { Filters } from './Filters/Filters'

export const Header = () => (
    <Bar
        left={
            <div className="flex gap-1">
                <HeroSideNavTrigger />
                <ViewSwitcher pageKey="WAITING_LIST" />
            </div>
        }
        right={
            <div className="flex gap-2">
                <BarSearch />
                <Filters />
            </div>
        }
    />
)
