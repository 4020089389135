import { ComponentProps, FC } from 'react'

export const ProgressHalfIcon: FC<ComponentProps<'svg'>> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.41689 1.76381C6.23583 1.42459 7.11358 1.25 8 1.25C8.88642 1.25 9.76417 1.42459 10.5831 1.76381C11.4021 2.10303 12.1462 2.60023 12.773 3.22703C13.3998 3.85382 13.897 4.59794 14.2362 5.41689C14.5754 6.23584 14.75 7.11358 14.75 8C14.75 8.88642 14.5754 9.76416 14.2362 10.5831C13.897 11.4021 13.3998 12.1462 12.773 12.773C12.1462 13.3998 11.4021 13.897 10.5831 14.2362C9.76416 14.5754 8.88642 14.75 8 14.75C7.11358 14.75 6.23584 14.5754 5.41689 14.2362C4.59794 13.897 3.85382 13.3998 3.22703 12.773C2.60023 12.1462 2.10303 11.4021 1.76381 10.5831C1.42459 9.76417 1.25 8.88642 1.25 8C1.25 7.11358 1.42459 6.23583 1.76381 5.41689C2.10303 4.59794 2.60023 3.85382 3.22703 3.22703C3.85382 2.60023 4.59794 2.10303 5.41689 1.76381ZM8 2.75C7.31056 2.75 6.62787 2.8858 5.99091 3.14963C5.35395 3.41347 4.7752 3.80018 4.28769 4.28769C3.80018 4.7752 3.41347 5.35395 3.14963 5.99091C2.8858 6.62787 2.75 7.31056 2.75 8C2.75 8.68944 2.8858 9.37213 3.14963 10.0091C3.41347 10.646 3.80018 11.2248 4.28769 11.7123C4.7752 12.1998 5.35395 12.5865 5.99091 12.8504C6.62787 13.1142 7.31056 13.25 8 13.25C8.68944 13.25 9.37213 13.1142 10.0091 12.8504C10.646 12.5865 11.2248 12.1998 11.7123 11.7123C12.1998 11.2248 12.5865 10.646 12.8504 10.0091C13.1142 9.37213 13.25 8.68944 13.25 8C13.25 7.31056 13.1142 6.62787 12.8504 5.99091C12.5865 5.35395 12.1998 4.7752 11.7123 4.28769C11.2248 3.80018 10.646 3.41347 10.0091 3.14963C9.37213 2.8858 8.68944 2.75 8 2.75Z"
            fill="currentColor"
        />
        <path d="M12 8.00002C12 10.2092 10.2093 12 8.00028 12L8 4C10.209 4 12 5.79086 12 8.00002Z" fill="currentColor" />
    </svg>
)
