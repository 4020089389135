import { Dayjs } from 'dayjs'
import uniqBy from 'lodash/uniqBy'

import { UserCircleIcon } from '~/icons'
import { selectGetLocations, selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'
import { getPractitionerFullname } from '~/utils/dips/practitioner'

import { useRemovePractitionerFromCells } from './hooks'
import { PractitionerTag } from './PractitionerTag'

type Props = {
    selectedCells: { locationId: number; date: Dayjs }[]
}

export const PractitionerTags = ({ selectedCells }: Props) => {
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const { removePractitioner } = useRemovePractitionerFromCells(selectedCells)
    const getLocations = useStore(selectGetLocations)

    const practitionerSchedulesByCell = selectedCells.map(cell => getPractitionerSchedules.byDateAndLocationId(cell.date, cell.locationId))
    const practitioners = uniqBy(practitionerSchedulesByCell.flat(), 'practitioner_id').map(schedule => schedule.practitioner)

    return (
        <div className="my-1 flex w-full flex-wrap gap-1">
            {practitioners.map(practitioner => {
                if (!practitioner) return null

                const count = practitionerSchedulesByCell.filter(schedules => schedules.some(schedule => schedule.practitioner_id === practitioner.id)).length
                const tagVariant = count === selectedCells.length ? 'filled' : count > 0 ? 'outlined' : 'none'

                return (
                    <PractitionerTag
                        key={practitioner?.id}
                        data-tooltip={getPractitionerFullname(practitioner)}
                        variant={tagVariant}
                        onRemove={() => {
                            const rooms = [...new Set(selectedCells.map(cell => getLocations.byId(cell.locationId)?.room_code).filter(Boolean))]
                            void removePractitioner(practitioner)
                            void trackUserEvent('SS_SURGEON_LOCATION_REMOVED', { numberOfEdits: 1, rooms })
                        }}
                        icon={<UserCircleIcon className="h-4 w-4" />}
                    >
                        {practitioner.short_name}
                    </PractitionerTag>
                )
            })}
        </div>
    )
}
