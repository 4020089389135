import { useQuery } from '@tanstack/react-query'

import optimusClient from '~/clients/optimus-client'

import { handleApiRequest } from './shared/apiWrapper'

type Request = { blockScheduleId: number; updatedAt: string }

// keys that both match the cache and will force the query to re-fetch if any of the values change
export const serializeKeysForCache = (request: Request) => ['blockScheduleUtilization', ...Object.values(request)]

export const useUtilizationQuery = ({ request, isEnabled }: { request: Request; isEnabled?: boolean }) =>
    useQuery({
        queryKey: serializeKeysForCache(request),
        queryFn: async () => {
            return handleApiRequest(() => optimusClient.getBlockScheduleUtilization(request.blockScheduleId))
        },
        enabled: isEnabled,
        placeholderData: isEnabled ? { utilization: 0.1, description: '' } : undefined,
    })
