import { Button, HeroDialog } from '~/components'
import { updatePlanningPeriod } from '~/store/di-entity.api'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'
import { trackUserEvent } from '~/utils/analytics'

import { getDaysUntilPeriodStarts } from './utils'

type Props = {
    id: number
    open: boolean
    onClose: () => void
}

export function UnpublishDialog({ id, open, onClose }: Props) {
    const planningPeriod = useStore(selectPlanningPeriods).byId(id)
    const addToast = useStore(state => state.toaster.actions.addToast)

    async function submit() {
        if (planningPeriod) {
            await updatePlanningPeriod(planningPeriod.id, { ...planningPeriod, is_published: false })

            void trackUserEvent('PP_PLANNING_PERIOD_PUBLICATION_STATUS_CHANGED', {
                start_date: planningPeriod.start_date,
                is_published: false,
                days_before_period_starts: getDaysUntilPeriodStarts(planningPeriod),
            })

            addToast({ type: 'success', message: 'Perioden er avpublisert' })
            onClose()
        }
    }

    return (
        <HeroDialog isOpen={open} onCloseDialog={onClose} title={`Avpubliser ${planningPeriod?.name || 'periode'}`}>
            <div className="flex flex-col gap-4">
                <p className="text-md max-w-sm">
                    Er du sikker på at du vil avpublisere denne perioden? Dette vil skjule periodens planer fra planleggingsssiden, men planene vil fortsatt
                    være tilgjengelig under kapasitet.
                </p>
                <div className="flex items-center justify-end gap-4">
                    <Button color="white" onClick={onClose} data-test="cancel-deletion-button">
                        Avbryt
                    </Button>
                    <Button onClick={submit} data-test="confirm-unpublish-button">
                        Avpubliser
                    </Button>
                </div>
            </div>
        </HeroDialog>
    )
}
