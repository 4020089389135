import { ComponentProps, FC } from 'react'

export const DepartmentAllIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.12626 8.79283C2.62324 8.29586 3.29727 8.01666 4.0001 8.01666C4.70292 8.01666 5.37696 8.29586 5.87393 8.79283C6.3709 9.2898 6.6501 9.96384 6.6501 10.6667C6.6501 11.3695 6.3709 12.0435 5.87393 12.5405C5.37696 13.0375 4.70292 13.3167 4.0001 13.3167C3.29727 13.3167 2.62324 13.0375 2.12626 12.5405C1.62929 12.0435 1.3501 11.3695 1.3501 10.6667C1.3501 9.96384 1.62929 9.2898 2.12626 8.79283ZM4.0001 9.31666C3.64206 9.31666 3.29868 9.45889 3.0455 9.71207C2.79233 9.96524 2.6501 10.3086 2.6501 10.6667C2.6501 11.0247 2.79233 11.3681 3.0455 11.6213C3.29868 11.8744 3.64206 12.0167 4.0001 12.0167C4.35814 12.0167 4.70152 11.8744 4.95469 11.6213C5.20787 11.3681 5.3501 11.0247 5.3501 10.6667C5.3501 10.3086 5.20787 9.96524 4.95469 9.71207C4.70152 9.45889 4.35814 9.31666 4.0001 9.31666Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.12626 2.12614C6.62324 1.62917 7.29727 1.34998 8.0001 1.34998C8.70292 1.34998 9.37696 1.62917 9.87393 2.12614C10.3709 2.62311 10.6501 3.29715 10.6501 3.99998C10.6501 4.7028 10.3709 5.37684 9.87393 5.87381C9.37696 6.37078 8.70292 6.64998 8.0001 6.64998C7.29727 6.64998 6.62324 6.37078 6.12626 5.87381C5.62929 5.37684 5.3501 4.7028 5.3501 3.99998C5.3501 3.29715 5.62929 2.62311 6.12626 2.12614ZM8.0001 2.64998C7.64206 2.64998 7.29868 2.79221 7.0455 3.04538C6.79233 3.29856 6.6501 3.64193 6.6501 3.99998C6.6501 4.35802 6.79233 4.7014 7.0455 4.95457C7.29868 5.20774 7.64206 5.34998 8.0001 5.34998C8.35814 5.34998 8.70152 5.20774 8.95469 4.95457C9.20787 4.7014 9.3501 4.35802 9.3501 3.99998C9.3501 3.64193 9.20787 3.29856 8.95469 3.04538C8.70152 2.79221 8.35814 2.64998 8.0001 2.64998Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1263 8.79283C10.6232 8.29586 11.2973 8.01666 12.0001 8.01666C12.7029 8.01666 13.377 8.29586 13.8739 8.79283C14.3709 9.2898 14.6501 9.96384 14.6501 10.6667C14.6501 11.3695 14.3709 12.0435 13.8739 12.5405C13.377 13.0375 12.7029 13.3167 12.0001 13.3167C11.2973 13.3167 10.6232 13.0375 10.1263 12.5405C9.62929 12.0435 9.3501 11.3695 9.3501 10.6667C9.3501 9.96384 9.62929 9.2898 10.1263 8.79283ZM12.0001 9.31666C11.6421 9.31666 11.2987 9.45889 11.0455 9.71207C10.7923 9.96524 10.6501 10.3086 10.6501 10.6667C10.6501 11.0247 10.7923 11.3681 11.0455 11.6213C11.2987 11.8744 11.6421 12.0167 12.0001 12.0167C12.3581 12.0167 12.7015 11.8744 12.9547 11.6213C13.2079 11.3681 13.3501 11.0247 13.3501 10.6667C13.3501 10.3086 13.2079 9.96524 12.9547 9.71207C12.7015 9.45889 12.3581 9.31666 12.0001 9.31666Z"
            fill="currentColor"
        />
    </svg>
)
