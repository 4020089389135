import { ComponentProps, FC } from 'react'

export const CircleIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.07257 0.932297C6.00071 0.547849 6.99549 0.349976 8.0001 0.349976C9.00471 0.349976 9.99949 0.547849 10.9276 0.932297C11.8558 1.31675 12.6991 1.88024 13.4095 2.59061C14.1198 3.30098 14.6833 4.14431 15.0678 5.07245C15.4522 6.00059 15.6501 6.99536 15.6501 7.99998C15.6501 9.00459 15.4522 9.99936 15.0678 10.9275C14.6833 11.8556 14.1198 12.699 13.4095 13.4093C12.6991 14.1197 11.8558 14.6832 10.9276 15.0677C9.99949 15.4521 9.00471 15.65 8.0001 15.65C6.99549 15.65 6.00071 15.4521 5.07257 15.0677C4.14443 14.6832 3.3011 14.1197 2.59073 13.4093C1.88036 12.699 1.31687 11.8556 0.932419 10.9275C0.547971 9.99936 0.350098 9.00459 0.350098 7.99998C0.350098 6.99536 0.547971 6.00059 0.932419 5.07245C1.31687 4.14431 1.88036 3.30098 2.59073 2.59061C3.3011 1.88024 4.14443 1.31675 5.07257 0.932297ZM8.0001 1.64998C7.1662 1.64998 6.34048 1.81422 5.57006 2.13334C4.79964 2.45246 4.09962 2.9202 3.50997 3.50985C2.92032 4.0995 2.45258 4.79952 2.13346 5.56994C1.81435 6.34035 1.6501 7.16608 1.6501 7.99998C1.6501 8.83387 1.81435 9.6596 2.13346 10.43C2.45258 11.2004 2.92032 11.9005 3.50997 12.4901C4.09962 13.0798 4.79964 13.5475 5.57006 13.8666C6.34048 14.1857 7.1662 14.35 8.0001 14.35C8.83399 14.35 9.65972 14.1857 10.4301 13.8666C11.2006 13.5475 11.9006 13.0798 12.4902 12.4901C13.0799 11.9005 13.5476 11.2004 13.8667 10.43C14.1859 9.6596 14.3501 8.83387 14.3501 7.99998C14.3501 7.16608 14.1859 6.34035 13.8667 5.56994C13.5476 4.79952 13.0799 4.0995 12.4902 3.50985C11.9006 2.9202 11.2006 2.45246 10.4301 2.13334C9.65972 1.81422 8.83399 1.64998 8.0001 1.64998Z"
            fill="currentColor"
        />
    </svg>
)
