import { isDurationRuleEvaluation, OccupancyData, RuleEvaluation } from '~/store/selectors'
import { formatDuration } from '~/utils/extendedDayjs'

import { shouldDisplay } from '../Calendar/CellBlock/AvailabilitiesList'
import { getUsableRoomTime } from '../Calendar/utils'
import { formatPlan } from '../shared/UsableTime'

type AvailableTimeLabelProps = {
    evaluationsToDisplay: RuleEvaluation[]
    occupancyData: OccupancyData
}

const AvailableTimeLabel = ({ evaluationsToDisplay, occupancyData }: AvailableTimeLabelProps) => {
    if (evaluationsToDisplay.length === 0) {
        const usableAvailableRoomTime = getUsableRoomTime(occupancyData)
        return usableAvailableRoomTime !== null && <p>Ledig stuetid: {formatDuration(usableAvailableRoomTime)}</p>
    }

    return (
        <>
            {evaluationsToDisplay.map(evaluation => {
                if (isDurationRuleEvaluation(evaluation)) {
                    const duration = evaluation.remaining ? formatDuration(evaluation.remaining) : '?'
                    const text = `Ledig stuetid: ${duration}`

                    return <p key={text}>{text}</p>
                }
                return null
            })}
        </>
    )
}

type Props = {
    occupancyData: OccupancyData
}

export const AvailableCard = ({ occupancyData }: Props) => {
    const evaluationsToDisplay = occupancyData.evaluations.filter(shouldDisplay)

    return (
        <div className="flex justify-between gap-8 rounded bg-fill-info-subtle p-2 text-info-rest">
            <p>{formatPlan(occupancyData)}</p>
            <AvailableTimeLabel evaluationsToDisplay={evaluationsToDisplay} occupancyData={occupancyData} />
        </div>
    )
}
