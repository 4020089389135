export function isNullish(value: unknown): value is null | undefined {
    return value === null || value === undefined
}

export function isNotNullish<T>(value: T): value is NonNullable<T> {
    return !isNullish(value)
}

/** Returns the specified string as number if it's an integer; undefined otherwise. */
export function asInteger(string: string): number | undefined {
    const result = Number(string)
    if (isNaN(result)) {
        return undefined
    }
    return result
}

/** Checks if the specified string is an integer. */
export function isInteger(string: string): boolean {
    return asInteger(string) !== undefined
}

export function booleanFromURLParam(value: string | null, defaultValue: boolean): boolean {
    if (value === 'true') {
        return true
    }
    if (value === 'false') {
        return false
    }
    return defaultValue
}

export const isStringWithContent = (value: unknown): value is string => {
    return typeof value === 'string' && value.trim() !== ''
}
