import { ComponentProps, FC } from 'react'

export const AddIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99977 2.68335C8.35876 2.68335 8.64977 2.97436 8.64977 3.33335V7.35002H12.6664C13.0254 7.35002 13.3164 7.64103 13.3164 8.00002C13.3164 8.359 13.0254 8.65002 12.6664 8.65002H8.64977V12.6667C8.64977 13.0257 8.35876 13.3167 7.99977 13.3167C7.64079 13.3167 7.34977 13.0257 7.34977 12.6667V8.65002H3.33311C2.97412 8.65002 2.68311 8.359 2.68311 8.00002C2.68311 7.64103 2.97412 7.35002 3.33311 7.35002H7.34977V3.33335C7.34977 2.97436 7.64079 2.68335 7.99977 2.68335Z"
            fill="currentColor"
        />
    </svg>
)
