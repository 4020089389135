import { ComponentProps, FC } from 'react'

export const ClockFastIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.89848 4.39C7.78848 3.5 8.99558 3 10.2542 3C11.5129 3 12.72 3.5 13.61 4.39C14.5 5.28 15 6.48711 15 7.74576C15 8.36899 14.8772 8.9861 14.6388 9.56189C14.4003 10.1377 14.0507 10.6608 13.61 11.1015C13.1693 11.5422 12.6461 11.8918 12.0704 12.1303C11.4946 12.3688 10.8775 12.4915 10.2542 12.4915C9.63101 12.4915 9.0139 12.3688 8.43811 12.1303C7.86233 11.8918 7.33916 11.5422 6.89848 11.1015C6.45779 10.6608 6.10822 10.1377 5.86972 9.56189C5.63123 8.9861 5.50847 8.36899 5.50847 7.74576C5.50847 6.48711 6.00847 5.28 6.89848 4.39ZM10.2542 4.05461C9.27528 4.05461 8.33643 4.4435 7.6442 5.13573C6.95198 5.82795 6.56309 6.76681 6.56309 7.74576C6.56309 8.23049 6.65856 8.71047 6.84406 9.1583C7.02956 9.60614 7.30145 10.013 7.6442 10.3558C7.98696 10.6986 8.39387 10.9704 8.8417 11.1559C9.28953 11.3414 9.76951 11.4369 10.2542 11.4369C10.739 11.4369 11.2189 11.3414 11.6668 11.1559C12.1146 10.9704 12.5215 10.6986 12.8643 10.3558C13.207 10.013 13.4789 9.60613 13.6644 9.1583C13.8499 8.71047 13.9454 8.23049 13.9454 7.74576C13.9454 6.76681 13.5565 5.82795 12.8643 5.13573C12.172 4.4435 11.2332 4.05461 10.2542 4.05461ZM10.2542 4.87487C10.5455 4.87487 10.7815 5.11095 10.7815 5.40218V7.52735L12.0333 8.77905C12.2392 8.98498 12.2392 9.31885 12.0333 9.52478C11.8273 9.7307 11.4935 9.7307 11.2875 9.52478L9.88137 8.11863C9.78249 8.01974 9.72693 7.88561 9.72693 7.74576V5.40218C9.72693 5.11095 9.96301 4.87487 10.2542 4.87487Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.42373 4.89831C2.42373 4.50515 2.74244 4.18644 3.13559 4.18644H4.55932C4.95247 4.18644 5.27119 4.50515 5.27119 4.89831C5.27119 5.29146 4.95247 5.61017 4.55932 5.61017H3.13559C2.74244 5.61017 2.42373 5.29146 2.42373 4.89831ZM1 7.74576C1 7.35261 1.31871 7.0339 1.71186 7.0339H4.08475C4.4779 7.0339 4.79661 7.35261 4.79661 7.74576C4.79661 8.13892 4.4779 8.45763 4.08475 8.45763H1.71186C1.31871 8.45763 1 8.13892 1 7.74576ZM2.42373 10.5932C2.42373 10.2001 2.74244 9.88136 3.13559 9.88136H4.55932C4.95247 9.88136 5.27119 10.2001 5.27119 10.5932C5.27119 10.9864 4.95247 11.3051 4.55932 11.3051H3.13559C2.74244 11.3051 2.42373 10.9864 2.42373 10.5932Z"
            fill="currentColor"
        />
    </svg>
)
