import { ComponentProps, FC } from 'react'

export const LoaderIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.0001 1.34998C8.35908 1.34998 8.6501 1.64099 8.6501 1.99998V3.99998C8.6501 4.35896 8.35908 4.64998 8.0001 4.64998C7.64111 4.64998 7.3501 4.35896 7.3501 3.99998V1.99998C7.3501 1.64099 7.64111 1.34998 8.0001 1.34998ZM3.27382 3.27369C3.52766 3.01985 3.93921 3.01985 4.19305 3.27369L5.62639 4.70703C5.88023 4.96087 5.88023 5.37243 5.62639 5.62627C5.37255 5.88011 4.96099 5.88011 4.70715 5.62627L3.27382 4.19293C3.01997 3.93909 3.01997 3.52753 3.27382 3.27369ZM12.7264 3.27369C12.9802 3.52753 12.9802 3.93909 12.7264 4.19293L11.2931 5.62627C11.0392 5.88011 10.6277 5.88011 10.3738 5.62627C10.12 5.37243 10.12 4.96087 10.3738 4.70703L11.8071 3.27369C12.061 3.01985 12.4725 3.01985 12.7264 3.27369ZM1.3501 7.99998C1.3501 7.64099 1.64111 7.34998 2.0001 7.34998H4.0001C4.35908 7.34998 4.6501 7.64099 4.6501 7.99998C4.6501 8.35896 4.35908 8.64998 4.0001 8.64998H2.0001C1.64111 8.64998 1.3501 8.35896 1.3501 7.99998ZM11.3501 7.99998C11.3501 7.64099 11.6411 7.34998 12.0001 7.34998H14.0001C14.3591 7.34998 14.6501 7.64099 14.6501 7.99998C14.6501 8.35896 14.3591 8.64998 14.0001 8.64998H12.0001C11.6411 8.64998 11.3501 8.35896 11.3501 7.99998ZM5.62639 10.3737C5.88023 10.6275 5.88023 11.0391 5.62639 11.2929L4.19305 12.7263C3.93921 12.9801 3.52766 12.9801 3.27382 12.7263C3.01997 12.4724 3.01997 12.0609 3.27382 11.807L4.70715 10.3737C4.96099 10.1198 5.37255 10.1198 5.62639 10.3737ZM10.3738 10.3737C10.6277 10.1198 11.0392 10.1198 11.293 10.3737L12.7264 11.807C12.9802 12.0609 12.9802 12.4724 12.7264 12.7263C12.4725 12.9801 12.061 12.9801 11.8071 12.7263L10.3738 11.2929C10.12 11.0391 10.12 10.6275 10.3738 10.3737ZM8.0001 11.35C8.35908 11.35 8.6501 11.641 8.6501 12V14C8.6501 14.359 8.35908 14.65 8.0001 14.65C7.64111 14.65 7.3501 14.359 7.3501 14V12C7.3501 11.641 7.64111 11.35 8.0001 11.35Z"
            fill="currentColor"
        />
    </svg>
)
