import { ReactNode, useState } from 'react'

import { BookingDialog } from '~/app/pages/OperationalPlanner/BookingDrawer/BookingDialog/BookingDialog'
import { Show } from '~/components'
import env from '~/env'
import { OccupancyData } from '~/store/selectors'
import { useStore } from '~/store/store'

import { useGetWaitingListSurgery } from '../hooks/useGetWaitingListSurgery'
import { Payload, saveSurgeryMetadata } from '../shared/saveSurgeryMetadata'
import { AvailablePicker } from './Booking/AvailablePicker'
import { SurgeryInfo } from './Booking/SurgeryInfo'
import { Comment } from './Comment/Comment'
import { PatientInfo } from './PatientInfo'
import { Remarks } from './Remarks'
import { Statuses } from './Statuses/Statuses'

const ContentSection = ({ children }: { children: ReactNode }) => <div className="flex flex-col gap-2">{children}</div>

export const Content = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const showAllPractitioners = useStore(state => state.waitingList.showAllPractitioners)
    const [selectedOccupancy, setSelectedOccupancy] = useState<OccupancyData>()

    const { BookingId, BookedStatus, Day } = drawerProps?.item ?? {}
    const isUnscheduledItem = BookedStatus !== 'Scheduled'

    const selectedSurgery = useGetWaitingListSurgery(drawerProps?.item)

    if (drawerProps === null || !selectedSurgery) return <div />

    function openDialog(selectedOccupancy: OccupancyData) {
        setSelectedOccupancy(selectedOccupancy)
    }
    function onCloseDialog() {
        setSelectedOccupancy(undefined)
    }

    function saveMetadata(metadata: Omit<Payload, 'booking_id'>) {
        saveSurgeryMetadata(selectedSurgery?.surgeryMetadata, { booking_id: BookingId!, ...metadata })
    }

    return (
        <div className="flex flex-col gap-6" data-test="waiting-list-booking-content">
            <PatientInfo item={drawerProps.item} isUnscheduledItem={isUnscheduledItem} surgery={selectedSurgery} />

            <ContentSection>
                <Statuses isUnscheduledItem={isUnscheduledItem} saveMetadata={saveMetadata} surgery={selectedSurgery} />
            </ContentSection>

            <ContentSection>
                <Remarks dayOvernight={Day} saveMetadata={saveMetadata} surgery={selectedSurgery} />
            </ContentSection>

            <Comment item={drawerProps.item} />

            <ContentSection>
                <p className="font-bold">{`${isUnscheduledItem ? 'Operasjon til planlegging' : 'Planlagt operasjon'}`}</p>
                <SurgeryInfo item={drawerProps.item} surgery={selectedSurgery} />
            </ContentSection>

            <Show condition={isUnscheduledItem && env.VITE_FF_WAITING_LIST_TIME_SLOTS_IN_DRAWER}>
                <ContentSection>
                    <AvailablePicker openBookingDialog={openDialog} showAllPractitioners={showAllPractitioners} surgery={selectedSurgery} />
                </ContentSection>
            </Show>

            {selectedOccupancy && <BookingDialog isOpen={true} onCloseDialog={onCloseDialog} surgery={selectedSurgery} occupancyData={selectedOccupancy} />}
        </div>
    )
}
