import { useStore } from '~/store/store'

import { Content } from './Content'
import { Header } from './Header'

export const Drawer = () => {
    const bookingId = useStore(state => state.waitingList.drawerProps?.item.BookingId)

    return (
        <div className="flex h-full w-[30rem] flex-col gap-4 pl-4 pr-2" key={bookingId} data-test="Waitinglist Sidebar">
            <div className="flex">
                <div className="grow">
                    <Header />
                </div>
            </div>

            <div className="flex flex-col gap-4 overflow-auto">
                <Content />
            </div>
        </div>
    )
}
