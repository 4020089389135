import { ComponentProps, FC } from 'react'

export const CaretLeftIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.3335 6.41421C9.3335 5.52331 8.25635 5.07714 7.62639 5.70711L6.0406 7.29289C5.65008 7.68342 5.65008 8.31658 6.0406 8.70711L7.62639 10.2929C8.25636 10.9229 9.3335 10.4767 9.3335 9.58579V6.41421Z"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
