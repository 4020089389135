import { PractitionerTag, PractitionerTags, Show, Tag } from '~/components'
import { CalendarCheckIcon, CalendarQuestionMarkIcon, SliceIcon } from '~/icons'
import { getDayOvernight } from '~/store/slices/filterSlice'
import { AnyScheduledSurgery, getSurgeonsAndAssistants } from '~/utils/dips'

import { FormattedWaitingListItem } from '../../shared/columns'

const iconStyles = 'h-4 w-4'

type SurgeryInfoProps = {
    item: FormattedWaitingListItem
    surgery: AnyScheduledSurgery
}

export const SurgeryInfo = ({ item, surgery }: SurgeryInfoProps) => {
    const dayOvernight = getDayOvernight(surgery.contact?.levelOfCareNpr?.nprCodeName)

    const isScheduled = item.BookedStatus === 'Scheduled'
    const practitioners = getSurgeonsAndAssistants(surgery.surgeryResources)
    const hasPractitioners = practitioners.filter(p => !!p.short_name).length > 0

    const date = isScheduled ? item.OperationDate : item.TentativeDate
    const dateIcon = isScheduled ? <CalendarCheckIcon className={iconStyles} /> : <CalendarQuestionMarkIcon className={iconStyles} />

    return (
        <div className="flex flex-col gap-2 rounded border border-gray-200 px-2 pb-4 pt-3">
            <p className="font-semibold">{item.SurgeryType}</p>
            <div className="flex flex-row flex-wrap gap-1">
                <Show condition={hasPractitioners} fallback={<PractitionerTag shortName="Alle" color="surgeryInfo" size="md" />}>
                    <PractitionerTags practitioners={practitioners} withPrefix={isScheduled} color="surgeryInfo" size="md" />
                </Show>
                <Show condition={Boolean(item.KnifeTime)}>
                    <Tag color="surgeryInfo">
                        <SliceIcon className={iconStyles} />
                        {item.KnifeTime}
                    </Tag>
                </Show>
                <Show condition={Boolean(dayOvernight)}>
                    <Tag color="surgeryInfo">
                        {dayOvernight?.icon} {dayOvernight?.label}
                    </Tag>
                </Show>
                <Show condition={Boolean(date)}>
                    <Tag color="surgeryInfo">
                        {dateIcon}
                        {date}
                    </Tag>
                </Show>
                <Show condition={Boolean(item.BMI)}>
                    <Tag color="surgeryInfo">{`BMI ${item.BMI}`}</Tag>
                </Show>
                <Show condition={Boolean(item.ASA)}>
                    <Tag color="surgeryInfo">{item.ASA}</Tag>
                </Show>
            </div>
        </div>
    )
}
