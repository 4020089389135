import { createRef } from 'react'

import PageLayout from '~/components/PageLayout'
import { useImportSurgeryTypeGroupEntities } from '~/hooks/useImportSurgeryTypeGroupEntities'
import { useStore } from '~/store/store'

import { Drawer } from './Drawer/Drawer'
import { Header } from './Header/Header'
import { useImportFutureScheduledEntities, useImportUnScheduledEntities } from './hooks/useImportWaitingListEntities'
import { Table } from './Table/Table'

const WaitingListPage = () => {
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const { setDrawerProps } = useStore(state => state.waitingList.actions)

    // Enable mapping from surgery type to surgery category in operation type filter
    useImportSurgeryTypeGroupEntities()

    const { isLoading: isUnscheduledLoading } = useImportUnScheduledEntities({ skip: activeView === '/patients/scheduled' })
    const { isLoading: isScheduledLoading } = useImportFutureScheduledEntities({ skip: activeView === '/patients/unscheduled' })
    const isLoading = isUnscheduledLoading || isScheduledLoading

    const ref = createRef<HTMLDivElement>()

    return (
        <PageLayout
            content={<Table isLoading={isLoading} containerRef={ref} />}
            header={<Header />}
            sidebar={<Drawer />}
            isSidebarOpen={drawerProps !== null}
            setIsSidebarOpen={open => {
                if (!open) setDrawerProps(null)
            }}
        />
    )
}

export default WaitingListPage
