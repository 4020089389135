import { ComponentProps, FC } from 'react'

export const CheckboxIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33343 2.64998C3.1522 2.64998 2.97839 2.72197 2.85024 2.85012C2.72209 2.97827 2.6501 3.15208 2.6501 3.33331V12.6666C2.6501 12.8479 2.72209 13.0217 2.85024 13.1498C2.97839 13.278 3.1522 13.35 3.33343 13.35H12.6668C12.848 13.35 13.0218 13.278 13.15 13.1498C13.2781 13.0217 13.3501 12.8479 13.3501 12.6666V3.33331C13.3501 3.15208 13.2781 2.97827 13.15 2.85012C13.0218 2.72197 12.848 2.64998 12.6668 2.64998H3.33343ZM1.931 1.93088C2.30295 1.55893 2.80742 1.34998 3.33343 1.34998H12.6668C13.1928 1.34998 13.6972 1.55893 14.0692 1.93088C14.4411 2.30283 14.6501 2.8073 14.6501 3.33331V12.6666C14.6501 13.1927 14.4411 13.6971 14.0692 14.0691C13.6972 14.441 13.1928 14.65 12.6668 14.65H3.33343C2.80742 14.65 2.30295 14.441 1.931 14.0691C1.55906 13.6971 1.3501 13.1927 1.3501 12.6666V3.33331C1.3501 2.8073 1.55906 2.30283 1.931 1.93088Z"
            fill="currentColor"
        />
    </svg>
)
