import { ComponentProps, FC } from 'react'

export const SearchIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.63216 1.75468C5.27721 1.4875 5.96857 1.34998 6.66676 1.34998C7.36496 1.34998 8.05632 1.4875 8.70136 1.75468C9.34641 2.02187 9.93252 2.41349 10.4262 2.90719C10.9199 3.40089 11.3115 3.98699 11.5787 4.63204C11.8459 5.27709 11.9834 5.96845 11.9834 6.66664C11.9834 7.36484 11.8459 8.05619 11.5787 8.70124C11.3949 9.14513 11.1521 9.56111 10.8576 9.93827L14.4597 13.5404C14.7136 13.7942 14.7136 14.2058 14.4597 14.4596C14.2059 14.7134 13.7943 14.7134 13.5405 14.4596L9.93839 10.8575C9.56123 11.1519 9.14526 11.3947 8.70136 11.5786C8.05632 11.8458 7.36496 11.9833 6.66676 11.9833C5.96857 11.9833 5.27721 11.8458 4.63216 11.5786C3.98712 11.3114 3.40101 10.9198 2.90731 10.4261C2.41362 9.93239 2.02199 9.34629 1.7548 8.70124C1.48762 8.0562 1.3501 7.36484 1.3501 6.66664C1.3501 5.96845 1.48762 5.27709 1.7548 4.63204C2.02199 3.98699 2.41361 3.40089 2.90731 2.90719C3.40101 2.41349 3.98712 2.02187 4.63216 1.75468ZM6.66676 2.64998C6.13929 2.64998 5.61698 2.75387 5.12965 2.95573C4.64233 3.15758 4.19953 3.45345 3.82655 3.82643C3.45357 4.19941 3.1577 4.64221 2.95585 5.12953C2.75399 5.61685 2.6501 6.13917 2.6501 6.66664C2.6501 7.19412 2.75399 7.71643 2.95585 8.20375C3.1577 8.69108 3.45357 9.13387 3.82655 9.50685C4.19953 9.87984 4.64233 10.1757 5.12965 10.3776C5.61698 10.5794 6.13929 10.6833 6.66676 10.6833C7.19424 10.6833 7.71655 10.5794 8.20388 10.3776C8.6912 10.1757 9.13399 9.87984 9.50698 9.50685C9.87996 9.13387 10.1758 8.69108 10.3777 8.20375C10.5795 7.71643 10.6834 7.19412 10.6834 6.66664C10.6834 6.13917 10.5795 5.61685 10.3777 5.12953C10.1758 4.64221 9.87996 4.19941 9.50698 3.82643C9.13399 3.45345 8.6912 3.15758 8.20388 2.95573C7.71655 2.75387 7.19424 2.64998 6.66676 2.64998Z"
            fill="currentColor"
        />
    </svg>
)
