import { useMemo } from 'react'

import { PractitionerTags, Show, Tag } from '~/components'
import { CalendarQuestionMarkIcon, SliceIcon } from '~/icons'
import { UnScheduledSurgery } from '~/store/selectors'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'

import { Remarks } from '../../WaitingList/shared/Remarks'
import { Statuses } from '../../WaitingList/shared/Statuses'
import { formatRemarks, formatStatuses } from '../../WaitingList/shared/utils'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

const iconStyles = 'h-4 w-4'

type Props = {
    surgery: UnScheduledSurgery
    onClick: (selectedSurgery: UnScheduledSurgery) => void
}

export const SurgeryCard = ({ surgery, onClick }: Props) => {
    const { dayOvernight, asa, bmi, knifeTime, practitioners, tentativeDate } = useMemo(() => getFormattedSurgery(surgery), [surgery])
    const statuses = formatStatuses(surgery)
    const remarks = formatRemarks(surgery)
    const hasWard = Boolean(surgery.contact?.ward)

    return (
        <div
            data-test="BookingDrawerPatients"
            data-test-has-ward={hasWard}
            onClick={() => onClick?.(surgery)}
            className="flex cursor-pointer flex-col gap-2 rounded border border-gray-200 px-2 py-3 hover:border-gray-400"
        >
            <div className="flex items-center justify-between">
                <p className="font-semibold">{surgery.surgeryType?.name ?? ''}</p>
                <div className="flex items-center gap-1">
                    <Statuses statuses={statuses} isScheduledItem={false} />
                    <Remarks remarks={remarks} />
                </div>
            </div>
            <div className="flex flex-row flex-wrap gap-1">
                <PractitionerTags practitioners={practitioners} withPrefix={true} color="surgeryInfo" size="md" />
                <Show condition={Boolean(knifeTime)}>
                    <Tag color="surgeryInfo">
                        <SliceIcon className={iconStyles} />
                        {knifeTime}
                    </Tag>
                </Show>
                <Show condition={Boolean(dayOvernight)}>
                    <Tag color="surgeryInfo">
                        {dayOvernight?.icon} {dayOvernight?.label}
                    </Tag>
                </Show>
                <Show condition={Boolean(tentativeDate)}>
                    <Tag color="surgeryInfo">
                        <CalendarQuestionMarkIcon className={iconStyles} />
                        {tentativeDate}
                    </Tag>
                </Show>
                <Show condition={Boolean(bmi)}>
                    <Tag color="surgeryInfo">{`BMI ${bmi}`}</Tag>
                </Show>
                <Show condition={Boolean(asa)}>
                    <Tag color="surgeryInfo">{asa}</Tag>
                </Show>
            </div>
            {surgery.contact?.coordinationComment && <p className="text-xs"> {surgery.contact?.coordinationComment}</p>}
        </div>
    )
}
