import { ComponentProps, FC } from 'react'

export const ActivityIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.29647 2.01766C9.58055 2.00148 9.84214 2.17195 9.94205 2.43838L11.7839 7.34994H14.0001C14.3591 7.34994 14.6501 7.64096 14.6501 7.99994C14.6501 8.35893 14.3591 8.64994 14.0001 8.64994H11.3334C11.0625 8.64994 10.82 8.48187 10.7248 8.22817L9.45775 4.84934L7.29736 13.4909C7.22835 13.767 6.98781 13.966 6.70373 13.9822C6.41964 13.9984 6.15806 13.8279 6.05815 13.5615L4.21631 8.64994H2.0001C1.64111 8.64994 1.3501 8.35893 1.3501 7.99994C1.3501 7.64096 1.64111 7.34994 2.0001 7.34994H4.66676C4.93771 7.34994 5.18024 7.51801 5.27538 7.77171L6.54244 11.1505L8.70284 2.50896C8.77185 2.23291 9.01239 2.03384 9.29647 2.01766Z"
            fill="currentColor"
        />
    </svg>
)
