import { Show } from '~/components'
import env from '~/env'
import { selectDrawerDates, selectGetLocations, selectGetOccupancies, selectGetScheduledPractitioners } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

import { BookingWaitingList } from './BookingWaitingList'

export const BookingDrawerAvailability = () => {
    const locationId = useStore(state => state.operationalPlanner.drawerProps?.locationId)
    const { date } = useStore(selectDrawerDates)
    const getOccupancies = useStore(selectGetOccupancies)
    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const getLocations = useStore(selectGetLocations)

    const location = locationId ? getLocations.byId(locationId) : null

    if (isNullish(location)) return null

    const occupancyData = locationId ? getOccupancies.byDateAndLocation(date, location) : null
    const scheduledPractitioners = locationId ? getScheduledPractitioners.byDateAndLocationId(date, locationId) : []

    return (
        <Show condition={env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER}>
            <BookingWaitingList scheduledPractitioners={scheduledPractitioners} occupancyData={occupancyData} date={date} />
        </Show>
    )
}
