import { Tag } from '~/components'
import { DurationRuleEvaluation, isRoomDurationBasedRuleEvaluation } from '~/store/selectors'
import { formatDuration } from '~/utils/extendedDayjs'

type Props = {
    'data-test'?: string
    asTag: boolean
    evaluation: DurationRuleEvaluation
}

export const AvailabilityByTime = ({ evaluation, asTag, 'data-test': data_test }: Props) => {
    const what = isRoomDurationBasedRuleEvaluation(evaluation) ? 'stuetid' : 'knivtid'
    const duration = evaluation.remaining ? formatDuration(evaluation.remaining) : '?'
    const text = `Ledig ${what}: ${duration}`

    if (asTag) {
        return (
            <Tag color="no-bg" data-test={`${data_test}-available-slots-duration`}>
                {text}
            </Tag>
        )
    }

    return (
        <span data-test={`${data_test}-available-slots-duration`} className="font-medium text-diBlue-700">
            {text}
        </span>
    )
}
