import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { optimusBaseClient } from '~/clients/optimus-client'
import { Button, DISelect, MonthPicker } from '~/components'
import { selectEntities } from '~/store/selectors'
import { useStore } from '~/store/store'

export const DeleteModelOutput = () => {
    const { departments: departmentsList } = useStore(selectEntities)
    const departmentsListOptions = departmentsList.map(dep => ({ label: dep.name, value: dep.id }))

    const [date, setDate] = useState<Dayjs>()
    const [depId, setDepId] = useState<number>()

    async function deleteOutput() {
        if (!date || !depId) return

        const alertDelete = window.confirm('Are you sure you want to delete this model output?')
        if (!alertDelete) return

        const doubleCheck = window.confirm('Are you really sure?')
        if (!doubleCheck) return

        try {
            await optimusBaseClient.DELETE('/staffing-plan/{department_id}/{year}/{month}', {
                params: {
                    path: {
                        year: date.year(),
                        month: date.month() + 1,
                        department_id: depId,
                    },
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="flex flex-col gap-y-2 p-4">
            <h2 className="mb-2 text-2xl">Delete Model Output</h2>
            <div className="flex w-64 flex-col gap-y-2">
                <MonthPicker value={date} onChange={setDate} />
                <DISelect
                    isClearable={false}
                    value={departmentsListOptions.find(option => option.value === depId)}
                    options={departmentsListOptions}
                    onChange={val => {
                        if (!val) return
                        setDepId(val.value)
                    }}
                />
                <Button color="delete" disabled={!date} onClick={deleteOutput}>
                    Delete
                </Button>
            </div>
        </div>
    )
}
