import { useState } from 'react'

import { Show, Tag } from '~/components'
import { CloseIcon, HelpIcon } from '~/icons'
import { isNullish } from '~/utils/guards'

import { WaitingListItem } from '../../shared/columns'
import { Payload } from '../../shared/saveSurgeryMetadata'
import { getCompletedStatus, Status } from '../../shared/Statuses'
import { SidebarSwitch } from '../shared/SidebarSwitch'
import { ClearedRadioGroup } from './ClearedRadioGroup'
import { FollowUpDate } from './FollowUpDate'

type Props = {
    surgery: WaitingListItem
    isUnscheduledItem: boolean
    saveMetadata: (metadata: Omit<Payload, 'booking_id'>) => void
}

export const Statuses = ({ surgery, isUnscheduledItem, saveMetadata }: Props) => {
    const [showPatientStatusHelp, setShowPatientStatusHelp] = useState<boolean>(false)

    const followUpDate = surgery.surgeryMetadata?.follow_up_date
    const isPatientReady = surgery.surgeryMetadata?.patient_ready

    const isNew = Boolean(isUnscheduledItem && isNullish(isPatientReady))
    const isCleared = Boolean(isPatientReady)
    const isConfirmed = Boolean(surgery.surgeryMetadata?.patient_confirmed)

    return (
        <>
            <div className="flex flex-col gap-0.5">
                <div className="flex items-center gap-1">
                    <p className="font-bold">Pasientstatus</p>
                    <div className={`mr-2 flex items-center justify-center rounded ${showPatientStatusHelp ? 'bg-neutral-100' : 'hover:bg-neutral-50'}`}>
                        <HelpIcon className="h-5 w-5 cursor-pointer" onClick={() => setShowPatientStatusHelp(prev => !prev)} />
                    </div>
                    <Show condition={isNew}>
                        <Tag size="sm" color="new">
                            <div className="h-[6px] w-[6px] rounded-full bg-fill-info-strong" />
                            Ny
                        </Tag>
                    </Show>
                </div>
                <Show condition={showPatientStatusHelp}>
                    <div className="flex gap-1 rounded bg-neutral-100 p-2">
                        <p>{`Planleggeren oppdaterer pasientstatusen for å vise om pasienten er klar for å settes opp til operasjon${!isUnscheduledItem ? ', og om de har fått bekreftet at pasienten vil møte opp' : ''}.`}</p>
                        <CloseIcon className="h-5 w-5 shrink-0 cursor-pointer hover:fill-neutral-500" onClick={() => setShowPatientStatusHelp(false)} />
                    </div>
                </Show>
            </div>
            <ClearedRadioGroup isUnscheduledItem={isUnscheduledItem} isNew={isNew} isCleared={isCleared} onChange={saveMetadata} />
            <Show condition={!isCleared && !isNew}>
                <FollowUpDate value={followUpDate} onChange={saveMetadata} />
            </Show>
            <Show condition={!isUnscheduledItem}>
                <SidebarSwitch label="Bekreftet oppmøte" checked={isConfirmed} onCheckedChange={checked => saveMetadata({ patient_confirmed: checked })}>
                    <Status status={{ type: 'confirmed', value: getCompletedStatus(isConfirmed) }} isSelected={isConfirmed} />
                </SidebarSwitch>
            </Show>
        </>
    )
}
