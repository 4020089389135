import { ComponentProps, FC } from 'react'

export const ExternalLinkIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.34994 2.66672C9.34994 2.30774 9.64095 2.01672 9.99994 2.01672H13.3333C13.6923 2.01672 13.9833 2.30774 13.9833 2.66672V6.00006C13.9833 6.35904 13.6923 6.65006 13.3333 6.65006C12.9743 6.65006 12.6833 6.35904 12.6833 6.00006V4.23596L7.12622 9.79301C6.87238 10.0469 6.46082 10.0469 6.20698 9.79301C5.95314 9.53917 5.95314 9.12761 6.20698 8.87377L11.764 3.31672H9.99994C9.64095 3.31672 9.34994 3.02571 9.34994 2.66672ZM2.59751 4.59763C2.96945 4.22568 3.47392 4.01672 3.99993 4.01672H7.33327C7.69225 4.01672 7.98327 4.30774 7.98327 4.66672C7.98327 5.02571 7.69225 5.31672 7.33327 5.31672H3.99993C3.8187 5.31672 3.64489 5.38872 3.51675 5.51687C3.3886 5.64502 3.3166 5.81883 3.3166 6.00006V12.0001C3.3166 12.1813 3.3886 12.3551 3.51675 12.4832C3.6449 12.6114 3.8187 12.6834 3.99993 12.6834H9.99994C10.1812 12.6834 10.355 12.6114 10.4831 12.4832C10.6113 12.3551 10.6833 12.1813 10.6833 12.0001V8.66672C10.6833 8.30774 10.9743 8.01672 11.3333 8.01672C11.6923 8.01672 11.9833 8.30774 11.9833 8.66672V12.0001C11.9833 12.5261 11.7743 13.0305 11.4024 13.4025C11.0304 13.7744 10.5259 13.9834 9.99994 13.9834H3.99993C3.47392 13.9834 2.96945 13.7744 2.59751 13.4025C2.22556 13.0305 2.0166 12.5261 2.0166 12.0001V6.00006C2.0166 5.47404 2.22556 4.96958 2.59751 4.59763Z"
            fill="currentColor"
        />
    </svg>
)
