import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import { DotsVerticalIcon, LogoutIcon, UserIcon } from '~/icons'

import { HeroPopover } from '../HeroPopover/HeroPopover'
import { SidebarFooter, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from './HeroSideNav'

const connectionStyles =
    'after:absolute after:bottom-0 after:right-0 after:h-2 after:w-2 after:rounded-full after:bg-[#17C5C5] after:content-[""] after:animate-pulse'

const avatarBaseStyles = 'relative flex items-center justify-center rounded-full bg-[#f0f0f3] '

export type MenuOption = {
    title: string
    icon?: React.FC
    action: () => void
    isHidden?: boolean
    isActive?: boolean
    ref?: React.LegacyRef<HTMLButtonElement>
    subTitle?: string
}

export const HeroSideNavFooter = forwardRef<
    HTMLDivElement,
    {
        userMenuOptions: MenuOption[]
        display_name: string
        username: string
        department: string
        showWebSocketStatus: boolean
        logOutCallback?: () => void
        logOutUrl: string
        departmentOptions: MenuOption[]
    }
>(({ userMenuOptions, display_name, username, showWebSocketStatus, logOutCallback, logOutUrl, department, departmentOptions, ...props }, ref) => {
    return (
        <SidebarFooter {...props} ref={ref}>
            <SidebarMenu>
                <SidebarMenuItem>
                    <HeroPopover
                        side={'right'}
                        align="end"
                        sideOffset={4}
                        noPadding
                        className="min-w-56 px-4 py-2"
                        data-test="user-menu-popup"
                        popoverContent={
                            <>
                                <div>
                                    <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                                        <div className={`${avatarBaseStyles} ${showWebSocketStatus ? connectionStyles : ''}`}>
                                            <UserIcon className="size-[22px]" />
                                        </div>
                                        <div className="grid flex-1 text-left text-sm leading-tight">
                                            <span className="truncate font-semibold">{display_name}</span>
                                            {username !== display_name && username !== '' && username}
                                        </div>
                                    </div>
                                </div>

                                <hr className="mt-2 pb-2" />
                                {departmentOptions.length > 0 && <div className="px-1 py-1 font-bold">Avdelinger</div>}
                                {departmentOptions.length > 0 &&
                                    departmentOptions.map(item => (
                                        <SidebarMenuButton
                                            data-test={`${item.title}-menu-button`}
                                            key={item.title}
                                            ref={item.ref}
                                            onClick={item.action}
                                            className={`flex w-full items-center gap-1 rounded p-1 text-sm outline-none hover:cursor-pointer hover:bg-sidebar-accent ${item.isHidden ? 'hidden' : ''} ${item.isActive ? 'bg-sidebar-accent text-sidebar-accent-foreground' : ''}`}
                                        >
                                            {item.icon && <item.icon />}
                                            {item.title}
                                        </SidebarMenuButton>
                                    ))}
                                <hr className="mt-2 pb-2" />
                                {userMenuOptions.map(item => (
                                    <button
                                        key={item.title}
                                        onClick={item.action}
                                        className={`flex w-full items-center gap-1 rounded p-1 text-sm outline-none hover:cursor-pointer hover:bg-sidebar-accent ${item.isHidden ? 'hidden' : ''} ${item.isActive ? 'bg-sidebar-accent text-sidebar-accent-foreground' : ''}`}
                                    >
                                        {item.icon && <item.icon />}
                                        <div className="flex w-full justify-between">
                                            <span>{item.title}</span> <span className="text-xs">{item.subTitle && item.subTitle}</span>
                                        </div>
                                    </button>
                                ))}

                                <hr className="mt-2 pb-2" />

                                <button
                                    className="flex w-full gap-1 rounded p-1 text-sm outline-none hover:cursor-pointer hover:bg-sidebar-accent"
                                    onClick={logOutCallback}
                                >
                                    <Link to={logOutUrl} className="flex items-center gap-1 font-normal" data-test="logout-button">
                                        <LogoutIcon />
                                        Logg ut
                                    </Link>
                                </button>
                            </>
                        }
                    >
                        <SidebarMenuButton
                            data-test="user-menu-button"
                            size="lg"
                            className="outline-none data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                        >
                            <div className={`${avatarBaseStyles} ${showWebSocketStatus ? connectionStyles : ''}`} data-test="departments-filter-loaded">
                                <UserIcon className="size-[22px]" />
                            </div>
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate font-semibold">{display_name}</span>
                                <span className="truncate text-xs text-neutral-400">{department}</span>
                            </div>
                            <DotsVerticalIcon />
                        </SidebarMenuButton>
                    </HeroPopover>
                </SidebarMenuItem>
            </SidebarMenu>
        </SidebarFooter>
    )
})
