import { useState } from 'react'

import { components } from '~/clients/generated-optimus-api-types'
import { optimusBaseClient } from '~/clients/optimus-client'
import { Button, inputStyles } from '~/components'

type StaffingPlan = components['schemas']['StaffingPlan']

export const ApplyModelOutput = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState('')
    const [modelOutput, setModelOutput] = useState('')

    async function applyOutput() {
        if (!modelOutput) return

        const alertApply = window.confirm('Are you sure you want to apply this model output?')
        if (!alertApply) return

        setLoading(true)
        try {
            const body = JSON.parse(modelOutput) as StaffingPlan
            await optimusBaseClient.POST('/staffing-plan/apply', { body })
            setSuccess('Model output applied successfully.')
        } catch (e) {
            setError('Something went wrong. Check the console for more information.')
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col gap-y-2 p-4">
            <h2 className="mb-2 text-2xl">Apply model output</h2>
            <div className="flex w-full flex-col gap-y-2">
                <textarea value={modelOutput || ''} onChange={e => setModelOutput(e.target.value)} className={inputStyles} rows={40} />
            </div>
            <Button onClick={applyOutput} className="w-fit">
                {loading ? 'Applying...' : 'Apply output'}
            </Button>
            {error && <div className="text-red-500">{error}</div>}
            {success && <div className="text-green-500">{success}</div>}
        </div>
    )
}
