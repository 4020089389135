import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { HeroDonutChart } from '~/hero-ui/stories/visualizations/Donut/HeroDonutChart'
import { useUtilizationQuery } from '~/hooks/tanstack-query/optimus/useUtilizationQuery'
import { BlockSchedule } from '~/store/selectors'

type Props = {
    blockSchedule: BlockSchedule | null
}

export const Utilization = ({ blockSchedule }: Props) => {
    // 'can' refers to the current limitation of Optimus API to only provide duration data for block schedules
    // with the rule_definition_id of 'max_count_of_surgeries_per_patient_group'
    const canGenerateData = !!blockSchedule?.id && blockSchedule.rule_instance?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'

    const { data, isLoading, isPending } = useUtilizationQuery({
        request: { blockScheduleId: blockSchedule?.id ?? 0, updatedAt: blockSchedule?.updated_at ?? '' },
        isEnabled: canGenerateData,
    })

    return (
        <>
            {isPending && <div data-test="utilization-chart-isloading" hidden />}

            <HeroTooltip side="top" tooltipContent={<p>{data?.description ?? 'loading'}</p>} className="w-48">
                <div
                    className={`mb-1 flex w-fit items-center gap-1 rounded border border-slate-200 p-1 ${isLoading ? 'animate-pulse-fast bg-gradient-to-l from-slate-200 via-slate-50 to-slate-200 bg-large' : ''}`}
                    data-test="utilization-chart-loaded"
                >
                    <HeroDonutChart
                        showTooltip={false}
                        className="h-4 w-4"
                        data={[
                            {
                                name: 'Utilization',
                                utilization: data?.utilization ?? 0,
                            },
                            {
                                name: 'Available',
                                utilization: 1 - (data?.utilization ?? 0),
                            },
                        ]}
                        category="name"
                        value="utilization"
                        colors={['emerald', 'gray']}
                    />
                    <p>{data && (data?.utilization * 100).toPrecision(2)}%</p>
                </div>
            </HeroTooltip>
        </>
    )
}
