import { CopyToClipboard, Show } from '~/components'
import { CopyIcon } from '~/icons'
import { useStore } from '~/store/store'
import { getAgeAndPersonalId, getFullNameAndMunicipality } from '~/utils/dips'

import { useGetWaitingListSurgery } from '../hooks/useGetWaitingListSurgery'

export const Header = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const surgery = useGetWaitingListSurgery(drawerProps?.item)

    const fullNameAndMunicipality = getFullNameAndMunicipality(surgery?.patient)
    const { age, personalId } = getAgeAndPersonalId(surgery?.patient)
    const ageAndPersonalId = [age, personalId].filter(Boolean).join(', ') || '??'
    const hasPersonalId = personalId !== ''

    if (drawerProps === null) return <div />

    return (
        <header>
            <div className="flex flex-col">
                <div className="text-base font-bold">{fullNameAndMunicipality}</div>
                <div className="flex items-center gap-1">
                    {ageAndPersonalId}
                    <Show condition={hasPersonalId}>
                        <CopyToClipboard copyText={personalId.replace(/\s+/g, '')}>
                            <CopyIcon className="h-4 w-4" />
                        </CopyToClipboard>
                    </Show>
                </div>
            </div>
        </header>
    )
}
