import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { DipsSchemas } from '~/clients/dips-client'
import { ScheduledSurgery, UnScheduledSurgery } from '~/store/selectors'

import { day } from '../extendedDayjs'

export type MinimalScheduledSurgery = DipsSchemas['MinimalScheduledSurgeryResult']
export type AnyScheduledSurgery = UnScheduledSurgery | ScheduledSurgery

function getDayjsDuration(duration: string | undefined): Duration {
    return duration ? dayjs.duration(duration) : dayjs.duration(0, 'minutes')
}

export function getRoomDuration(surgery: MinimalScheduledSurgery): Duration {
    return getDayjsDuration(surgery.plannedDuration)
}

export function getKnifeTime(surgery: MinimalScheduledSurgery): Duration {
    return getDayjsDuration(surgery.plannedProcedureDuration)
}

export const sortByWaitingTime = (surgery1: UnScheduledSurgery, surgery2: UnScheduledSurgery) =>
    day(surgery1.creationTime)?.unix() - day(surgery2.creationTime).unix()

/**
 * Currently, we differentiate between scheduled and unscheduled surgeries by the presence of the 'id' field.
 */
export const isScheduledSurgery = (surgery: AnyScheduledSurgery): surgery is ScheduledSurgery => 'id' in surgery

export const isUnScheduledSurgery = (item: AnyScheduledSurgery): item is UnScheduledSurgery => {
    return 'bookingId' in item
}
