import { ComponentProps, FC } from 'react'

export const TrashIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.6666 2.64998C6.66218 2.64998 6.65794 2.65173 6.65482 2.65486C6.65169 2.65798 6.64993 2.66222 6.64993 2.66664V4.01664H9.34994V2.66664C9.34994 2.66222 9.34818 2.65798 9.34505 2.65486C9.34193 2.65173 9.33769 2.64998 9.33327 2.64998H6.6666ZM10.6499 4.01664V2.66664C10.6499 2.31744 10.5112 1.98254 10.2643 1.73562C10.0174 1.4887 9.68247 1.34998 9.33327 1.34998H6.6666C6.3174 1.34998 5.9825 1.4887 5.73558 1.73562C5.48866 1.98254 5.34994 2.31744 5.34994 2.66664V4.01664H3.34087C3.3363 4.01659 3.33171 4.01659 3.32711 4.01664H2.6666C2.30762 4.01664 2.0166 4.30766 2.0166 4.66664C2.0166 5.02563 2.30762 5.31664 2.6666 5.31664H2.73518L3.35016 12.6963C3.35787 13.2116 3.5659 13.7041 3.93084 14.0691C4.30279 14.441 4.80726 14.65 5.33327 14.65H10.6666C11.1926 14.65 11.6971 14.441 12.069 14.0691C12.434 13.7041 12.642 13.2116 12.6497 12.6963L13.2647 5.31664H13.3333C13.6923 5.31664 13.9833 5.02563 13.9833 4.66664C13.9833 4.30766 13.6923 4.01664 13.3333 4.01664H12.6728C12.6682 4.01659 12.6636 4.01659 12.659 4.01664H10.6499ZM4.03969 5.31664L4.64769 12.6127C4.64919 12.6306 4.64993 12.6486 4.64993 12.6666C4.64993 12.8479 4.72193 13.0217 4.85008 13.1498C4.97823 13.278 5.15204 13.35 5.33327 13.35H10.6666C10.8478 13.35 11.0216 13.278 11.1498 13.1498C11.2779 13.0217 11.3499 12.8479 11.3499 12.6666C11.3499 12.6486 11.3507 12.6306 11.3522 12.6127L11.9602 5.31664H4.03969ZM6.6666 6.68331C7.02559 6.68331 7.3166 6.97432 7.3166 7.33331V11.3333C7.3166 11.6923 7.02559 11.9833 6.6666 11.9833C6.30762 11.9833 6.0166 11.6923 6.0166 11.3333V7.33331C6.0166 6.97432 6.30762 6.68331 6.6666 6.68331ZM9.33327 6.68331C9.69225 6.68331 9.98327 6.97432 9.98327 7.33331V11.3333C9.98327 11.6923 9.69225 11.9833 9.33327 11.9833C8.97428 11.9833 8.68327 11.6923 8.68327 11.3333V7.33331C8.68327 6.97432 8.97428 6.68331 9.33327 6.68331Z"
            fill="currentColor"
        />
    </svg>
)
