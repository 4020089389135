import { memo } from 'react'

export const BookingFormOverview = memo(function BookingFormOverview({
    operation,
    bookingDate,
    room,
    plannedProcedureDuration,
    totalRoomDuration,
}: {
    operation: string
    bookingDate: string
    room: string
    plannedProcedureDuration: string
    totalRoomDuration: string
}) {
    return (
        <div className="grid grid-cols-[auto_1fr_auto] gap-3">
            <div className="text-gray-600">Hva:</div>
            <div>{operation}</div>
            <div className="text-right">
                <span className="text-gray-600">Knivtid:</span> {plannedProcedureDuration}
            </div>
            <div className="text-gray-600">Hvor:</div>
            <div>
                <span className="capitalize">{bookingDate}</span> på {room}
            </div>
            <div className="text-right">
                <span className="text-gray-600">Total stuetid:</span> {totalRoomDuration}
            </div>
        </div>
    )
})
