import { ComponentProps, FC } from 'react'

export const HeartRateIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66676 3.31666C2.66234 3.31666 2.65811 3.31842 2.65498 3.32154C2.65185 3.32467 2.6501 3.32891 2.6501 3.33333V10C2.6501 10.0044 2.65185 10.0087 2.65498 10.0118C2.65811 10.0149 2.66234 10.0167 2.66676 10.0167H13.3334C13.3379 10.0167 13.3421 10.0149 13.3452 10.0118C13.3483 10.0087 13.3501 10.0044 13.3501 10V3.33333C13.3501 3.32891 13.3483 3.32467 13.3452 3.32154C13.3421 3.31842 13.3379 3.31666 13.3334 3.31666H2.66676ZM10.6501 11.3167H13.3334C13.6826 11.3167 14.0175 11.1779 14.2645 10.931C14.5114 10.6841 14.6501 10.3492 14.6501 10V3.33333C14.6501 2.98413 14.5114 2.64923 14.2645 2.40231C14.0175 2.15538 13.6826 2.01666 13.3334 2.01666H2.66676C2.31756 2.01666 1.98266 2.15538 1.73574 2.40231C1.48882 2.64923 1.3501 2.98413 1.3501 3.33333V10C1.3501 10.3492 1.48882 10.6841 1.73574 10.931C1.98266 11.1779 2.31756 11.3167 2.66676 11.3167H5.3501V12.6833H4.66676C4.30778 12.6833 4.01676 12.9743 4.01676 13.3333C4.01676 13.6923 4.30778 13.9833 4.66676 13.9833H11.3334C11.6924 13.9833 11.9834 13.6923 11.9834 13.3333C11.9834 12.9743 11.6924 12.6833 11.3334 12.6833H10.6501V11.3167ZM9.3501 11.3167H6.6501V12.6833H9.3501V11.3167ZM8.66676 4.01666C8.94654 4.01666 9.19493 4.19569 9.28341 4.46111L9.80192 6.01666H11.3334C11.6924 6.01666 11.9834 6.30768 11.9834 6.66666C11.9834 7.02565 11.6924 7.31666 11.3334 7.31666H9.33343C9.05365 7.31666 8.80526 7.13763 8.71679 6.87221L8.66676 6.72214L7.95008 8.87221C7.87157 9.10773 7.66577 9.27796 7.41971 9.31091C7.17365 9.34386 6.93031 9.23378 6.7926 9.02722L5.65223 7.31666H4.66676C4.30778 7.31666 4.01676 7.02565 4.01676 6.66666C4.01676 6.30768 4.30778 6.01666 4.66676 6.01666H6.0001C6.21743 6.01666 6.42038 6.12528 6.54093 6.30611L7.13706 7.2003L8.05012 4.46111C8.13859 4.19569 8.38698 4.01666 8.66676 4.01666Z"
            fill="currentColor"
        />
    </svg>
)
