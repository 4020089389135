import { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import { HeroDayPicker } from '~/hero-ui/stories/HeroDayPicker/HeroDayPicker'
import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { CalendarMonthIcon } from '~/icons'
import { OccupancyData, UnScheduledSurgery } from '~/store/selectors'
import { day, format, getToday } from '~/utils/extendedDayjs'
import getMonthRangeFromToday from '~/utils/extendedDayjs/helpers/getMonthRangeFromToday'
import { formatDateToNorskReadableIdDate } from '~/utils/utils'

import { useGetBookableOccupancies } from '../../hooks/useGetBookableOccupancies'

type Props = {
    surgery: UnScheduledSurgery
    /**
     * @param occupancy The bookable occupancies for the associated surgery.
     *                  The empty array means none of the traversed dates have any availability;
     *                  undefined means we're still loading
     */
    setBookableOccupancies: (occupancy: OccupancyData[] | undefined) => void
}

function useSelectFirstAvailableEffect(
    select: (date: Dayjs | null) => void,
    availableOccupanciesWithPractitioner: Record<string, OccupancyData[]>,
    isLoading: boolean
) {
    const [usedEffect, setUsedEffect] = useState(false)
    useEffect(() => {
        if (!usedEffect && !isLoading) {
            setUsedEffect(true)
            const date = Object.values(availableOccupanciesWithPractitioner).find(occupancies => occupancies.length !== 0)?.[0]?.date ?? null
            select(date)
        }
    }, [availableOccupanciesWithPractitioner, isLoading, select, usedEffect])
}

export const AvailableDaysPicker = ({ setBookableOccupancies, surgery }: Props) => {
    // selectedDay: null means nothing bookable was found in the range; undefined means no date selected (yet), i.e. we're loading
    const [selectedDay, setSelectedDay] = useState<Dayjs | null | undefined>()
    const today = getToday()
    const [month, setMonth] = useState<Dayjs>(today)
    const range = useMemo(() => getMonthRangeFromToday(month), [month])

    const { isLoading } = useImportOccupancyEntities(format(range.start), format(range.end))
    const bookableOccupancies = useGetBookableOccupancies(range, surgery)
    function select(date: Dayjs | null | undefined) {
        setSelectedDay(date)
        if (date === undefined) setBookableOccupancies(undefined)
        else if (date === null) setBookableOccupancies([])
        else setBookableOccupancies(bookableOccupancies[format(day(date), 'YYYY-MM-DD')] ?? [])
    }

    useSelectFirstAvailableEffect(select, bookableOccupancies, isLoading)
    return (
        <div className="relative flex w-full flex-col rounded border border-slate-200 px-4 py-2">
            <p className="text-slate-400">Dato: </p>
            {(selectedDay && <p>{formatDateToNorskReadableIdDate(selectedDay).label}</p>) || <p className="text-slate-400">Ingen dato valgt</p>}
            <HeroPopover
                noPadding
                popoverContent={
                    <HeroDayPicker
                        className="w-96"
                        required
                        disabled={[
                            isLoading,
                            { before: today.toDate() },
                            date => {
                                const hasAvailability = (bookableOccupancies[format(day(date), 'YYYY-MM-DD')] ?? []).length > 0
                                return !hasAvailability
                            },
                        ]}
                        onMonthChange={date => setMonth(day(date))}
                        weekdaysOnly={true}
                        startMonth={today.toDate()}
                        month={month.toDate()}
                        selected={selectedDay?.toDate()}
                        onSelect={date => select(day(date))}
                        mode="single"
                        fixedWeeks
                    />
                }
            >
                <button
                    className="absolute right-4 top-1 flex items-center gap-1 rounded bg-white px-2 py-1 text-xs shadow hover:bg-slate-50"
                    data-test="available-days-picker-trigger"
                >
                    <CalendarMonthIcon width={16} /> Åpne datovelger
                </button>
            </HeroPopover>
        </div>
    )
}
