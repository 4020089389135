import { ComponentProps, FC } from 'react'

export const PillIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6665 2.68348C9.96369 2.68348 9.28969 2.96266 8.79275 3.4596L6.58571 5.66665L10.3331 9.41407L12.5402 7.20703C13.0371 6.71009 13.3163 6.03609 13.3163 5.33332C13.3163 4.63054 13.0371 3.95654 12.5402 3.4596C12.0432 2.96266 11.3692 2.68348 10.6665 2.68348ZM13.4594 8.12627C14.2002 7.38553 14.6163 6.38088 14.6163 5.33332C14.6163 4.28575 14.2002 3.2811 13.4594 2.54036C12.7187 1.79963 11.714 1.38348 10.6665 1.38348C9.6189 1.38348 8.61425 1.79963 7.87351 2.54036L5.20701 5.20686L2.54018 7.8737C1.79944 8.61443 1.3833 9.61909 1.3833 10.6666C1.3833 11.7142 1.79944 12.7189 2.54018 13.4596C3.28092 14.2003 4.28557 14.6165 5.33313 14.6165C6.38069 14.6165 7.38535 14.2003 8.12608 13.4596L13.4594 8.12627ZM9.41389 10.3333L5.66647 6.58589L3.45942 8.79293C2.96248 9.28987 2.6833 9.96387 2.6833 10.6666C2.6833 11.3694 2.96248 12.0434 3.45942 12.5404C3.95636 13.0373 4.63035 13.3165 5.33313 13.3165C6.03591 13.3165 6.70991 13.0373 7.20685 12.5404L9.41389 10.3333Z"
            fill="currentColor"
        />
    </svg>
)
