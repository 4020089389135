import { CrossIcon } from '@storybook/icons'
import cn from 'clsx'
import { useEffect, useMemo, useState } from 'react'

import optimusClient from '~/clients/optimus-client'
import { Button, Spinner } from '~/components'
import { CheckIcon, MagicIcon } from '~/icons'
import { selectGetPractitionerSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'

type Status = NonNullable<Awaited<ReturnType<typeof optimusClient.staffingPlan.getStatus>>['data']>['status'] | null

export const MagicButton = () => {
    const [status, setStatus] = useState<Status>(null)

    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)

    // 2025 march is hardcoded in the backend
    const isCorrectDateForMagicButton = selectedDate.year() === 2025 && selectedDate.month() === 2
    const isSingleDepartmentSelected = departmentKey !== 'all'
    // If the magic button was applied once, it should be disabled (even if user edited the schedule)

    const wasAppliedOnce = useMemo(() => {
        const practitionerSchedules = getPractitionerSchedules.byMonth(selectedDate)
        const practitionerSchedulesByDepartment = practitionerSchedules.filter(schedule =>
            schedule.practitioner?.departments.some(department => department.id === departmentKey)
        )

        return practitionerSchedulesByDepartment.some(
            schedule =>
                schedule.statuses.some(status => status.generated && status.updated_by === 'hero') ||
                schedule.locations.some(status => status.generated && status.updated_by === 'hero')
        )
    }, [departmentKey, getPractitionerSchedules, selectedDate])

    // on page refresh
    useEffect(() => {
        const fetchStatus = async () => {
            if (departmentKey === 'all') {
                return
            }
            try {
                const { data } = await optimusClient.staffingPlan.getStatus(departmentKey, selectedDate.year(), selectedDate.month() + 1)
                const newStatus = data?.status ?? null
                setStatus(newStatus)
            } catch (error) {
                console.error('Error fetching status:', error)
            }
        }

        fetchStatus().catch(console.error)
    }, [departmentKey, selectedDate])

    useEffect(() => {
        let timerId: NodeJS.Timeout

        const pollingCallback = async () => {
            if (departmentKey === 'all') {
                return
            }
            try {
                const { data } = await optimusClient.staffingPlan.getStatus(departmentKey, selectedDate.year(), selectedDate.month() + 1)
                const newStatus = data?.status ?? null
                setStatus(newStatus)

                if (newStatus === 'failure' || newStatus === 'success') {
                    clearInterval(timerId)
                }
            } catch (error) {
                console.error('Error fetching status:', error)
                clearInterval(timerId)
            }
        }

        if (status === 'pending' || status === 'running') {
            timerId = setInterval(pollingCallback, 1000)
        }

        return () => clearInterval(timerId)
    }, [departmentKey, selectedDate, status])

    async function requestNewSuggestion(department_id: number, year: number, month: number) {
        const { data } = await optimusClient.staffingPlan.suggests({
            department_id,
            year,
            month,
        })
        setStatus(data?.status ?? null)
    }

    async function applySuggestion(department_id: number, year: number, month: number) {
        const { data: suggestion, error } = await optimusClient.staffingPlan.getSuggestion(department_id, year, month)
        if (error) {
            setStatus('failure')
        }
        if (suggestion) {
            await optimusClient.staffingPlan.apply(suggestion)
        }
    }

    return (
        <>
            {isCorrectDateForMagicButton && isSingleDepartmentSelected && (
                <Button
                    color="custom"
                    data-test="surgeon-scheduler-magic-wand-button"
                    className={cn('flex items-center justify-between gap-1', {
                        'bg-[#804EFD] pl-2 text-white hover:bg-[#7646ED]':
                            status === 'success' || status === 'pending' || status === 'not_started' || status === null,
                        'bg-[#F4F0FE] pl-2 text-[#804EFD] hover:bg-[#F4F0FE]': status === 'running',
                        'bg-diRed-400 pl-2 text-white hover:bg-diRed-300': status === 'failure',
                    })}
                    disabled={wasAppliedOnce}
                    onClick={async () => {
                        if (status !== 'success') {
                            await requestNewSuggestion(departmentKey, selectedDate.year(), selectedDate.month() + 1)
                        } else {
                            await applySuggestion(departmentKey, selectedDate.year(), selectedDate.month() + 1)
                        }
                    }}
                >
                    {(status === null || status === 'not_started') && (
                        <>
                            <MagicIcon className="h-4 w-4" color="#F4F0FE" />
                            Gi forslag
                        </>
                    )}

                    {(status === 'running' || status === 'pending') && (
                        <>
                            <Spinner className="h-4 w-4 fill-[#804EFD]" />
                            <span>Foreslår...</span>
                        </>
                    )}

                    {status === 'success' && (
                        <>
                            <CheckIcon className="h-4 w-4" />
                            Legg til
                        </>
                    )}

                    {status === 'failure' && (
                        <>
                            <CrossIcon className="mr-0.5 h-3 w-3" />
                            Prøv igjen
                        </>
                    )}
                </Button>
            )}
        </>
    )
}
