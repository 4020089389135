import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { OccupancyData, selectGetOccupancies, selectGetScheduledPractitioners, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getSurgeonsAndAssistants } from '~/utils/dips'
import { format, getDaysBetween } from '~/utils/extendedDayjs'
import { hasMatchingPractitioner } from '~/utils/hasMatchingPractitioner'
import { occupancyHasAvailabilityForSurgery } from '~/utils/occupancyHasAvailabilityForSurgery'

/* Gets whether any of the specified practitioners is available in any occupancy with availability. */
export function useGetBookableOccupancies(range: { start: Dayjs; end: Dayjs }, surgery: UnScheduledSurgery): Record<string /* YYYY-MM-DD */, OccupancyData[]> {
    // FUNCTIONAL REQUIREMENTS:
    // a surgery is bookable on a date + location (=schedule) IF:
    // the date + location match the surgery's type AND the surgery's practitioner AND the date + location has availability.
    //
    // definitions:
    // - practitioners match if:
    //   - the surgery has no practitioners assigned OR `matchAnyPractitioner` is true, AND any practitioner has availability;
    //   - OR any of the surgery's practitioner is scheduled on that date + location
    // - availability of a date + location is defined as:
    //   - the surgery has no surgery type AND any count based rule is not null
    //   - OR the location has a rule for the surgery's type that is count based AND not full
    // - surgery's type match if the location has a count-based rule for the surgery's type (which is automatically satisfied by the above)

    const getOccupancies = useStore(selectGetOccupancies)

    const getScheduledPractitioners = useStore(selectGetScheduledPractitioners)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const matchAnyPractitioner = useStore(state => state.waitingList.showAllPractitioners)
    const surgeryPractitioners = useMemo(() => getSurgeonsAndAssistants(surgery?.surgeryResources), [surgery])

    function practitionersMatch(schedule: OccupancyData): boolean {
        const scheduledPractitioners = getScheduledPractitioners.byDateAndLocationId(schedule.date, schedule.location.id)
        return (
            scheduledPractitioners.length !== 0 &&
            (surgeryPractitioners.length === 0 || matchAnyPractitioner || hasMatchingPractitioner(scheduledPractitioners, surgeryPractitioners))
        )
    }

    return Object.fromEntries(
        getDaysBetween(range.start, range.end).map(date => [
            format(date, 'YYYY-MM-DD'),
            getOccupancies
                .byDate(date, departmentKey)
                .filter(schedule => practitionersMatch(schedule) && occupancyHasAvailabilityForSurgery(schedule, surgery.surgeryType)),
        ])
    )
}
