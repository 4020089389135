import { useQuery } from '@tanstack/react-query'

import optimusClient from '~/clients/optimus-client'

import { handleApiRequest } from './shared/apiWrapper'

type Request = { blockScheduleId: number; updatedAt: string }

const zeroDurationToken = 'P0DT0H0M0.000000S'
export const serializeKeysForCache = (request: Request) => ['blockScheduleDuration', ...Object.values(request)]
export const useDurationQuery = ({ request, isEnabled }: { request: Request; isEnabled?: boolean }) =>
    useQuery({
        queryKey: serializeKeysForCache(request),
        queryFn: async () => {
            return handleApiRequest(() => optimusClient.getBlockScheduleDuration(request.blockScheduleId))
        },
        enabled: isEnabled,
        placeholderData: isEnabled ? { duration: zeroDurationToken, description: '' } : undefined,
    })
