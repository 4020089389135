import { Dayjs } from 'dayjs'

import { createSorter, SurgeryForSorting } from './rankedSorting'

/**
 * Sorts surgeries based a variety of factors, such as:
 * - How long the surgery has been waiting
 * - If the surgery is short notice
 * - If the surgery is prioritized
 * - If the surgery is cleared
 *
 * Smart Sorting = Prioritization + Ranked Sorting
 *
 * Prioritization means putting prioritized surgeries always first, as in pinning to the top.
 * Within the prioritized surgeries, we still sort by the ranked sorting.
 */
export function smartSort<T extends SurgeryForSorting>({ surgeries, bookingDate }: { surgeries: T[]; bookingDate: Dayjs }): T[] {
    const sortByScore = createSorter(bookingDate)
    const sorted = surgeries.toSorted(sortByScore)
    const prioritized = sorted.filter(surgery => surgery.surgeryMetadata?.patient_prioritized)
    const notPrioritized = sorted.filter(surgery => !surgery.surgeryMetadata?.patient_prioritized)
    return [...prioritized, ...notPrioritized]
}
