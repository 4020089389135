import clsx from 'clsx'
import { ReactNode, useRef, useState } from 'react'

import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { AdjustmentsHorizontalIcon, CloseIcon } from '~/icons'

import { Show } from '../Show'
import { Tag } from '../Tag'

type Props = {
    children: ReactNode
    displayItems: { id: string; label: string }[]
    onDisplayItemRemove: (id: string) => void
    clearFilters: () => void
    maxOptions?: number
}

export const FilterDropdown = ({ children, displayItems, onDisplayItemRemove, clearFilters, maxOptions = 2 }: Props) => {
    const triggerRef = useRef<HTMLDivElement>(null)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState(false)
    const hasAnyFilteredItems = displayItems.length > 0

    return (
        <HeroPopover
            noPadding
            isOpen={isOpen}
            onPointerDownOutside={() => setIsOpen(false)}
            side="bottom"
            popoverContent={
                <div ref={dropdownRef} className="relative w-[340px]">
                    <div className="flex items-center justify-between border-b px-4 py-2">
                        <p className="text-base">Filter</p>
                        <CloseIcon className="cursor-pointer rounded-full hover:bg-blue-900/40" onClick={() => setIsOpen(false)} />
                    </div>

                    {children}
                </div>
            }
        >
            <div className="flex items-center gap-x-2">
                <div
                    data-test="filter"
                    ref={triggerRef}
                    onClick={() => setIsOpen(prev => !prev)}
                    className={clsx('flex cursor-pointer items-center gap-x-2 rounded border border-indigo-700 px-3 py-1 text-indigo-700', {
                        'bg-indigo-700/10': isOpen || hasAnyFilteredItems,
                    })}
                >
                    <AdjustmentsHorizontalIcon className="h-5 w-5" />
                    Filter
                    {displayItems.slice(0, maxOptions).map(({ id, label }) => (
                        <Tag key={id} size="md" defaultCursor>
                            <div className="max-w-[100px] truncate whitespace-nowrap" data-test={'filter-' + id}>
                                {label}
                            </div>
                            <CloseIcon
                                onClick={e => {
                                    e.stopPropagation()
                                    onDisplayItemRemove(id)
                                }}
                                className="h-4 w-4 cursor-pointer"
                            />
                        </Tag>
                    ))}
                    <Show condition={displayItems.length > maxOptions}>
                        <Tag size="md" defaultCursor>
                            +{displayItems.length - maxOptions}
                        </Tag>
                    </Show>
                </div>

                <div
                    data-test="clear-filters"
                    className={clsx('inline-flex cursor-pointer items-center gap-x-2 whitespace-nowrap text-indigo-700', {
                        hidden: !hasAnyFilteredItems,
                    })}
                    onClick={clearFilters}
                >
                    Tøm filter
                    <CloseIcon className="h-5 w-5" />
                </div>
            </div>
        </HeroPopover>
    )
}
