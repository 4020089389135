import { isCountBasedRuleEvaluation, isDurationRuleEvaluation, OccupancyData, OccupancyStatus, RuleEvaluation } from '~/store/selectors'
import { compareSurgeryTypeGroups } from '~/store/utils/blockEvaluation'

import { AvailabilityByPatientGroup } from '../../shared/AvailabilityByPatientGroup'
import { AvailabilityByTime } from '../../shared/AvailabilityByTime'
import { UsableTime } from '../../shared/UsableTime'
import { getUsableRoomTime } from '../utils'

type Props = {
    occupancyData: OccupancyData
    'data-test'?: string // of format "<room_code>-<yyyy-MM-dd>"
}

function compareByPatientGroup(a: RuleEvaluation, b: RuleEvaluation): number {
    if (isDurationRuleEvaluation(a) === isDurationRuleEvaluation(b)) {
        return compareSurgeryTypeGroups(a.filteredByPatientGroup, b.filteredByPatientGroup)
    }

    // Number of surgeries are displayed above duration in the AvailabilitiesList
    return isCountBasedRuleEvaluation(a) ? -1 : 1
}

export function shouldDisplay(evaluation: RuleEvaluation) {
    if (isDurationRuleEvaluation(evaluation) && !evaluation.maxValue) {
        return false
    }

    // Show only when there is some availability
    return evaluation.status === OccupancyStatus.Available
}

export const AvailabilitiesList = ({ occupancyData, 'data-test': data_test }: Props) => {
    const usableRoomTime = getUsableRoomTime(occupancyData)
    if (usableRoomTime !== null) {
        return <UsableTime usableAvailableRoomTime={usableRoomTime} occupancyData={occupancyData} />
    }

    return (
        <div className="flex flex-col gap-2">
            {occupancyData.evaluations
                .filter(shouldDisplay)
                .sort(compareByPatientGroup)
                .map((evaluation, index) => {
                    if (isDurationRuleEvaluation(evaluation)) {
                        return <AvailabilityByTime key={index} asTag={true} data-test={data_test} evaluation={evaluation} />
                    }
                    return <AvailabilityByPatientGroup key={index} data-test={data_test} evaluation={evaluation} />
                })}
        </div>
    )
}
