export type ApiResponse<T, Err> = {
    response: Response
    data?: T
    error?: Err
}

// Wrapper function
export async function handleApiRequest<T, Err>(apiCall: () => Promise<ApiResponse<T, Err>>): Promise<T | undefined> {
    try {
        const res = await apiCall()

        if (!res.response.ok) {
            // Check if the error is a JSON object with a 'detail' key (as passed from optimus)
            const errorMessage = res?.error && typeof res?.error === 'object' && 'detail' in res.error ? (res.error.detail as string) : 'No data found'
            throw new Error(errorMessage)
        }
        return res.data
    } catch (e: unknown) {
        throw e instanceof Error ? e : new Error('An unexpected error occurred when fetching data')
    }
}
