import { InfoCircleIcon } from '~/icons'

export const NotShownInDIPSWarning = () => (
    <div className="flex flex-col gap-2 rounded bg-yellow-100 p-4">
        <p className="flex items-center gap-2 font-semibold">
            <InfoCircleIcon />
            Den nye kommentaren vil ikke vises i DIPS
        </p>
        <p>Kommentaren du skriver her vil ikke oppdateres i DIPS. Du finner den opprinnelige oppmøtekommentaren fra DIPS under.</p>
    </div>
)

export const NotShownInDIPSHint = () => (
    <p className="flex items-center gap-2">
        <InfoCircleIcon />
        Denne kommentaren vises ikke i DIPS
    </p>
)
