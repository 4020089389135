import { ComponentProps, FC } from 'react'

export const CheckIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7927 4.20704C14.0466 4.46088 14.0466 4.87244 13.7927 5.12628L7.12606 11.7929C6.87222 12.0468 6.46066 12.0468 6.20682 11.7929L2.87349 8.45962C2.61965 8.20577 2.61965 7.79422 2.87349 7.54038C3.12733 7.28654 3.53888 7.28654 3.79272 7.54038L6.66644 10.4141L12.8735 4.20704C13.1273 3.9532 13.5389 3.9532 13.7927 4.20704Z"
            fill="currentColor"
        />
    </svg>
)
