import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { isCountBasedRuleEvaluation, OccupancyData, OccupancyStatus } from '~/store/selectors'
import { getTotalRoomDuration, summarizeCapacityEvaluations } from '~/store/utils/blockEvaluation'
import { subtractDuration } from '~/utils/extendedDayjs'

const minimumAvailableRoomTime_ms = dayjs.duration({ hours: 1 }).asMilliseconds()
/**
 * Gets the remaining available time in an OR.
 * If that doesn't surpass a fixed minimum threshold, we return `null`.
 */
export function getAvailableRoomTime(data: OccupancyData): Duration | null {
    const bookedDuration = getTotalRoomDuration([...data.bookedSurgeries, ...data.mismatchedSurgeries])
    const openingHours = data.openingDuration

    const availableDuration = subtractDuration(openingHours, bookedDuration)

    if (availableDuration.asMilliseconds() >= minimumAvailableRoomTime_ms) {
        return availableDuration
    }

    return null
}

export function getUsableRoomTime(data: OccupancyData): Duration | null {
    // "Usable" room time is available room time that is larger than a fixed minimum.
    // We want to display available time even if we have fully-booked count-based rules.
    // Also when we have non-fully booked count-based rules and mismatched surgeries we want to display the remaining time instead of the remaining counts (assuming it's >minimum).

    const availableRoomTime = getAvailableRoomTime(data)
    const isCountBased = data.evaluations.every(isCountBasedRuleEvaluation) // mixes of count- and duration-based currently are not supported
    const isFullyBooked = data.evaluations.filter(isCountBasedRuleEvaluation).every(rule => rule.remaining === 0 || rule.remaining === null)
    const hasMismatches = data.mismatchedSurgeries.length !== 0

    if (isCountBased && isFullyBooked) {
        return availableRoomTime
    } else if (isCountBased && hasMismatches) {
        return availableRoomTime
    }
    return null
}

/** Gets whether the block evaluations have any booking. */
export function hasAnyBooking(data: OccupancyData): boolean {
    return data.mismatchedSurgeries.length !== 0 || data.evaluations.some(evaluation => evaluation && evaluation.bookingIds.length !== 0)
}

/** Gets whether the block evaluations have any availabilities. */
export function hasAnyAvailability(data: OccupancyData): boolean {
    const hasUsableRoomTime = getUsableRoomTime(data) !== null
    if (hasUsableRoomTime) {
        return true
    }
    const isCountBased = data.evaluations.every(isCountBasedRuleEvaluation) // mixes of count- and duration-based currently are not supported
    const hasMismatches = data.mismatchedSurgeries.length !== 0
    if (hasMismatches && isCountBased /* and not hasUsableRoomTime */) {
        return false
    }

    return summarizeCapacityEvaluations(data.evaluations) === OccupancyStatus.Available
}
