import { useMemo } from 'react'

import { MultipleSelectWithTitle } from '~/components'
import { selectDiagnosisGroupValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { selectAllWaitingListItems } from '../../selectors/waitingListItems'
import { nullishDiagnosisGroupName } from '../../shared/utils'

function useDiagnosisGroupOptions() {
    const surgeries = useStore(selectAllWaitingListItems)

    const options = useMemo(() => {
        const uniqueDiagnosisGroupNames = [...new Set(surgeries.map(item => item.contact?.diagnosisGroupName))]

        return uniqueDiagnosisGroupNames
            .map(name => name || nullishDiagnosisGroupName)
            .map(name => ({ value: name, label: name }))
            .sort((a, b) => {
                // Sort nullish first
                if (a.label === nullishDiagnosisGroupName) return -1
                if (b.label === nullishDiagnosisGroupName) return 1
                return a.label.localeCompare(b.label)
            })
    }, [surgeries])

    return options
}

export const DiagnosisGroupFilter = () => {
    const options = useDiagnosisGroupOptions()
    const selectedValues = useStore(selectDiagnosisGroupValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const removeAllFilterByType = useStore(state => state.appFilters.actions.removeAllFilterByType)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'diagnosis-group', value, label })
        setSelectedPage(1)
    }

    const handleEmptyFilter = () => {
        removeAllFilterByType('diagnosis-group')
        setSelectedPage(1)
    }

    return (
        <MultipleSelectWithTitle
            title="Diagnosegruppe"
            values={selectedValues}
            options={options}
            onChange={({ value, label }) => handleOnChange(value, label)}
            emptyFilter={handleEmptyFilter}
        />
    )
}
