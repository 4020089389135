import { MaxCount, MaxDuration, MaxDurationOfSurgeriesPerSurgeryTypeGroup, MaxWeight, PatientGroupContainer } from '~/clients/derived-api-types'
import { RuleInstanceParameters } from '~/store/utils/blockEvaluation/ruleInstance'

function isMaxDuration(parameters: RuleInstanceParameters): parameters is MaxDurationOfSurgeriesPerSurgeryTypeGroup {
    return !Array.isArray(parameters?.constraints)
}

function getPatienGroups(parameters: RuleInstanceParameters): PatientGroupContainer[] {
    if (!parameters?.constraints) {
        return []
    }

    if (isMaxDuration(parameters)) {
        return parameters.constraints.patient_groups
    }

    return parameters.constraints.map(constraint => ({
        patient_group: constraint.patient_group,
    }))
}

function getMaxWeight(parameters: RuleInstanceParameters): MaxWeight[] {
    if (!parameters?.constraints) {
        return []
    }

    if (isMaxDuration(parameters)) {
        return parameters.constraints.patient_groups.map(constraint => ({
            patient_group: constraint.patient_group,
            max_weight: 0,
        }))
    }

    // The `max_weight` is derived from the `max_count` property of each constraint if it exists
    return parameters.constraints.map(constraint => ({
        patient_group: constraint.patient_group,
        max_weight: 'max_count' in constraint ? constraint.max_count : 0,
    }))
}

function getMaxCount(parameters: RuleInstanceParameters): MaxCount[] {
    if (!parameters?.constraints) {
        return []
    }

    if (isMaxDuration(parameters)) {
        return parameters.constraints.patient_groups.map(constraint => ({
            patient_group: constraint.patient_group,
            max_count: 0,
        }))
    }

    // The `max_count` is derived from the `max_weight` property of each constraint if it exists
    return parameters.constraints.map(constraint => ({
        patient_group: constraint.patient_group,
        max_count: 'max_weight' in constraint ? constraint.max_weight : 0,
    }))
}

function getMaxDuration(parameters: RuleInstanceParameters): MaxDuration {
    const patientGroups = getPatienGroups(parameters)

    return {
        max_duration: '',
        patient_groups: patientGroups,
    }
}

export function mapParametersToRuleInstnace(ruleId: string, parameters: RuleInstanceParameters): RuleInstanceParameters | null {
    if (ruleId === 'max_duration_of_surgeries_per_patient_group') {
        return { ...parameters, constraints: getMaxDuration(parameters) }
    }

    if (ruleId === 'max_count_of_surgeries_per_patient_group') {
        return { ...parameters, constraints: getMaxCount(parameters) }
    }

    if (ruleId === 'max_weight_of_surgeries_per_patient_group') {
        return { ...parameters, constraints: getMaxWeight(parameters) }
    }

    // Parameters are cleared there is no mapping for the provided ruleId
    return null
}
