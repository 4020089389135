import dayjs from 'dayjs'
import { useState } from 'react'
import { CalendarWeek, DateRange, DayPicker, DayPickerProps, DayProps, getDefaultClassNames, WeekdayProps } from 'react-day-picker'
// @ts-expect-error - Missing types
import { nb } from 'react-day-picker/locale'

import { day } from '~/utils/extendedDayjs'

type DiProps = DayPickerProps & {
    /**
     * Determines if the calendar should only show weekdays
     */
    weekdaysOnly?: boolean
}

const selectedStyles = 'relative bg-[#E6F4FE] text-black bg-blue rounded'
const todayStyles =
    'relative rounded text-black after:border-blue-500 after:rounded after:border after:block after:top-0 after:absolute after:w-full after:h-full after:pointer-events-none'

/**
 * A date picker component that allows the user to select a single day, multiple days, or a range of days.
 */
export const HeroDayPicker = ({ weekdaysOnly, showOutsideDays, ...rest }: DiProps) => {
    const defaultClassNames = getDefaultClassNames()
    const [hoverRange, setHoverRange] = useState<DateRange | undefined>(undefined)

    const rootRenderer = (props: React.HTMLAttributes<HTMLDivElement>) => {
        return <div {...props} onMouseLeave={() => setHoverRange(undefined)} />
    }

    // Hides the weekends
    const dayRenerder = (props: DayProps) => {
        const { day: renderDay } = props

        if (renderDay.outside && !showOutsideDays && !rest.fixedWeeks) return <td />

        if (weekdaysOnly && (day(renderDay.date).day() === 6 || day(renderDay.date).day() === 0)) {
            return <td />
        }
        // @ts-expect-error - Missing types
        return <td {...props} data-test={props['data-disabled'] ? 'booking-day-disabled' : 'booking-day-available'} />
    }

    const weekRenderer = (
        props: {
            week: CalendarWeek
        } & React.ClassAttributes<HTMLTableRowElement> &
            React.HTMLAttributes<HTMLTableRowElement>
    ) => {
        const { days } = props.week
        if (rest.fixedWeeks) return <tr {...props} />
        if (weekdaysOnly && !showOutsideDays && days) {
            return days
                .filter(d => {
                    return day(d.date).day() > 0 && day(d.date).day() < 6
                })
                .every(day => day.outside) ? (
                <></>
            ) : (
                <tr {...props} />
            )
        }
        return <tr {...props} />
    }

    const weekdayRenderer = (props: WeekdayProps) => {
        // hide the column headers for the weekends
        if (weekdaysOnly && (props.children === 'Lø' || props.children === 'Sø')) {
            return <></>
        }

        return <th {...props} />
    }

    const weekNumberHeaderRenderer = () => <th className="pb-4">U</th>

    function formatWeekdayName(date: Date): string {
        return `${day(date).format('dd').at(0)?.toUpperCase() ?? ''}${day(date).format('dd').at(1) ?? ''}`
    }

    function formatWeekNumber(weekNumber: number): string {
        return `${weekNumber}`
    }

    function hasDisabledDatesInExtendedRange(date: Date): boolean {
        // If selection is not provided
        if (!('selected' in rest) || !rest.selected) {
            return false
        }

        const { disabled, selected } = rest

        // If selection is not a date range
        if (!('from' in selected) || !('to' in selected)) {
            return false
        }

        if (selected && Array.isArray(disabled) && disabled.length > 0) {
            return disabled.some(d => day(d as Date).isBetween(dayjs.min(day(selected.from), day(date)), dayjs.max(day(selected.to), day(date))))
        }

        return false
    }

    function hasDisabledDatesInRange(range: DateRange): boolean {
        const { disabled } = rest

        if (Array.isArray(disabled) && disabled.length > 0) {
            return disabled.some(d => day(d as Date).isBetween(day(range.from), day(range.to)))
        }

        return false
    }

    function getSelectionDiff(date: Date): DateRange | undefined {
        // If selection is not provided or mode is not 'range'
        if (!('selected' in rest) || rest.mode !== 'range') {
            return undefined
        }

        const { selected } = rest

        const range = {
            from: date,
            to: date,
        }

        // If only start of range is defined
        if (selected?.from && !selected.to) {
            // Expands the range to include the hovered date
            const extendedRange = {
                from: day(date).isBefore(selected.from, 'day') ? date : selected.from,
                to: day(date).isAfter(selected.from, 'day') ? date : selected.from,
            }

            // Returns extended range if there are no disabled dates in the range
            if (!hasDisabledDatesInRange(extendedRange)) {
                return extendedRange
            }
        }

        // Returns hovered range there is no previous selection or the range contains any disabled dates
        if (!selected?.from || !selected?.to || hasDisabledDatesInExtendedRange(date)) {
            return range
        }

        // No difference between selected and hovered day
        if ((range?.from === selected?.from && range?.to === selected?.to) || day(date).isBetween(selected?.from, selected?.to, 'day', '[]')) {
            return undefined
        }

        // Extends start of range when hovering over preceding days
        if (day(date).isBefore(selected.from, 'day')) {
            return {
                from: date,
                to: day(selected.from).subtract(1, 'day').toDate(),
            }
        }

        // Extends end of range when hovering over subsequent days
        if (day(date).isAfter(selected?.to, 'day')) {
            return {
                from: day(selected.to).add(1, 'day').toDate(),
                to: date,
            }
        }

        return undefined
    }

    function handleOnMouseEnter(date: Date): void {
        if (rest.mode === 'range') {
            setHoverRange(getSelectionDiff(date))
        }
    }

    function getModifiers() {
        // Only returns modifiers if the mode is 'range' and a selection is provided
        if (rest.mode !== 'range' || !('selected' in rest)) {
            return undefined
        }

        const { selected } = rest

        return {
            hoverRange,
            hoverRangeStart: hoverRange?.from,
            hoverRangeEnd: hoverRange?.to,
            selectedRange: selected,
            selectedRangeStart: selected?.from,
            selectedRangeEnd: selected?.to,
        }
    }

    return (
        <DayPicker
            locale={nb}
            mode="single"
            fixedWeeks
            showWeekNumber
            showOutsideDays={showOutsideDays}
            formatters={{ formatWeekdayName, formatWeekNumber }}
            onDayMouseEnter={handleOnMouseEnter}
            modifiers={getModifiers()}
            modifiersClassNames={{
                selectedRange: 'bg-blue-100',
                hoverRange: 'bg-gray-100',
            }}
            classNames={{
                months: 'flex gap-4 w-full justify-between',
                today: todayStyles, // Add a border to today's date
                selected: selectedStyles, // Highlight the selected day
                root: `${defaultClassNames.root} relative p-4 w-full`, // Add a shadow to the root element
                chevron: `${defaultClassNames.chevron} p-0.5`, // Change the color of the chevron
                day_button: 'w-full h-full align-center disabled:opacity-15  hover:bg-gray-100 rounded',
                nav: 'absolute top-4 right-4 flex gap-4 px-2 pb-4',
                day: 'rounded  h-[50px] w-[50px]',
                weeks: 'rounded',
                month_grid: 'w-full border-spacing-1 border-separate',
                month: 'capitalize grow',
                week_number: 'text-sm text-center h-[50px] max-w-8 min-w-8 font-bold text-white bg-diBlue-300 rounded  w-1',
                caption_label: 'text-md font-bold',
                month_caption: 'px-4 pb-8 text-lg',
                weekday: 'font-normal text-[#60646C] pb-4',
                range_start: 'bg-blue-100',
                range_end: 'bg-blue-100',
            }}
            {...rest}
            components={{
                Root: rootRenderer,
                Day: dayRenerder,
                Weekday: weekdayRenderer,
                WeekNumberHeader: weekNumberHeaderRenderer,
                Week: weekRenderer,
                ...rest.components,
            }}
        />
    )
}
