import { ComponentProps, FC } from 'react'

export const InboxIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.99993 3.31672C3.8187 3.31672 3.64489 3.38872 3.51675 3.51687C3.3886 3.64502 3.3166 3.81883 3.3166 4.00006V8.01672H4.6666C4.83899 8.01672 5.00432 8.08521 5.12622 8.2071L6.83333 9.91421C6.89897 9.97985 6.98799 10.0167 7.08082 10.0167H8.91905C9.01188 10.0167 9.1009 9.97985 9.16654 9.91421L10.8736 8.2071C10.9955 8.08521 11.1609 8.01672 11.3333 8.01672H12.6833V4.00006C12.6833 3.81883 12.6113 3.64502 12.4831 3.51687C12.355 3.38872 12.1812 3.31672 11.9999 3.31672H3.99993ZM13.9833 4.00006C13.9833 3.47404 13.7743 2.96958 13.4024 2.59763C13.0304 2.22568 12.5259 2.01672 11.9999 2.01672H3.99993C3.47392 2.01672 2.96945 2.22568 2.59751 2.59763C2.22556 2.96958 2.0166 3.47404 2.0166 4.00006V12.0001C2.0166 12.5261 2.22556 13.0305 2.59751 13.4025C2.96945 13.7744 3.47392 13.9834 3.99993 13.9834H11.9999C12.5259 13.9834 13.0304 13.7744 13.4024 13.4025C13.7743 13.0305 13.9833 12.5261 13.9833 12.0001V4.00006ZM12.6833 9.31672H11.6025L10.0858 10.8334C9.77635 11.1429 9.35666 11.3167 8.91905 11.3167H7.08082C6.64321 11.3167 6.22352 11.1429 5.91409 10.8335L4.39736 9.31672H3.3166V12.0001C3.3166 12.1813 3.3886 12.3551 3.51675 12.4832C3.6449 12.6114 3.8187 12.6834 3.99993 12.6834H11.9999C12.1812 12.6834 12.355 12.6114 12.4831 12.4832C12.6113 12.3551 12.6833 12.1813 12.6833 12.0001V9.31672Z"
            fill="currentColor"
        />
    </svg>
)
