import { ComponentProps, FC } from 'react'

export const NightSurgeryIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.80649 2.77979C6.26065 2.90488 5.73539 3.11545 5.25083 3.40593C4.42015 3.90389 3.74022 4.618 3.28358 5.47209C2.82693 6.32618 2.61069 7.2882 2.65793 8.25555C2.70517 9.2229 3.01411 10.1593 3.5518 10.9648C4.08949 11.7703 4.83576 12.4148 5.711 12.8295C6.58624 13.2441 7.55763 13.4134 8.52154 13.3193C9.48546 13.2251 10.4057 12.8711 11.1842 12.295C11.5753 12.0056 11.9231 11.6656 12.2197 11.2855C11.458 11.3606 10.6834 11.2807 9.94376 11.044C8.68552 10.6414 7.60813 9.81061 6.89889 8.69606C6.18964 7.5815 5.89338 6.25365 6.0616 4.94332C6.16046 4.17324 6.41607 3.43784 6.80649 2.77979ZM8.08345 1.34994H8.26212C8.52936 1.34994 8.76937 1.51351 8.8671 1.76225C8.96482 2.01099 8.90034 2.29419 8.70457 2.4761C7.95947 3.16845 7.48053 4.10001 7.35102 5.10885C7.2215 6.11769 7.4496 7.14002 7.99566 7.99813C8.54171 8.85624 9.3712 9.49587 10.3399 9.80584C11.3087 10.1158 12.3554 10.0765 13.2982 9.69479C13.5411 9.59642 13.8194 9.65359 14.0038 9.83976C14.1883 10.0259 14.2429 10.3047 14.1423 10.5467C13.6803 11.6584 12.9252 12.6238 11.9576 13.3399C10.9899 14.0561 9.84603 14.4961 8.6479 14.6131C7.44976 14.7301 6.24233 14.5197 5.15442 14.0043C4.0665 13.4889 3.13889 12.6878 2.47055 11.6865C1.80221 10.6853 1.4182 9.52136 1.35948 8.31896C1.30076 7.11655 1.56954 5.92076 2.13715 4.85914C2.70476 3.79752 3.5499 2.90988 4.58242 2.29092C5.61495 1.67196 6.79612 1.34489 7.99996 1.3446C8.02795 1.3446 8.05598 1.3464 8.08345 1.34994Z"
            fill="currentColor"
        />
    </svg>
)
