import { ComponentProps, FC } from 'react'

export const StarIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99534 0.684692C8.24276 0.684692 8.46872 0.825158 8.57822 1.04703L10.4843 4.90912L14.7459 5.52675C14.9909 5.56225 15.1944 5.73383 15.2709 5.96926C15.3474 6.2047 15.2834 6.46313 15.1061 6.6358L12.0178 9.6419L12.746 13.8868C12.7878 14.1308 12.6875 14.3773 12.4872 14.5227C12.2868 14.6681 12.0213 14.6871 11.8023 14.5717L7.99966 12.5679L4.18783 14.572C3.96883 14.6872 3.70345 14.668 3.50328 14.5226C3.30311 14.3771 3.20287 14.1307 3.2447 13.8868L3.97287 9.6419L0.884624 6.6358C0.707242 6.46313 0.643331 6.2047 0.719788 5.96926C0.796245 5.73383 0.999795 5.56225 1.24478 5.52675L5.50643 4.90912L7.41246 1.04703C7.52196 0.825158 7.74792 0.684692 7.99534 0.684692ZM7.99534 2.80342L6.52089 5.79102C6.42617 5.98295 6.24305 6.11594 6.03124 6.14664L2.73642 6.62415L5.12472 8.94892C5.27814 9.09826 5.34818 9.31357 5.31198 9.52459L4.74867 12.8084L7.69752 11.258C7.88708 11.1584 8.11357 11.1585 8.30303 11.2583L11.2417 12.8069L10.6787 9.52459C10.6425 9.31357 10.7125 9.09826 10.866 8.94892L13.2543 6.62415L9.95944 6.14664C9.74764 6.11594 9.56451 5.98295 9.46979 5.79102L7.99534 2.80342Z"
            fill="currentColor"
        />
    </svg>
)
