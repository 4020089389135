import { AgeGroupCode } from '~/clients/derived-api-types'
import { ChildIcon } from '~/icons'
import { selectGetAgeGroups } from '~/store/selectors'
import { useStore } from '~/store/store'

type Props = {
    ageGroupCode?: AgeGroupCode | null
}

export const AgeGroup = ({ ageGroupCode }: Props) => {
    const getAgeGroup = useStore(selectGetAgeGroups)
    if (ageGroupCode === 'child') {
        const ageGroup = getAgeGroup.byCode(ageGroupCode)
        return (
            <div className="py-0\\.5 flex items-center gap-1 rounded border px-2">
                <ChildIcon />
                {ageGroup?.display_name || 'Barn'}
            </div>
        )
    }
    return null
}
