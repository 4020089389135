import { ComponentProps, FC } from 'react'

export const RefreshIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.95833 2.83799C6.1074 2.17416 7.44285 1.90654 8.75898 2.07636C10.0751 2.24619 11.2989 2.84403 12.2418 3.77779C13.1847 4.71155 13.7945 5.92944 13.9772 7.24385C14.0266 7.59941 13.7784 7.92772 13.4228 7.97713C13.0673 8.02654 12.7389 7.77836 12.6895 7.42279C12.5461 6.39082 12.0674 5.43463 11.3271 4.70151C10.5868 3.96838 9.62594 3.49901 8.59261 3.36567C7.55929 3.23234 6.5108 3.44245 5.60863 3.96364C5.00562 4.31201 4.49035 4.78644 4.09528 5.34998H5.33335C5.69233 5.34998 5.98335 5.641 5.98335 5.99998C5.98335 6.35897 5.69233 6.64998 5.33335 6.64998H3.01455C3.00471 6.65021 2.99484 6.65021 2.98495 6.64998H2.66668C2.3077 6.64998 2.01668 6.35897 2.01668 5.99998V3.33332C2.01668 2.97433 2.3077 2.68332 2.66668 2.68332C3.02567 2.68332 3.31668 2.97433 3.31668 3.33332V4.22803C3.77349 3.67396 4.32831 3.20196 4.95833 2.83799ZM2.57721 8.02287C2.93278 7.97346 3.26108 8.22164 3.31049 8.57721C3.45391 9.60918 3.93265 10.5654 4.67296 11.2985C5.41327 12.0316 6.37409 12.501 7.40742 12.6343C8.44074 12.7677 9.48923 12.5575 10.3914 12.0364C10.9944 11.688 11.5097 11.2136 11.9048 10.65H10.6667C10.3077 10.65 10.0167 10.359 10.0167 10C10.0167 9.64103 10.3077 9.35002 10.6667 9.35002H12.9855C12.9953 9.34979 13.0052 9.34979 13.0151 9.35002H13.3333C13.6923 9.35002 13.9833 9.64103 13.9833 10V12.6667C13.9833 13.0257 13.6923 13.3167 13.3333 13.3167C12.9744 13.3167 12.6833 13.0257 12.6833 12.6667V11.772C12.2265 12.326 11.6717 12.798 11.0417 13.162C9.89263 13.8258 8.55718 14.0935 7.24105 13.9236C5.92493 13.7538 4.70115 13.156 3.75822 12.2222C2.81529 11.2884 2.20553 10.0706 2.02287 8.75616C1.97345 8.40059 2.22164 8.07229 2.57721 8.02287Z"
            fill="currentColor"
        />
    </svg>
)
