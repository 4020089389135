import dayjs from 'dayjs'

import { PlanningPeriod } from '~/store/selectors/entities'
import { getToday } from '~/utils/extendedDayjs'

export type ProgressStatus = 'completed' | 'current' | 'future'

export function getProgressStatus(planningPeriod: PlanningPeriod): ProgressStatus {
    const today = getToday()

    if (dayjs(planningPeriod.end_date).isBefore(today)) {
        return 'completed'
    }

    if (dayjs(planningPeriod.start_date).isAfter(today)) {
        return 'future'
    }

    return 'current'
}

export function getNumberOfDistinctWeeks(start_date: string, end_date: string): number {
    const start = dayjs(start_date).startOf('isoWeek')
    const end = dayjs(end_date).endOf('isoWeek')

    // Returns the number of distinct weeks for the duration
    return Math.ceil(dayjs.duration(end.diff(start)).asWeeks())
}

export function getDaysUntilPeriodStarts({ start_date }: PlanningPeriod): number {
    return dayjs(start_date).diff(getToday(), 'day')
}
