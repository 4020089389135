import dayjs from 'dayjs'
import { NorwegianId } from 'norwegian-national-id-validator'

import { DipsSchemas } from '~/clients/dips-client'
import { day, getToday } from '~/utils/extendedDayjs'
import { isNotNullish, isNullish } from '~/utils/guards'

type Patient = DipsSchemas['Patient']

export function getBirthDate(patient: Patient | undefined): dayjs.Dayjs | undefined {
    if (isNullish(patient)) {
        return undefined
    }
    if (isNotNullish(patient.birthDate)) {
        return day(patient.birthDate)
    }
    if (isNotNullish(patient.nationalId)) {
        const fromNorwegianId = NorwegianId(patient?.nationalId).birthDate()
        return isNotNullish(fromNorwegianId) ? day(fromNorwegianId) : undefined
    }
    return undefined
}

export function getAge(patient: Patient | undefined): number | undefined {
    const birthDate = getBirthDate(patient)
    if (isNotNullish(birthDate)) {
        return getToday().diff(birthDate, 'year')
    }
    return undefined
}

export const formatPersonalId = (personalId: string) => {
    return `${personalId.slice(0, 6)} ${personalId.slice(6)}`
}

export function formatPersonalIdWithLastName(patient?: Patient | undefined) {
    const formattedPersonalId = `${formatPersonalId(patient?.nationalId ?? '')}`
    const lastName = patient?.lastName ?? ''

    const values = [formattedPersonalId, lastName]

    if (values.every(value => value === '')) return '??'

    return values.filter(Boolean).join(', ')
}

export function formatPersonalIdWithFirstAndLastName(patient?: Patient | undefined) {
    const formattedPersonalId = `${formatPersonalId(patient?.nationalId ?? '')}`
    const firstName = patient?.firstName ?? ''
    const lastName = patient?.lastName ?? ''

    const values = [formattedPersonalId, firstName, lastName]

    if (values.every(value => value === '')) return '??'

    return values.filter(Boolean).join(', ')
}

export function getLastNameAndBirthYear(patient?: Patient | undefined) {
    const lastName = patient?.lastName ?? ''
    const firstName = patient?.firstName ?? ''
    const birthYear = getBirthDate(patient)?.year()

    const values = [lastName, firstName]

    if (values.every(value => value === '')) return '??'

    return `${lastName}, ${firstName?.charAt(0)} (f. ${birthYear})`
}

export function getFullNameAndMunicipality(patient?: Patient | undefined) {
    const fullName = `${patient?.firstName} ${patient?.lastName}`
    const municipality = patient?.municipality ?? ''

    const values = [fullName, municipality]

    if (values.every(value => value === '')) return '??'

    return values.filter(Boolean).join(', ')
}

export function getAgeAndPersonalId(patient?: Patient | undefined) {
    const age = getAge(patient)
    const formattedAge = age ? `${age} år` : ''
    const personalId = formatPersonalId(patient?.nationalId ?? '')

    return { age: formattedAge, personalId }
}
