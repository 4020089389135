import { createSelector } from 'reselect'

import { pages } from '~/app/routes'
import { NavItem } from '~/hero-ui/stories/HeroSideNav/HeroSideNavContent'

import { LandingPage } from '../slices/featureFlagSlice'
import { AllSlices } from '../store'

export const selectNavLinks = createSelector(
    (state: AllSlices) => state.app.activeViews,
    (state: AllSlices) => state.featureFlags,
    (activeViews, featureFlags) => {
        const navLinks: Record<LandingPage, NavItem> = {
            SURGEON_SCHEDULER: {
                isHidden: !featureFlags['SURGEON_SCHEDULER'],
                url: activeViews.SURGEON_SCHEDULER,
                title: pages.SURGEON_SCHEDULER.title,
                icon: pages.SURGEON_SCHEDULER.icon,
            },
            WAITING_LIST: {
                isHidden: !featureFlags['WAITING_LIST'],
                url: activeViews.WAITING_LIST,
                title: pages.WAITING_LIST.title,
                icon: pages.WAITING_LIST.icon,
            },
            OPERATIONAL_PLANNER: {
                isHidden: !featureFlags['OPERATIONAL_PLANNER'],
                url: activeViews.OPERATIONAL_PLANNER,
                title: pages.OPERATIONAL_PLANNER.title,
                icon: pages.OPERATIONAL_PLANNER.icon,
            },
            SURGERY_SCHEDULER: {
                url: activeViews.SURGERY_SCHEDULER,
                title: pages.SURGERY_SCHEDULER.title,
                icon: pages.SURGERY_SCHEDULER.icon,
                isHidden: !featureFlags.SURGERY_SCHEDULER,
                items: [
                    {
                        url: pages.SURGERY_SCHEDULER.views['surgery-scheduler'].path,
                        title: pages.SURGERY_SCHEDULER.views['surgery-scheduler'].title,
                        isHidden: !featureFlags.SURGERY_SCHEDULER,
                    },
                    {
                        url: pages.SURGERY_SCHEDULER.views['planning-periods'].path,
                        title: pages.SURGERY_SCHEDULER.views['planning-periods'].title,
                    },
                ],
            },
            ADMIN_PANEL: { url: activeViews.ADMIN_PANEL, title: pages.ADMIN_PANEL.title, icon: pages.ADMIN_PANEL.icon },
        }

        return navLinks
    }
)
