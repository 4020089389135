import { ComponentProps, FC } from 'react'

export const Loader2Icon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.30556 2.4707C5.39915 1.73999 6.68485 1.34998 8.0001 1.34998C8.35909 1.34998 8.6501 1.64099 8.6501 1.99998C8.6501 2.35896 8.35909 2.64998 8.0001 2.64998C6.94197 2.64998 5.9076 2.96375 5.0278 3.55161C4.148 4.13948 3.46227 4.97503 3.05735 5.95262C2.65242 6.9302 2.54647 8.00591 2.7529 9.04371C2.95933 10.0815 3.46887 11.0348 4.21708 11.783C4.96529 12.5312 5.91857 13.0407 6.95637 13.2472C7.99417 13.4536 9.06987 13.3477 10.0475 12.9427C11.025 12.5378 11.8606 11.8521 12.4485 10.9723C13.0363 10.0925 13.3501 9.05811 13.3501 7.99998C13.3501 7.64099 13.6411 7.34998 14.0001 7.34998C14.3591 7.34998 14.6501 7.64099 14.6501 7.99998C14.6501 9.31522 14.2601 10.6009 13.5294 11.6945C12.7987 12.7881 11.7601 13.6405 10.5449 14.1438C9.32982 14.6471 7.99272 14.7788 6.70275 14.5222C5.41278 14.2656 4.22786 13.6323 3.29784 12.7022C2.36782 11.7722 1.73447 10.5873 1.47788 9.29733C1.22129 8.00735 1.35298 6.67026 1.8563 5.45513C2.35962 4.24 3.21197 3.20141 4.30556 2.4707Z"
            fill="currentColor"
        />
    </svg>
)
