import { ComponentProps, FC } from 'react'

export const CalendarCheckIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33327 1.35001C5.69225 1.35001 5.98327 1.64102 5.98327 2.00001V2.68334H10.0166V2.00001C10.0166 1.64102 10.3076 1.35001 10.6666 1.35001C11.0256 1.35001 11.3166 1.64102 11.3166 2.00001V2.68334H11.9999C12.5259 2.68334 13.0304 2.8923 13.4024 3.26424C13.7743 3.63619 13.9833 4.14066 13.9833 4.66667V8.66667C13.9833 9.02566 13.6923 9.31667 13.3333 9.31667C12.9743 9.31667 12.6833 9.02566 12.6833 8.66667V7.98334H3.3166V12.6667C3.3166 12.8479 3.3886 13.0217 3.51675 13.1499C3.6449 13.278 3.8187 13.35 3.99993 13.35H7.6666C8.02559 13.35 8.3166 13.641 8.3166 14C8.3166 14.359 8.02559 14.65 7.6666 14.65H3.99993C3.47392 14.65 2.96945 14.441 2.59751 14.0691C2.22556 13.6972 2.0166 13.1927 2.0166 12.6667V4.66667C2.0166 4.14066 2.22556 3.63619 2.59751 3.26424C2.96945 2.8923 3.47392 2.68334 3.99993 2.68334H4.68327V2.00001C4.68327 1.64102 4.97428 1.35001 5.33327 1.35001ZM4.68327 3.98334H3.99993C3.8187 3.98334 3.64489 4.05533 3.51675 4.18348C3.3886 4.31163 3.3166 4.48544 3.3166 4.66667V6.68334H12.6833V4.66667C12.6833 4.48544 12.6113 4.31163 12.4831 4.18348C12.355 4.05533 12.1812 3.98334 11.9999 3.98334H11.3166V4.66667C11.3166 5.02566 11.0256 5.31667 10.6666 5.31667C10.3076 5.31667 10.0166 5.02566 10.0166 4.66667V3.98334H5.98327V4.66667C5.98327 5.02566 5.69225 5.31667 5.33327 5.31667C4.97428 5.31667 4.68327 5.02566 4.68327 4.66667V3.98334ZM14.4596 10.8737C14.7134 11.1276 14.7134 11.5391 14.4596 11.793L11.7929 14.4596C11.671 14.5815 11.5057 14.65 11.3333 14.65C11.1609 14.65 10.9955 14.5815 10.8736 14.4596L9.54032 13.1263C9.28647 12.8725 9.28647 12.4609 9.54032 12.2071C9.79416 11.9532 10.2057 11.9532 10.4596 12.2071L11.3333 13.0808L13.5403 10.8737C13.7942 10.6199 14.2057 10.6199 14.4596 10.8737Z"
            fill="currentColor"
        />
    </svg>
)
