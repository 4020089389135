import { ComponentProps, FC } from 'react'

export const LoaderQuarterIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.0001 1.34998C8.35908 1.34998 8.6501 1.64099 8.6501 1.99998V3.99998C8.6501 4.35896 8.35908 4.64998 8.0001 4.64998C7.64111 4.64998 7.3501 4.35896 7.3501 3.99998V1.99998C7.3501 1.64099 7.64111 1.34998 8.0001 1.34998ZM3.27382 3.27369C3.52766 3.01985 3.93921 3.01985 4.19305 3.27369L5.62639 4.70703C5.88023 4.96087 5.88023 5.37243 5.62639 5.62627C5.37255 5.88011 4.96099 5.88011 4.70715 5.62627L3.27382 4.19293C3.01997 3.93909 3.01997 3.52753 3.27382 3.27369ZM1.3501 7.99998C1.3501 7.64099 1.64111 7.34998 2.0001 7.34998H4.0001C4.35908 7.34998 4.6501 7.64099 4.6501 7.99998C4.6501 8.35896 4.35908 8.64998 4.0001 8.64998H2.0001C1.64111 8.64998 1.3501 8.35896 1.3501 7.99998Z"
            fill="currentColor"
        />
    </svg>
)
