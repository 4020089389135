import dayjs from 'dayjs'
import { useState } from 'react'

import { Spinner } from '~/components'
import { importSurgeryMetadata } from '~/store/di-entity.api'
import { ScheduledSurgery, selectGetLocations, selectGetOccupancies, selectGetScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { day, isBefore } from '~/utils/extendedDayjs'

import { BookingDialog } from './BookingDialog/BookingDialog'
import { EmptyItem } from './EmptyItem'
import { SurgeryRow } from './SurgeryRow'

export const BookingDrawerBookings = () => {
    const { isLoading } = useImportEntities(() => [importSurgeryMetadata({})], [])
    const [selectedSurgery, setSelectedSurgery] = useState<ScheduledSurgery | null>(null)
    const { locationId, unixDate } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const getScheduledSurgeries = useStore(selectGetScheduledSurgeries)
    const getOccupancies = useStore(selectGetOccupancies)
    const getLocations = useStore(selectGetLocations)
    const location = locationId !== undefined ? getLocations.byId(locationId) : null
    const date = dayjs.unix(unixDate ?? 0)

    if (!unixDate) return null
    if (!location) return null

    const occupancyData = getOccupancies.byDateAndLocation(date, location)
    const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code).toSorted((surgery1, surgery2) => {
        const timeA = surgery1.contact?.checkInTime
        const timeB = surgery2.contact?.checkInTime

        // Sort nullish last
        if (!timeA && !timeB) return 0
        if (!timeA) return 1
        if (!timeB) return -1

        return isBefore(day(timeA), day(timeB)) ? -1 : 1
    })

    if (isLoading) return <Spinner />

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <span className="text-base">Operasjoner på programmet</span>
                <span className="rounded-lg bg-gray-100 px-2">{bookedSurgeries.length}</span>
            </div>
            <div className="flex flex-col gap-2">
                {bookedSurgeries.length > 0 ? (
                    <div className="border-t border-gray-200">
                        {bookedSurgeries.map((surgery, index) => (
                            <div
                                key={index}
                                className="cursor-pointer border-b border-gray-200 py-1 hover:bg-gray-50"
                                onClick={() => setSelectedSurgery(surgery)}
                            >
                                <SurgeryRow surgery={surgery} />
                            </div>
                        ))}

                        {selectedSurgery && (
                            <BookingDialog
                                key={selectedSurgery.id}
                                isOpen={selectedSurgery !== null}
                                onCloseDialog={() => setSelectedSurgery(null)}
                                surgery={selectedSurgery}
                                occupancyData={occupancyData}
                            />
                        )}
                    </div>
                ) : (
                    <EmptyItem>
                        <p>Det er ingen pasienter på programmet.</p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-loaded" className="hidden" />
            </div>
        </div>
    )
}
