import env from '~/env'
import { GetResolvedSurgeryTypeGroup } from '~/store/selectors'
import { DepartmentKey, getDayOvernight, isDayOvernightLabel } from '~/store/slices/filterSlice'
import { getSurgeonsAndAssistants, getUniquePractitioners } from '~/utils/dips'
import { day, diff, format, getToday } from '~/utils/extendedDayjs'
import { isNotNullish, isNullish } from '~/utils/guards'

import { isUnScheduledSurgery, WaitingListItem } from './columns'
import { FormattedRemark } from './Remarks'
import { FormattedStatus } from './Statuses'

export const nullishDiagnosisGroupName = 'Uspesifisert'

export function shouldShowASAColumn(departmentId: DepartmentKey) {
    const envVar = String(env.VITE_WAITINGLIST_HIDE_ASA_FOR_DEPARTMENT_IDS).split(',')
    return !envVar.includes(String(departmentId))
}

export function getSurgeryCategoryName(surgery: WaitingListItem, getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup) {
    const surgeryTypeId = surgery.surgeryType?.id

    if (!surgeryTypeId) return

    const surgeryTypeGroups = getSurgeryTypeGroup.byHospitalId(surgeryTypeId) ?? []
    const surgeryCategories = surgeryTypeGroups.filter(surgeryTypeGroup => surgeryTypeGroup?.group_type === 'category')

    // Some dips surgery types are very generic and are used across departments/sections (i.e. "Andre operasjoner/inngrep")
    // In these cases, there are multiple surgery categories per dips surgery type and we need to find the correct one based on the section and speciality relationship
    if (surgeryCategories.length > 1) {
        const surgeryCategory = surgeryCategories.filter(surgeryCategory =>
            surgeryCategory.specialities.some(speciality => speciality.id === surgery.section?.speciality?.id)
        )

        if (surgeryCategory.length > 1) {
            console.warn('Surgery type has multiple surgery categories', surgery, surgeryCategory)
        }

        return surgeryCategory.at(0)?.display_name
    }

    return surgeryCategories.at(0)?.display_name
}

export function formatRemarks(item: WaitingListItem) {
    const formattedRemarks: FormattedRemark[] = []

    const dayOvernightLabel = getDayOvernight(item.contact?.levelOfCareNpr?.nprCodeName)?.label ?? ''
    if (!isDayOvernightLabel(dayOvernightLabel)) formattedRemarks.push('ugyldig dag/døgn')

    if (!isUnScheduledSurgery(item) && isNotNullish(item.letterStatus) && item.letterStatus !== 2) formattedRemarks.push('brev ikke sendt')

    if (item.surgeryMetadata?.patient_short_notice) formattedRemarks.push('kort varsel')

    if (item.surgeryMetadata?.patient_prioritized) formattedRemarks.push('prioritert')

    return formattedRemarks
}

export function formatStatuses(item: WaitingListItem) {
    const formattedStatuses: FormattedStatus[] = []

    if (isUnScheduledSurgery(item) && isNullish(item.surgeryMetadata?.patient_ready)) formattedStatuses.push('ny')
    else if (item.surgeryMetadata?.patient_ready) formattedStatuses.push('klarert')

    if (item.surgeryMetadata?.patient_confirmed) formattedStatuses.push('bekreftet')

    return formattedStatuses
}

export function formatSurgeonsAndAssistants(item: WaitingListItem) {
    const practitioners = getSurgeonsAndAssistants(item.surgeryResources)
    const uniquePractitioners = getUniquePractitioners(practitioners)

    return uniquePractitioners.map(({ short_name }) => short_name)
}

export function formatFollowUpDate(item: WaitingListItem) {
    const today = getToday()
    const _followUpDate = item.surgeryMetadata?.follow_up_date

    if (isNullish(_followUpDate)) return ''

    const followUpDate = day(_followUpDate)

    if (followUpDate.isSameOrBefore(today, 'day')) {
        const diffDays = diff(today, followUpDate, 'day')

        if (diffDays === 0) return `I dag`
        if (diffDays === 1) return `+ 1 dag`
        return `+ ${diffDays} dager`
    }

    return format(followUpDate, 'DD.MM.YYYY')
}
