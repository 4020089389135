import { ComponentProps, FC } from 'react'

export const MagicIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.1501 0.999976C6.1501 0.64099 5.85908 0.349976 5.5001 0.349976C5.14111 0.349976 4.8501 0.64099 4.8501 0.999976C4.8501 2.02106 4.44447 3.00032 3.72246 3.72234C3.00044 4.44435 2.02118 4.84998 1.0001 4.84998C0.641113 4.84998 0.350098 5.14099 0.350098 5.49998C0.350098 5.85896 0.641113 6.14998 1.0001 6.14998C2.02118 6.14998 3.00044 6.5556 3.72246 7.27761C4.44447 7.99963 4.8501 8.97889 4.8501 9.99998C4.8501 10.359 5.14111 10.65 5.5001 10.65C5.85908 10.65 6.1501 10.359 6.1501 9.99998C6.1501 8.97889 6.55572 7.99963 7.27774 7.27761C7.99975 6.5556 8.97901 6.14998 10.0001 6.14998C10.3591 6.14998 10.6501 5.85896 10.6501 5.49998C10.6501 5.14099 10.3591 4.84998 10.0001 4.84998C8.97901 4.84998 7.99975 4.44435 7.27774 3.72234C6.55572 3.00032 6.1501 2.02106 6.1501 0.999976Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3151 9.99998C13.3151 9.64099 13.0241 9.34998 12.6651 9.34998C12.3061 9.34998 12.0151 9.64099 12.0151 9.99998C12.0151 10.5344 11.8028 11.0469 11.4249 11.4248C11.047 11.8027 10.5345 12.015 10.0001 12.015C9.64111 12.015 9.3501 12.306 9.3501 12.665C9.3501 13.024 9.64111 13.315 10.0001 13.315C10.5345 13.315 11.047 13.5273 11.4249 13.9052C11.8028 14.283 12.0151 14.7956 12.0151 15.33C12.0151 15.689 12.3061 15.98 12.6651 15.98C13.0241 15.98 13.3151 15.689 13.3151 15.33C13.3151 14.7956 13.5274 14.283 13.9053 13.9052C14.2832 13.5273 14.7957 13.315 15.3301 13.315C15.6891 13.315 15.9801 13.024 15.9801 12.665C15.9801 12.306 15.6891 12.015 15.3301 12.015C14.7957 12.015 14.2832 11.8027 13.9053 11.4248C13.5274 11.0469 13.3151 10.5344 13.3151 9.99998Z"
            fill="currentColor"
        />
    </svg>
)
