import clsx from 'clsx'
import { memo } from 'react'

import { UserCircleIcon } from '~/icons'

import { Tag, TagProps } from './Tag'

type Props = {
    shortName: string
    fullName?: string
    prefix?: string
    color?: TagProps['color']
    size?: TagProps['size']
}

export const PractitionerTag = memo(({ shortName, fullName, prefix, color = 'practitioner', size = 'xs' }: Props) => (
    <Tag color={color} size={size} textSize="xs" gap={0.5} data-tooltip={fullName ?? shortName}>
        <UserCircleIcon
            className={clsx('h-4 w-4', {
                'text-black': color === 'practitioner',
            })}
        />
        {prefix ? `${prefix}:` : ''} {shortName}
    </Tag>
))
