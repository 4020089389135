import { ComponentProps, FC } from 'react'

export const Loader3Icon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.3501 1.99998C7.3501 1.64099 7.64111 1.34998 8.0001 1.34998C9.76379 1.34998 11.4552 2.0506 12.7024 3.29772C13.9495 4.54483 14.6501 6.23629 14.6501 7.99998C14.6501 9.76367 13.9495 11.4551 12.7024 12.7022C11.4552 13.9494 9.76379 14.65 8.0001 14.65C6.23641 14.65 4.54495 13.9494 3.29784 12.7022C2.05072 11.4551 1.3501 9.76367 1.3501 7.99998C1.3501 7.64099 1.64111 7.34998 2.0001 7.34998C2.35908 7.34998 2.6501 7.64099 2.6501 7.99998C2.6501 9.41888 3.21376 10.7797 4.21708 11.783C5.2204 12.7863 6.58119 13.35 8.0001 13.35C9.41901 13.35 10.7798 12.7863 11.7831 11.783C12.7864 10.7797 13.3501 9.41888 13.3501 7.99998C13.3501 6.58107 12.7864 5.22027 11.7831 4.21695C10.7798 3.21363 9.41901 2.64998 8.0001 2.64998C7.64111 2.64998 7.3501 2.35896 7.3501 1.99998ZM7.22299 4.09318C7.99568 3.93948 8.7966 4.01837 9.52446 4.31986C10.2523 4.62135 10.8744 5.1319 11.3121 5.78696C11.7498 6.44201 11.9834 7.21215 11.9834 7.99998C11.9834 8.35896 11.6924 8.64998 11.3334 8.64998C10.9744 8.64998 10.6834 8.35896 10.6834 7.99998C10.6834 7.46926 10.5261 6.95047 10.2312 6.5092C9.93636 6.06792 9.51728 5.724 9.02697 5.5209C8.53665 5.31781 7.99712 5.26467 7.47661 5.3682C6.95609 5.47174 6.47797 5.7273 6.1027 6.10257C5.72743 6.47785 5.47186 6.95597 5.36833 7.47649C5.26479 7.997 5.31793 8.53653 5.52102 9.02684C5.72412 9.51716 6.06805 9.93624 6.50932 10.2311C6.95059 10.5259 7.46939 10.6833 8.0001 10.6833C8.35908 10.6833 8.6501 10.9743 8.6501 11.3333C8.6501 11.6923 8.35908 11.9833 8.0001 11.9833C7.21227 11.9833 6.44213 11.7497 5.78708 11.312C5.13202 10.8743 4.62147 10.2522 4.31998 9.52433C4.01849 8.79647 3.93961 7.99556 4.0933 7.22287C4.247 6.45018 4.62638 5.74041 5.18346 5.18334C5.74054 4.62626 6.4503 4.24688 7.22299 4.09318Z"
            fill="currentColor"
        />
    </svg>
)
