import stringify from 'fast-safe-stringify'
import { useEffect, useMemo, useRef, useState } from 'react'

import { withImport } from './di-entity.api'
import { dispatchErrorToast } from './slices/toasterSlice'

type ImportEntity = ReturnType<ReturnType<typeof withImport>>

function getDependencyKey(dependencies: unknown[]) {
    return JSON.stringify(dependencies)
}

const isErrorResult = (result: unknown): result is { error: unknown } => {
    return Boolean(result && typeof result === 'object' && 'error' in result)
}

function dispatchError() {
    dispatchErrorToast('Det oppstod en feil. Vennligst prøv igjen senere.')
}

export type ImportEntitiesOptions = {
    skip?: boolean
    refetchInterval?: number
}

/**
 * Imports one or more entities. This includes fetching the data and updating the store.
 * @param promisesCallback The list of import functions.
 * @param dependencies Any variables that the import function(s) depend on. E.g. date range. Should be serializable.
 * @param options Object with skip: skip the import, refetchInterval: interval in ms to refetch
 * @returns Object with isLoading: initial fetch, isFetching: every fetch, isError, and isSuccess
 */
export function useImportEntities(promisesCallback: () => ImportEntity[], dependencies: unknown[], options?: ImportEntitiesOptions) {
    const [isLoading, setIsLoading] = useState(options?.skip ? false : true)
    const [isFetching, setIsFetching] = useState(options?.skip ? false : true)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    // Bookkeeping for the first fetch (per dependency key)
    const firstFetches = useRef<Record<string, boolean>>({})
    const dependencyKey = useMemo(() => getDependencyKey(dependencies), [dependencies])

    const refetchIntervalId = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (options?.skip) {
            setIsLoading(false)
            setIsFetching(false)
            setIsError(false)
            setIsSuccess(false)
            return
        }

        async function mainFetch() {
            const isFirstFetch = firstFetches.current[dependencyKey] ?? true

            if (isFirstFetch) {
                setIsLoading(true)
            }
            setIsFetching(true)
            setIsError(false)
            setIsSuccess(false)

            try {
                const results = await Promise.all(promisesCallback())

                const errors = results
                    .filter(isErrorResult)
                    .map(result => result.error)
                    .filter(Boolean)
                if (errors.length) {
                    setIsError(true)
                    console.error(`${errors.length} errors: ${stringify(errors)}`)
                    dispatchError()
                } else {
                    setIsSuccess(true)
                }
            } catch (err) {
                setIsError(true)
                console.error(err)
                dispatchError()
            } finally {
                setIsLoading(false)
                setIsFetching(false)
                firstFetches.current[dependencyKey] = false
            }
        }

        const deferTimeoutId = setTimeout(mainFetch, 1)

        if (options?.refetchInterval) {
            refetchIntervalId.current = setInterval(mainFetch, options.refetchInterval)
        }

        return () => {
            clearTimeout(deferTimeoutId)

            if (refetchIntervalId.current) {
                clearInterval(refetchIntervalId.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, options?.skip])

    return { isFetching, isError, isLoading, isSuccess }
}
