export const views = ['gather_step', 'delete_model_output', 'apply_model_output'] as const
export type View = (typeof views)[number]
export type ViewOptions = { label: string; value: View }[]
export const viewOptions: ViewOptions = [
    {
        label: 'Gather step',
        value: 'gather_step',
    },
    {
        label: 'Delete model output',
        value: 'delete_model_output',
    },
    {
        label: 'Apply model output',
        value: 'apply_model_output',
    },
]
