import { ComponentProps, FC } from 'react'

export const SendIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4597 1.54037C14.6379 1.71858 14.6971 1.98373 14.6115 2.22076L10.2782 14.2208C10.2721 14.2377 10.2652 14.2544 10.2577 14.2708C10.1766 14.4478 10.0464 14.5977 9.88257 14.7029C9.71874 14.808 9.52816 14.8639 9.33348 14.8639C9.1388 14.8639 8.94822 14.808 8.78438 14.7029C8.62442 14.6002 8.49651 14.4548 8.41506 14.2833L6.18229 9.81781L1.71687 7.58508C1.54533 7.50364 1.39993 7.37573 1.29727 7.21576C1.19212 7.05192 1.13623 6.86133 1.13623 6.66666C1.13623 6.47199 1.19212 6.2814 1.29727 6.11757C1.40241 5.95373 1.55238 5.82352 1.72936 5.74242C1.74574 5.73491 1.76243 5.72809 1.77937 5.72197L13.7793 1.38863C14.0164 1.30304 14.2815 1.36217 14.4597 1.54037ZM7.45766 9.46167L9.29072 13.1277L12.3989 4.52048L7.45766 9.46167ZM11.4796 3.60126L2.87243 6.70942L6.53842 8.54243L11.4796 3.60126Z"
            fill="currentColor"
        />
    </svg>
)
