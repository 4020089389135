import { useStore } from '~/store/store'

import { useColumnsToShow } from '../../hooks/useColumnsToShow'
import { WaitingListColumn } from '../../shared/columns'
import { TableHeaderCell } from './TableHeaderCell'

export const TableHeaderRow = () => {
    const columnSortedOn = useStore(state => state.waitingList.columnSortedOn)
    const sortOrder = useStore(state => state.waitingList.sortOrder)
    const { setColumnSortedOn, toggleSortOrder } = useStore(state => state.waitingList.actions)

    const columnsToShow = useColumnsToShow()

    function onClickTableHeaderCell(column: WaitingListColumn) {
        return () => {
            if (column === columnSortedOn) {
                toggleSortOrder()
            } else {
                setColumnSortedOn(column)
            }
        }
    }

    return (
        <tr>
            {columnsToShow.map(({ column, label }) => (
                <TableHeaderCell
                    label={
                        <div>
                            {label} {column === columnSortedOn ? (sortOrder === 'desc' ? ' ▼' : ' ▲') : ''}
                        </div>
                    }
                    key={column}
                    onClick={onClickTableHeaderCell(column)}
                />
            ))}
        </tr>
    )
}
