import clsx from 'clsx'

import { PractitionerSchedule } from '~/store/selectors'
import { selectStatusValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'

type Props = {
    schedule: PractitionerSchedule | null
}

export const CellScheduleLocations = ({ schedule }: Props) => {
    const locationSchedules = schedule?.locationAssignments ?? []
    const filteredStatuses = useStore(selectStatusValues)

    return (
        <div
            className={clsx('flex items-stretch -space-x-5 xl:-space-x-4 2xl:-space-x-2', {
                '-space-x-5': locationSchedules.length > 3,
            })}
        >
            {locationSchedules
                .filter(Boolean)
                .reverse()
                .map(cellLocation => {
                    const isPractitionerAndLocationSameDepartment = cellLocation.schedule?.practitioner?.departments.some(department =>
                        cellLocation.location?.departments.some(locationDepartment => department.id === locationDepartment.id)
                    )

                    const diffDepartmentTooltip = isPractitionerAndLocationSameDepartment ? '' : '\n Kirurgen og rommet er fra forskjellige avdelinger'
                    const tooltip = `Sist endret av ${cellLocation.updated_by} ${humanizeDate(day(cellLocation.updated_at))} ${diffDepartmentTooltip}`

                    return (
                        <ButtonTag
                            key={cellLocation.location?.id}
                            tabIndex={-1}
                            data-tooltip={tooltip}
                            colorType={isPractitionerAndLocationSameDepartment ? 'room' : 'room_conflict'}
                            whiteText={!isPractitionerAndLocationSameDepartment}
                            fillType="all"
                            className={clsx('!min-w-[20px] truncate !border-white', {
                                'opacity-30': filteredStatuses.length > 0,
                            })}
                        >
                            {cellLocation.location?.room_code}
                        </ButtonTag>
                    )
                })}
        </div>
    )
}
