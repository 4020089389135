import * as Sentry from '@sentry/browser'
import { useEffect, useRef, useState } from 'react'

import env from '~/env'
import { importSurgeryMetadata } from '~/store/di-entity.api'
import { importFullScheduledSurgeries, importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { dispatchErrorToast } from '~/store/slices/toasterSlice'
import { ImportEntitiesOptions, useImportEntities } from '~/store/useImportEntities'
import { format, getToday } from '~/utils/extendedDayjs'

export function useImportUnScheduledEntities(options?: ImportEntitiesOptions) {
    return useImportEntities(() => [importUnScheduledSurgeries(), importSurgeryMetadata({})], [], options)
}

const NUM_PAGES = 6
const DAYS_PER_PAGE = 30

function useImportFutureFullScheduledSurgeries(options?: ImportEntitiesOptions) {
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)

    const isFirstFetch = useRef(true)

    useEffect(() => {
        if (options?.skip) {
            setIsLoading(false)
            setIsFetching(false)
            return
        }

        async function mainFetch() {
            if (isFirstFetch.current) {
                setIsLoading(true)
            }
            setIsFetching(true)

            const importPromises: Promise<unknown>[] = []
            const today = getToday()

            for (let i = 0; i < NUM_PAGES; i++) {
                const startTime = format(today.add(i * DAYS_PER_PAGE, 'days'), 'YYYY-MM-DD')
                const endTime = format(today.add((i + 1) * DAYS_PER_PAGE, 'days'), 'YYYY-MM-DD')
                const treatmentLocationId = Number(env.VITE_DIPS_TREATMENT_LOCATION_ID)

                importPromises.push(importFullScheduledSurgeries(startTime, endTime, treatmentLocationId))
            }

            try {
                await Promise.all(importPromises)
            } catch (error) {
                console.error(error)
                dispatchErrorToast('Det oppstod en feil. Vennligst prøv igjen.')
            } finally {
                setIsLoading(false)
                setIsFetching(false)
                isFirstFetch.current = false
            }
        }

        void Sentry.startSpan({ name: 'importFutureFullScheduledSurgeries' }, mainFetch)
    }, [options?.skip])

    return {
        isLoading,
        isFetching,
    }
}

export function useImportFutureScheduledEntities(options?: ImportEntitiesOptions) {
    const { isLoading: futureScheduledIsLoading } = useImportFutureFullScheduledSurgeries(options)
    const { isLoading: surgeryMetadataIsLoading } = useImportEntities(() => [importSurgeryMetadata({})], [], options)

    return {
        isLoading: futureScheduledIsLoading || surgeryMetadataIsLoading,
    }
}
