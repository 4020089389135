import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { optimusBaseClient } from '~/clients/optimus-client'
import { Button, DISelect, MonthPicker } from '~/components'
import { selectEntities } from '~/store/selectors'
import { useStore } from '~/store/store'

export const GatherStep = () => {
    const { departments: departmentsList } = useStore(selectEntities)
    const departmentsListOptions = departmentsList.map(dep => ({ label: dep.name, value: dep.id }))

    const [date, setDate] = useState<Dayjs>()
    const [data, setData] = useState<unknown>()
    const [depId, setDepId] = useState<number>()

    async function generateLink() {
        if (!date || !depId) return

        const { data: gatherStep } = await optimusBaseClient.GET('/staffing-plan/gather/{year}/{month}/{department_id}', {
            params: {
                path: {
                    year: date.year(),
                    month: date.month() + 1,
                    department_id: depId,
                },
            },
        })

        setData(gatherStep)
    }

    return (
        <div className="flex flex-col gap-y-2 p-4">
            <h2 className="mb-2 text-2xl">Gather step</h2>
            <div className="flex w-64 flex-col gap-y-2">
                <MonthPicker value={date} onChange={setDate} />
                <DISelect
                    isClearable={false}
                    value={departmentsListOptions.find(option => option.value === depId)}
                    options={departmentsListOptions}
                    onChange={val => {
                        if (!val) return
                        setDepId(val.value)
                    }}
                />
                <Button disabled={!date || !depId} onClick={generateLink}>
                    Gather
                </Button>
            </div>
            <div className="text-xs">{JSON.stringify(data)}</div>
        </div>
    )
}
