import { Slot } from '@radix-ui/react-slot'
import { ComponentProps, ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { ChevronRightIcon } from '~/icons'
import { Dots } from '~/icons/Dots'

const Breadcrumb = forwardRef<
    HTMLElement,
    ComponentPropsWithoutRef<'nav'> & {
        separator?: ReactNode
    }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />)
Breadcrumb.displayName = 'Breadcrumb'

const BreadcrumbList = forwardRef<HTMLOListElement, ComponentPropsWithoutRef<'ol'>>(({ className, ...props }, ref) => (
    <ol ref={ref} className={twMerge('text-muted-foreground flex flex-wrap items-center gap-1.5 break-words text-sm sm:gap-2.5', className)} {...props} />
))
BreadcrumbList.displayName = 'BreadcrumbList'

const BreadcrumbItem = forwardRef<HTMLLIElement, ComponentPropsWithoutRef<'li'>>(({ className, ...props }, ref) => (
    <li ref={ref} className={twMerge('inline-flex items-center gap-1.5', className)} {...props} />
))
BreadcrumbItem.displayName = 'BreadcrumbItem'

const BreadcrumbLink = forwardRef<
    HTMLAnchorElement,
    ComponentPropsWithoutRef<'a'> & {
        asChild?: boolean
    }
>(({ asChild, className, ...props }, ref) => {
    const Comp = asChild ? Slot : 'a'

    return <Comp ref={ref} className={twMerge('hover:text-foreground transition-colors', className)} {...props} />
})
BreadcrumbLink.displayName = 'BreadcrumbLink'

const BreadcrumbPage = forwardRef<HTMLSpanElement, ComponentPropsWithoutRef<'span'>>(({ className, ...props }, ref) => (
    <span ref={ref} role="link" aria-disabled="true" aria-current="page" className={twMerge('text-foreground font-normal', className)} {...props} />
))
BreadcrumbPage.displayName = 'BreadcrumbPage'

const BreadcrumbSeparator = ({ children, className, ...props }: ComponentProps<'div'>) => (
    <div role="presentation" aria-hidden="true" className={twMerge('[&>svg]:h-3.5 [&>svg]:w-3.5', className)} {...props}>
        {children ?? <ChevronRightIcon />}
    </div>
)
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator'

const BreadcrumbEllipsis = ({ className, ...props }: ComponentProps<'span'>) => (
    <span role="presentation" aria-hidden="true" className={twMerge('flex h-9 w-9 items-center justify-center', className)} {...props}>
        <Dots className="h-4 w-4" />
        <span className="sr-only">More</span>
    </span>
)
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis'

export type BreadcrumbOption = {
    /*
     * The label to display for the breadcrumb item.
     */
    label: string
    /*
     * The URL to navigate to when the breadcrumb item is clicked.
     */
    href?: string
    /*
     * Indicates if the breadcrumb item is an ellipsis.
     */
    isEllipsis?: boolean
}

export type HeroBreadcrumbsProps = {
    /**
     * An array of breadcrumb options to display.
     */
    options: BreadcrumbOption[]
}
export const HeroBreadcrumbs = ({ options }: HeroBreadcrumbsProps) => {
    return (
        <Breadcrumb>
            <BreadcrumbList>
                {options.map((option, index) =>
                    index === options.length - 1 ? (
                        <BreadcrumbPage key={index}>{option.label}</BreadcrumbPage>
                    ) : option.isEllipsis ? (
                        <>
                            <BreadcrumbEllipsis key={index} />
                            <BreadcrumbSeparator />
                        </>
                    ) : (
                        <BreadcrumbItem key={index}>
                            {option.href ? (
                                <BreadcrumbLink asChild>
                                    <Link to={option.href}>{option.label}</Link>
                                </BreadcrumbLink>
                            ) : (
                                <BreadcrumbPage key={index}>{option.label}</BreadcrumbPage>
                            )}
                            {index < options.length - 1 && <BreadcrumbSeparator />}
                        </BreadcrumbItem>
                    )
                )}
            </BreadcrumbList>
        </Breadcrumb>
    )
}
