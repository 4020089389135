import { ComponentProps, FC } from 'react'

export const LogoutIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.931 2.59757C2.30295 2.22562 2.80742 2.01666 3.33343 2.01666H8.0001C8.52611 2.01666 9.03058 2.22562 9.40253 2.59757C9.77447 2.96951 9.98343 3.47398 9.98343 4V5.33333C9.98343 5.69231 9.69242 5.98333 9.33343 5.98333C8.97445 5.98333 8.68343 5.69231 8.68343 5.33333V4C8.68343 3.81876 8.61144 3.64496 8.48329 3.51681C8.35514 3.38866 8.18133 3.31666 8.0001 3.31666H3.33343C3.1522 3.31666 2.97839 3.38866 2.85024 3.51681C2.72209 3.64496 2.6501 3.81876 2.6501 4V12C2.6501 12.1812 2.72209 12.355 2.85024 12.4832C2.97839 12.6113 3.1522 12.6833 3.33343 12.6833H8.0001C8.18133 12.6833 8.35514 12.6113 8.48329 12.4832C8.61144 12.355 8.68343 12.1812 8.68343 12V10.6667C8.68343 10.3077 8.97445 10.0167 9.33343 10.0167C9.69242 10.0167 9.98343 10.3077 9.98343 10.6667V12C9.98343 12.526 9.77447 13.0305 9.40253 13.4024C9.03058 13.7744 8.52611 13.9833 8.0001 13.9833H3.33343C2.80742 13.9833 2.30295 13.7744 1.931 13.4024C1.55906 13.0305 1.3501 12.526 1.3501 12V4C1.3501 3.47398 1.55906 2.96951 1.931 2.59757ZM11.5405 5.54038C11.7943 5.28654 12.2059 5.28654 12.4597 5.54038L14.4597 7.54038C14.7136 7.79422 14.7136 8.20577 14.4597 8.45962L12.4597 10.4596C12.2059 10.7135 11.7943 10.7135 11.5405 10.4596C11.2866 10.2058 11.2866 9.79422 11.5405 9.54038L12.4309 8.65H6.0001C5.64111 8.65 5.3501 8.35898 5.3501 8C5.3501 7.64101 5.64111 7.35 6.0001 7.35H12.4309L11.5405 6.45962C11.2866 6.20577 11.2866 5.79422 11.5405 5.54038Z"
            fill="currentColor"
        />
    </svg>
)
