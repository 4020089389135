import { useEffect, useRef, useState } from 'react'

import { Button } from '~/components'
import { HeroPopover } from '~/hero-ui/stories/HeroPopover/HeroPopover'
import { selectLocationOptions, selectRuleDefinitionOptions } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isRuleDefinitionId, RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/blockEvaluation'

import { Header } from './MultipeCreate/Header'
import { BookedDay, MultipleDatePicker } from './MultipeCreate/MultipleDatePicker'
import { RuleEditor } from './MultipeCreate/RuleEditor'
import { useCreateMultipleBlockSchedules } from './MultipeCreate/useCreateMultipleBlockSchedules'

export const MultipleCreate = () => {
    const submitButtonRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)

    const closeModal = () => setOpen(false)

    const locationOptions = useStore(selectLocationOptions)
    const ruleDefinitionOptions = useStore(selectRuleDefinitionOptions)

    const [locationId, setLocationId] = useState<number | null>(null)
    const [ruleId, setRuleId] = useState<RuleDefinitionId>('max_count_of_surgeries_per_patient_group')
    const [parameters, setParameters] = useState<RuleInstanceParameters>(null)
    const [dates, setDates] = useState<BookedDay[]>([])

    const { createMultipleBlockSchedules, isLoading } = useCreateMultipleBlockSchedules()

    useEffect(() => {
        if (locationOptions.length && !locationId) setLocationId(locationOptions[0]?.value ?? null)
    }, [locationOptions, locationId])

    useEffect(() => {
        if (!open) {
            setRuleId('max_count_of_surgeries_per_patient_group')
            setParameters(null)
            setDates([])
        }
    }, [open])

    function onSchemaChange(val: string | null) {
        if (!isRuleDefinitionId(val)) return
        setRuleId(val)
        setParameters(null)
    }

    function handleSubmit() {
        void createMultipleBlockSchedules(ruleId, parameters, dates, locationId, closeModal)
    }

    return (
        <HeroPopover
            noPadding
            side="bottom"
            popoverContent={
                <div className="flex w-[400px] flex-col gap-y-2 p-2">
                    <Header
                        locationId={locationId}
                        setLocationId={setLocationId}
                        locationOptions={locationOptions}
                        ruleId={ruleId}
                        onSchemaChange={onSchemaChange}
                        ruleDefinitionOptions={ruleDefinitionOptions}
                    />

                    <RuleEditor
                        ruleId={ruleId}
                        parameters={parameters}
                        setParameters={setParameters}
                        submitButtonRef={submitButtonRef}
                        onSubmit={handleSubmit}
                    />

                    {locationId && <MultipleDatePicker locationId={locationId} dates={dates} setDates={setDates} />}

                    <div className="flex justify-end gap-x-2">
                        <Button disabled={isLoading} onClick={() => submitButtonRef.current?.click()} type="submit">
                            Lagre
                        </Button>
                    </div>
                </div>
            }
        >
            <Button>Legg til flere</Button>
        </HeroPopover>
    )
}
