import { ComponentProps, FC } from 'react'

export const FilterIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.0166 2.66666C2.0166 2.30768 2.30762 2.01666 2.6666 2.01666H13.3333C13.6923 2.01666 13.9833 2.30768 13.9833 2.66666V4.11466C13.9832 4.6406 13.7741 5.1451 13.4022 5.51695C13.4022 5.51697 13.4022 5.51693 13.4022 5.51695L10.6499 8.26923V12.6667C10.6499 12.9464 10.4709 13.1948 10.2055 13.2833L6.20548 14.6166C6.00727 14.6827 5.78937 14.6495 5.61987 14.5273C5.45037 14.4051 5.34994 14.2089 5.34994 14V8.58462L2.53231 5.48523C2.20054 5.12023 2.01665 4.64465 2.0166 4.1514V2.66666ZM3.3166 3.31666V4.15126C3.3166 4.15128 3.3166 4.15124 3.3166 4.15126C3.31664 4.32116 3.37996 4.48502 3.49423 4.61076C3.49424 4.61077 3.49422 4.61074 3.49423 4.61076L6.4809 7.89609C6.58967 8.01574 6.64993 8.17163 6.64993 8.33333V13.0982L9.34994 12.1982V8C9.34994 7.8276 9.41842 7.66227 9.54032 7.54038L12.483 4.59771C12.6111 4.46962 12.6832 4.29583 12.6833 4.11466C12.6833 4.11462 12.6833 4.11471 12.6833 4.11466V3.31666H3.3166Z"
            fill="currentColor"
        />
    </svg>
)
