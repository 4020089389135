import { ComponentProps, FC } from 'react'

export const StickerNoteIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.99993 3.31666C3.8187 3.31666 3.64489 3.38866 3.51675 3.51681C3.3886 3.64496 3.3166 3.81876 3.3166 4V12C3.3166 12.1812 3.38859 12.355 3.51675 12.4832C3.6449 12.6113 3.8187 12.6833 3.99993 12.6833H8.0166V10C8.0166 9.47398 8.22556 8.96951 8.59751 8.59757C8.96945 8.22562 9.47392 8.01666 9.99994 8.01666H12.6833V4C12.6833 3.81877 12.6113 3.64496 12.4831 3.51681C12.355 3.38866 12.1812 3.31666 11.9999 3.31666H3.99993ZM12.4307 9.31666H9.99994C9.8187 9.31666 9.6449 9.38866 9.51675 9.51681C9.3886 9.64496 9.3166 9.81877 9.3166 10V12.4308L12.4307 9.31666ZM2.59751 2.59757C2.96945 2.22562 3.47392 2.01666 3.99993 2.01666H11.9999C12.5259 2.01666 13.0304 2.22562 13.4024 2.59757C13.7743 2.96951 13.9833 3.47398 13.9833 4V8.78133C13.9832 9.30727 13.7741 9.81176 13.4022 10.1836M13.4022 10.1836L10.1836 13.4022C9.81177 13.7741 9.30734 13.9832 8.78141 13.9833H3.99993C3.47392 13.9833 2.96945 13.7744 2.59751 13.4024C2.22556 13.0305 2.0166 12.526 2.0166 12V4C2.0166 3.47398 2.22556 2.96951 2.59751 2.59757"
            fill="currentColor"
        />
    </svg>
)
