import { ComponentProps, FC } from 'react'

export const SliceIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5405 2.20704C11.7943 1.9532 12.2059 1.9532 12.4597 2.20704L14.4597 4.20704C14.5816 4.32894 14.6501 4.49427 14.6501 4.66666C14.6501 4.83905 14.5816 5.00438 14.4597 5.12628L10.9194 8.66666L11.7931 9.54038C12.0398 9.78711 12.0477 10.1846 11.811 10.441C10.5686 11.7863 8.98008 12.764 7.21929 13.2671C5.45849 13.7702 3.59325 13.7793 1.82764 13.2934C1.6045 13.232 1.43084 13.0565 1.3717 12.8328C1.31255 12.609 1.37684 12.3707 1.54049 12.207L11.5405 2.20704ZM3.31309 12.2729C4.49695 12.433 5.70546 12.3476 6.86215 12.0171C8.19311 11.6369 9.41088 10.9448 10.4163 10.002L9.54049 9.12628C9.41859 9.00438 9.35011 8.83905 9.35011 8.66666C9.35011 8.49427 9.41859 8.32894 9.54049 8.20704L13.0809 4.66666L12.0001 3.5859L3.31309 12.2729Z"
            fill="currentColor"
        />
    </svg>
)
