import { ComponentProps, FC } from 'react'

export const PencilIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0296 2.65743C10.3372 2.53 10.667 2.46442 10.9999 2.46442C11.3329 2.46442 11.6626 2.53 11.9703 2.65743C12.2779 2.78486 12.5574 2.97163 12.7929 3.20708C13.0283 3.44254 13.2151 3.72206 13.3425 4.0297C13.47 4.33733 13.5356 4.66705 13.5356 5.00003C13.5356 5.33301 13.47 5.66274 13.3425 5.97037C13.2151 6.27801 13.0283 6.55753 12.7929 6.79299L5.79289 13.793C5.67099 13.9149 5.50566 13.9834 5.33327 13.9834H2.6666C2.30762 13.9834 2.0166 13.6924 2.0166 13.3334V10.6667C2.0166 10.4943 2.08508 10.329 2.20698 10.2071L8.53605 3.87801C8.53747 3.87657 8.53889 3.87514 8.54032 3.87371C8.54174 3.87228 8.54318 3.87086 8.54462 3.86945L9.20698 3.20708C9.44244 2.97163 9.72196 2.78486 10.0296 2.65743ZM8.99995 5.25259L3.3166 10.9359V12.6834H5.06403L10.7474 7.00002L8.99995 5.25259ZM11.6666 6.08078L9.91919 4.33335L10.1262 4.12632C10.241 4.01158 10.3772 3.92057 10.5271 3.85847C10.677 3.79638 10.8377 3.76442 10.9999 3.76442C11.1622 3.76442 11.3229 3.79638 11.4728 3.85847C11.6227 3.92057 11.7589 4.01158 11.8736 4.12632C11.9884 4.24106 12.0794 4.37727 12.1415 4.52718C12.2036 4.67709 12.2356 4.83777 12.2356 5.00003C12.2356 5.1623 12.2036 5.32297 12.1415 5.47288C12.0794 5.6228 11.9884 5.75901 11.8736 5.87375L11.6666 6.08078Z"
            fill="currentColor"
        />
    </svg>
)
