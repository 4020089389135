import { ComponentProps, FC } from 'react'

export const CloseIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.54048 3.54036C3.79432 3.28652 4.20588 3.28652 4.45972 3.54036L8.0001 7.08074L11.5405 3.54036C11.7943 3.28652 12.2059 3.28652 12.4597 3.54036C12.7136 3.7942 12.7136 4.20575 12.4597 4.45959L8.91934 7.99998L12.4597 11.5404C12.7136 11.7942 12.7136 12.2058 12.4597 12.4596C12.2059 12.7134 11.7943 12.7134 11.5405 12.4596L8.0001 8.91921L4.45972 12.4596C4.20588 12.7134 3.79432 12.7134 3.54048 12.4596C3.28664 12.2058 3.28664 11.7942 3.54048 11.5404L7.08086 7.99998L3.54048 4.45959C3.28664 4.20575 3.28664 3.7942 3.54048 3.54036Z"
            fill="currentColor"
        />
    </svg>
)
