import { ComponentProps, FC } from 'react'

export const AlertTriangleIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.05459 1.37644C7.34314 1.21388 7.66873 1.12848 7.99993 1.12848C8.33112 1.12848 8.65671 1.21388 8.94526 1.37644C9.23382 1.539 9.47558 1.77322 9.64719 2.05648L9.64892 2.05934L15.0584 11.0925C15.2263 11.3837 15.315 11.7138 15.3159 12.0499C15.3167 12.386 15.2296 12.7165 15.0631 13.0085C14.8967 13.3005 14.6567 13.5439 14.367 13.7144C14.0774 13.8849 13.7482 13.9767 13.4121 13.9806L13.4046 13.9807L2.58796 13.9806C2.25172 13.9768 1.92232 13.8851 1.63254 13.7145C1.34276 13.5439 1.10269 13.3004 0.936211 13.0083C0.76973 12.7161 0.682639 12.3855 0.683602 12.0492C0.684565 11.7129 0.773547 11.3828 0.941699 11.0916L0.946889 11.0826L6.34041 2.07759C6.34436 2.07049 6.34844 2.06345 6.35266 2.05648C6.52428 1.77322 6.76603 1.539 7.05459 1.37644ZM7.99993 2.42848C7.89228 2.42848 7.78645 2.45624 7.69267 2.50907C7.60311 2.55952 7.52742 2.63121 7.47221 2.71774C7.47025 2.72116 7.46825 2.72456 7.46622 2.72795L2.06536 11.7454C2.01208 11.8391 1.98391 11.945 1.9836 12.0529C1.98328 12.1622 2.01159 12.2697 2.0657 12.3646C2.11981 12.4596 2.19784 12.5387 2.29203 12.5942C2.38535 12.6491 2.49131 12.6789 2.59955 12.6806H13.4002C13.5083 12.6788 13.6142 12.6491 13.7075 12.5942C13.8016 12.5387 13.8796 12.4596 13.9337 12.3647C13.9878 12.2698 14.0161 12.1624 14.0159 12.0532C14.0156 11.9454 13.9875 11.8395 13.9344 11.7458L8.53533 2.7301C8.53509 2.72971 8.53485 2.72932 8.53461 2.72893C8.47888 2.63739 8.40057 2.56168 8.30719 2.50907C8.2134 2.45624 8.10757 2.42848 7.99993 2.42848ZM7.99994 5.35C8.35892 5.35 8.64994 5.64101 8.64994 6V8.66666C8.64994 9.02565 8.35892 9.31666 7.99994 9.31666C7.64095 9.31666 7.34994 9.02565 7.34994 8.66666V6C7.34994 5.64101 7.64095 5.35 7.99994 5.35ZM7.34994 10.6667C7.34994 10.3077 7.64095 10.0167 7.99994 10.0167H8.0066C8.36559 10.0167 8.6566 10.3077 8.6566 10.6667C8.6566 11.0256 8.36559 11.3167 8.0066 11.3167H7.99994C7.64095 11.3167 7.34994 11.0256 7.34994 10.6667Z"
            fill="currentColor"
        />
    </svg>
)
