import dayjs from 'dayjs'

import { selectGetLocations } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format } from '~/utils/extendedDayjs'

import { Comment } from './Comment'

type InfoTextProps = {
    label: string
    value: string
}

const InfoText = ({ label, value }: InfoTextProps) => (
    <div>
        {label}: <span className="font-semibold">{value}</span>
    </div>
)

export const BookingDrawerHeader = () => {
    const { locationId, unixDate, totalKnifeTime, totalRoomDuration, capacity } = useStore(state => state.operationalPlanner.drawerProps) ?? {}

    const getLocations = useStore(selectGetLocations)
    const location = locationId !== undefined ? getLocations.byId(locationId) : null
    const date = format(dayjs.unix(unixDate ?? 0).startOf('day'), 'DD.MM.YYYY')

    return (
        <header className="flex flex-col gap-3 bg-white">
            {location && (
                <p className="text-base font-semibold">
                    Stue {location.room_code} - {date}
                </p>
            )}
            <div className="flex flex-col gap-1">
                {totalKnifeTime && <InfoText label="Total knivtid" value={totalKnifeTime} />}
                {totalRoomDuration && <InfoText label="Total stuetid" value={totalRoomDuration} />}
                {capacity && <InfoText label="Kapasitet" value={capacity} />}
            </div>
            <Comment />
        </header>
    )
}
