import { ComponentProps, FC } from 'react'

export const MapPinIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99994 2.65016C7.07367 2.65016 6.16821 2.92483 5.39804 3.43943C4.62788 3.95403 4.0276 4.68545 3.67312 5.5412C3.31865 6.39696 3.22589 7.33861 3.40658 8.24708C3.58726 9.15552 4.03325 9.98998 4.68817 10.645L7.51733 13.4734C7.64545 13.6014 7.81916 13.6733 8.00027 13.6733C8.18138 13.6733 8.35509 13.6014 8.48322 13.4734L11.3116 10.645C11.9666 9.99004 12.4126 9.15554 12.5933 8.24708C12.774 7.33861 12.6812 6.39696 12.3268 5.5412C11.9723 4.68545 11.372 3.95403 10.6018 3.43943C9.83167 2.92483 8.9262 2.65016 7.99994 2.65016ZM4.67581 2.35851C5.65976 1.70107 6.81656 1.35016 7.99994 1.35016C9.18332 1.35016 10.3401 1.70107 11.3241 2.35851C12.308 3.01595 13.0749 3.9504 13.5278 5.0437C13.9807 6.13699 14.0992 7.34002 13.8683 8.50067C13.6375 9.66131 13.0677 10.7274 12.2309 11.5642L9.40222 14.3929C9.03033 14.7644 8.52594 14.9733 8.00027 14.9733C7.47461 14.9733 6.97044 14.7647 6.59855 14.3931L3.76904 11.5643C2.93229 10.7275 2.36239 9.66131 2.13155 8.50067C1.90071 7.34002 2.01921 6.13699 2.47209 5.0437C2.92496 3.9504 3.69186 3.01595 4.67581 2.35851Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 8.7C8.93888 8.7 9.7 7.93888 9.7 7C9.7 6.06112 8.93888 5.3 8 5.3C7.06112 5.3 6.3 6.06112 6.3 7C6.3 7.93888 7.06112 8.7 8 8.7ZM8 10C9.65685 10 11 8.65685 11 7C11 5.34315 9.65685 4 8 4C6.34315 4 5 5.34315 5 7C5 8.65685 6.34315 10 8 10Z"
            fill="currentColor"
        />
    </svg>
)
