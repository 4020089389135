import { MinimalPractitioner } from './dips'

/**
 * Gets whether any practition is in both specified lists.
 */
export function hasMatchingPractitioner(surgeryPractitioner: MinimalPractitioner[], practitionersForLocation: MinimalPractitioner[]) {
    return surgeryPractitioner.some(practitioner =>
        practitionersForLocation.map(p => p.short_name.toLocaleLowerCase()).includes(practitioner.short_name.toLocaleLowerCase())
    )
}
