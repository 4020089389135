import { ComponentProps, FC } from 'react'

export const EditIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1509 1.94978C11.5353 1.56568 12.0565 1.34991 12.5999 1.34991C13.1436 1.34991 13.6651 1.5659 14.0496 1.95037C14.434 2.33483 14.65 2.85627 14.65 3.39999C14.65 3.94362 14.4341 4.465 14.0497 4.84945C14.0497 4.8495 14.0498 4.8494 14.0497 4.84945L13.2061 5.69604C13.1831 5.73023 13.1565 5.76271 13.1262 5.79295C13.0965 5.82262 13.0647 5.84882 13.0312 5.87156L8.46037 10.4588C8.33841 10.5812 8.17273 10.65 7.99994 10.65H5.99994C5.64095 10.65 5.34994 10.359 5.34994 9.99999V7.99999C5.34994 7.82719 5.41874 7.66151 5.54114 7.53955L10.1284 2.96862C10.1511 2.93517 10.1773 2.90336 10.207 2.87371C10.2372 2.84349 10.2697 2.81686 10.3038 2.79383L11.1509 1.94978C11.1511 1.94958 11.1507 1.94997 11.1509 1.94978ZM10.6741 4.26008L6.64993 8.26991V9.34999H7.73002L11.7398 5.32581L10.6741 4.26008ZM12.6574 4.40493L11.595 3.34248L12.0687 2.87043L12.0696 2.86961C12.2102 2.72894 12.401 2.64991 12.5999 2.64991C12.7989 2.64991 12.9896 2.72894 13.1303 2.86961C13.271 3.01027 13.35 3.20105 13.35 3.39999C13.35 3.59892 13.271 3.7897 13.1303 3.93037L12.6574 4.40493ZM2.59751 4.59757C2.96945 4.22562 3.47392 4.01666 3.99993 4.01666H4.6666C5.02559 4.01666 5.3166 4.30768 5.3166 4.66666C5.3166 5.02565 5.02559 5.31666 4.6666 5.31666H3.99993C3.8187 5.31666 3.64489 5.38866 3.51675 5.51681C3.3886 5.64496 3.3166 5.81876 3.3166 6V12C3.3166 12.1812 3.3886 12.355 3.51675 12.4832C3.6449 12.6113 3.8187 12.6833 3.99993 12.6833H9.99994C10.1812 12.6833 10.355 12.6113 10.4831 12.4832C10.6113 12.355 10.6833 12.1812 10.6833 12V11.3333C10.6833 10.9743 10.9743 10.6833 11.3333 10.6833C11.6923 10.6833 11.9833 10.9743 11.9833 11.3333V12C11.9833 12.526 11.7743 13.0305 11.4024 13.4024C11.0304 13.7744 10.5259 13.9833 9.99994 13.9833H3.99993C3.47392 13.9833 2.96945 13.7744 2.59751 13.4024C2.22556 13.0305 2.0166 12.526 2.0166 12V6C2.0166 5.47398 2.22556 4.96951 2.59751 4.59757Z"
            fill="currentColor"
        />
    </svg>
)
