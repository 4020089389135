import { ComponentProps, FC } from 'react'

export const SidebarRightCollapseIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.99993 3.31666C3.8187 3.31666 3.64489 3.38866 3.51675 3.51681C3.3886 3.64496 3.3166 3.81876 3.3166 4V12C3.3166 12.1812 3.3886 12.355 3.51675 12.4832C3.6449 12.6113 3.8187 12.6833 3.99993 12.6833H9.34994V3.31666H3.99993ZM3.99993 2.01666C3.47392 2.01666 2.96945 2.22562 2.59751 2.59757C2.22556 2.96951 2.0166 3.47398 2.0166 4V12C2.0166 12.526 2.22556 13.0305 2.59751 13.4024C2.96945 13.7744 3.47392 13.9833 3.99993 13.9833H11.9999C12.5259 13.9833 13.0304 13.7744 13.4024 13.4024C13.7743 13.0305 13.9833 12.526 13.9833 12V4C13.9833 3.47398 13.7743 2.96951 13.4024 2.59757C13.0304 2.22562 12.5259 2.01666 11.9999 2.01666H3.99993ZM10.6499 3.31666V12.6833H11.9999C12.1812 12.6833 12.355 12.6113 12.4831 12.4832C12.6113 12.355 12.6833 12.1812 12.6833 12V4C12.6833 3.81876 12.6113 3.64496 12.4831 3.51681C12.355 3.38866 12.1812 3.31666 11.9999 3.31666H10.6499ZM5.54032 6.20704C5.79416 5.9532 6.20571 5.9532 6.45955 6.20704L7.79289 7.54038C8.04673 7.79422 8.04673 8.20577 7.79289 8.45962L6.45955 9.79295C6.20571 10.0468 5.79416 10.0468 5.54032 9.79295C5.28647 9.53911 5.28647 9.12755 5.54032 8.87371L6.41403 8L5.54032 7.12628C5.28647 6.87244 5.28647 6.46088 5.54032 6.20704Z"
            fill="currentColor"
        />
    </svg>
)
