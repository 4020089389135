import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { OccupancyData, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'

import { AvailableDaysPicker } from './AvailableDaysPicker'
import { AvailableLocations } from './AvailableLocations'

type Props = {
    selectedDay?: Dayjs
    showAllPractitioners: boolean
    surgery: UnScheduledSurgery
    openBookingDialog: (slot: OccupancyData) => void
}

export const AvailablePicker = ({ showAllPractitioners, surgery, openBookingDialog }: Props) => {
    const setShowAllPractitioners = useStore(state => state.waitingList.actions.setShowAllPractitioners)
    const [bookableOccupancies, setBookableOccupancies] = useState<OccupancyData[] | undefined>()

    return (
        <>
            <p className="font-bold">Finn operasjonstid</p>
            <label className="flex w-full gap-2 hover:cursor-pointer">
                <input
                    type="checkbox"
                    data-test="show-all-practitioners-checkbox"
                    className="accent-indigo-700 hover:cursor-pointer"
                    checked={showAllPractitioners}
                    onChange={e => setShowAllPractitioners(e.target.checked)}
                />
                <p>Vis ledige tider for alle kirurger</p>
            </label>
            <AvailableDaysPicker surgery={surgery} setBookableOccupancies={setBookableOccupancies} />
            <div className="flex flex-col gap-4">
                <AvailableLocations onClick={openBookingDialog} bookableOccupancies={bookableOccupancies} />
            </div>
        </>
    )
}
