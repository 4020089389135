import { useState } from 'react'

import { RadioButtons, Show } from '~/components'

import { ApplyModelOutput } from './ApplyModelOutput'
import { DeleteModelOutput } from './DeleteModelOutput'
import { GatherStep } from './GatherStep'
import { View, viewOptions } from './views'

function OptimusApi() {
    const [view, setView] = useState<View>('gather_step')

    return (
        <div>
            <RadioButtons options={viewOptions} value={view} onChange={setView} />
            <div className="grid grid-cols-2 gap-x-6 divide-x">
                <Show condition={view === 'gather_step'}>
                    <GatherStep />
                </Show>
                <Show condition={view === 'delete_model_output'}>
                    <DeleteModelOutput />
                </Show>
                <Show condition={view === 'apply_model_output'}>
                    <ApplyModelOutput />
                </Show>
            </div>
        </div>
    )
}

export default OptimusApi
