/**
 * This file is a generic fetch client that does not know about React or store life cycles.
 * It should be treated as simply a convenience wrapper around the generated API client.
 */
import createClient from 'openapi-fetch'

import env from '~/env'

import { components, paths } from './generated-optimus-api-types'
import { apiRefreshingMiddleware } from './unauthorizedHandlingMiddleware'

export const optimusBaseClient = createClient<paths>({
    baseUrl: `${env.VITE_API_BASE_URL}/optimus`,
    credentials: 'include',
})
optimusBaseClient.use(apiRefreshingMiddleware)

export type OptimusSchemas = components['schemas']

export type StaffingPlan = OptimusSchemas['StaffingPlan']
/**
 * The client is a wrapper around .GET, .POST and so on methods that help bind the path and query parameters to the response objects.
 * This allows us to use to instantiate generic "import*" methods in the store.
 */
const optimusClient = {
    getBlockScheduleUtilization: async (block_schedule_id: number) =>
        await optimusBaseClient.GET('/plan-metrics/block_schedules/{block_schedule_id}/estimated_utilization', {
            params: { path: { block_schedule_id: block_schedule_id } },
        }),

    getBlockScheduleDuration: async (block_schedule_id: number) =>
        await optimusBaseClient.GET('/plan-metrics/block_schedules/{block_schedule_id}/estimated_duration', {
            params: { path: { block_schedule_id: block_schedule_id } },
        }),

    staffingPlan: {
        // POST Operations
        suggests: async (data: OptimusSchemas['StaffingPlanPeriod']) => await optimusBaseClient.POST('/staffing-plan/', { body: data }),

        apply: async (data: StaffingPlan) => {
            await optimusBaseClient.POST('/staffing-plan/apply', { body: data })
        },

        // GET Operations
        getStatus: async (department_id: number, year: number, month: number) =>
            await optimusBaseClient.GET('/staffing-plan/status/{department_id}/{year}/{month}', { params: { path: { department_id, year, month } } }),

        getSuggestion: async (department_id: number, year: number, month: number) =>
            await optimusBaseClient.GET('/staffing-plan/result/{department_id}/{year}/{month}', { params: { path: { department_id, year, month } } }),
    },
} as const

export default optimusClient
