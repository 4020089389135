import clsx from 'clsx'
import { useState } from 'react'

import { AlertSquareRoundedIcon, CircleCheckIcon, CloseIcon } from '~/icons'
import { ToastProps } from '~/store/slices/toasterSlice'
import { useStore } from '~/store/store'

const TIME_TO_CLOSE = 4000

const Toast = ({ id, type, message }: ToastProps) => {
    const [hovered, setHovered] = useState(false)
    const removeToast = useStore(state => state.toaster.actions.removeToast)

    return (
        <div
            className="pointer-events-auto w-96 rounded bg-white text-sm shadow-diCard"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className="flex items-center justify-between px-3 pb-2 pt-3">
                <div
                    className={clsx('flex items-center gap-2', {
                        'text-red-500': type === 'error',
                        'text-orange-500': type === 'warning',
                        'text-emerald-500': type === 'success',
                    })}
                >
                    {type === 'error' && <AlertSquareRoundedIcon className="shrink-0 fill-red-500" />}
                    {type === 'warning' && <AlertSquareRoundedIcon className="shrink-0 fill-orange-500" />}
                    {type === 'success' && <CircleCheckIcon className="shrink-0 fill-emerald-500" />}
                    <span className="break-normal first-letter:capitalize">{message}</span>
                </div>
                <CloseIcon onClick={() => removeToast(id)} className="h-5 w-5 shrink-0 cursor-pointer rounded-full hover:bg-blue-900/40" />
            </div>

            <div className="w-full overflow-hidden rounded-b bg-gray-100">
                <div
                    className="toast-progress h-1 bg-diBlue-500"
                    onAnimationEnd={() => removeToast(id)}
                    style={{ animationDuration: `${TIME_TO_CLOSE}ms`, animationPlayState: hovered ? 'paused' : 'running' }}
                />
            </div>
        </div>
    )
}

export const Toaster = () => {
    const toasts = useStore(state => state.toaster.toasts)

    return (
        <div className="pointer-events-none absolute left-0 top-0 z-priority h-screen w-screen">
            <div className="absolute mt-3 flex w-full flex-col items-center space-y-3">
                {toasts.map(toast => (
                    <Toast key={toast.id} {...toast} />
                ))}
            </div>
        </div>
    )
}
