import { ComponentProps, FC } from 'react'

export const MessagePlusIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.0001 3.31666C3.64206 3.31666 3.29868 3.45889 3.0455 3.71207C2.79233 3.96524 2.6501 4.30862 2.6501 4.66666V10C2.6501 10.358 2.79233 10.7014 3.0455 10.9546C3.29868 11.2078 3.64206 11.35 4.0001 11.35H5.33343C5.69242 11.35 5.98343 11.641 5.98343 12V12.852L7.67234 11.8386C7.98017 11.6539 8.37944 11.7537 8.56414 12.0616C8.74883 12.3694 8.64901 12.7687 8.34119 12.9534L5.66785 14.5574C5.46705 14.6778 5.21696 14.681 5.01318 14.5656C4.8094 14.4502 4.68343 14.2342 4.68343 14V12.65H4.0001C3.29727 12.65 2.62324 12.3708 2.12626 11.8738C1.62929 11.3769 1.3501 10.7028 1.3501 10V4.66666C1.3501 3.96384 1.62929 3.2898 2.12626 2.79283C2.62324 2.29586 3.29727 2.01666 4.0001 2.01666H12.0001C12.7029 2.01666 13.377 2.29586 13.8739 2.79283C14.3709 3.2898 14.6501 3.96384 14.6501 4.66666V8.33333C14.6501 8.69231 14.3591 8.98333 14.0001 8.98333C13.6411 8.98333 13.3501 8.69231 13.3501 8.33333V4.66666C13.3501 4.30862 13.2079 3.96524 12.9547 3.71207C12.7015 3.45889 12.3581 3.31666 12.0001 3.31666H4.0001ZM4.68343 6C4.68343 5.64101 4.97445 5.35 5.33343 5.35H10.6668C11.0257 5.35 11.3168 5.64101 11.3168 6C11.3168 6.35898 11.0257 6.65 10.6668 6.65H5.33343C4.97445 6.65 4.68343 6.35898 4.68343 6ZM4.68343 8.66666C4.68343 8.30768 4.97445 8.01666 5.33343 8.01666H9.33343C9.69242 8.01666 9.98343 8.30768 9.98343 8.66666C9.98343 9.02565 9.69242 9.31666 9.33343 9.31666H5.33343C4.97445 9.31666 4.68343 9.02565 4.68343 8.66666ZM12.6668 10.0167C13.0257 10.0167 13.3168 10.3077 13.3168 10.6667V12.0167H14.6668C15.0257 12.0167 15.3168 12.3077 15.3168 12.6667C15.3168 13.0256 15.0257 13.3167 14.6668 13.3167H13.3168V14.6667C13.3168 15.0256 13.0257 15.3167 12.6668 15.3167C12.3078 15.3167 12.0168 15.0256 12.0168 14.6667V13.3167H10.6668C10.3078 13.3167 10.0168 13.0256 10.0168 12.6667C10.0168 12.3077 10.3078 12.0167 10.6668 12.0167H12.0168V10.6667C12.0168 10.3077 12.3078 10.0167 12.6668 10.0167Z"
            fill="currentColor"
        />
    </svg>
)
