import clsx from 'clsx'
import Select, { GroupBase } from 'react-select'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'

import { inputStyles, selectSize, Show, tagColors, TextInputSize } from '~/components'
import { CheckIcon, ChevronDownIcon, CloseIcon } from '~/icons'
import { isNullish } from '~/utils/guards'

type Props = {
    size?: TextInputSize
}

export const DISelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
    size = 'md',
    ...props
}: StateManagerProps<Option, IsMulti, Group> & Props) => (
    <Select
        {...props}
        components={{
            IndicatorsContainer: ({ getValue, clearValue, selectProps }) => {
                const values = getValue()
                const hasValues = !isNullish(values) && values?.length !== 0
                const isClearable = selectProps.isClearable ?? true

                return (
                    <Show condition={hasValues && isClearable} fallback={<ChevronDownIcon className="h-4 w-4" />}>
                        <div className="group cursor-pointer p-0.5" onClick={clearValue}>
                            <CloseIcon className="h-4 w-4 text-gray-700 group-hover:text-gray-400" />
                        </div>
                    </Show>
                )
            },
            MenuList: ({ children, innerRef, innerProps }) => (
                <div ref={innerRef} {...innerProps} className="flex max-h-64 list-outside list-none flex-col space-y-1 overflow-y-auto p-1">
                    {children}
                </div>
            ),
            Option: ({ children, innerProps, innerRef, isFocused, isSelected }) => (
                <div
                    ref={innerRef}
                    {...innerProps}
                    className={clsx(
                        'flex w-full cursor-pointer items-center justify-between space-x-4 rounded px-2 py-1',
                        isSelected ? 'bg-diBlue-100' : isFocused ? 'bg-gray-100' : 'hover:bg-gray-100'
                    )}
                >
                    {children}

                    <Show condition={isSelected}>
                        <CheckIcon className="h-5 w-5 text-diBlue-500" />
                    </Show>
                </div>
            ),
            MultiValueContainer: ({ children, innerProps }) => (
                <div {...innerProps} className={clsx('flex items-center justify-between rounded', tagColors['primary'])}>
                    {children}
                </div>
            ),
            MultiValueLabel: ({ children }) => <div className="pl-2 text-sm font-medium">{children}</div>,
            MultiValueRemove: ({ innerProps }) => (
                <div {...innerProps} className="cursor-pointer pl-0.5 pr-1">
                    <CloseIcon className="h-4 w-4" />
                </div>
            ),
        }}
        styles={{
            control: base => ({ ...base, minHeight: 'unset' }),
            valueContainer: base => ({ ...base, padding: 0 }),
            dropdownIndicator: base => ({ ...base, padding: '0 2px' }),
            input: base => ({ ...base, padding: 0, margin: 0 }),
            indicatorSeparator: () => ({ display: 'none' }),
        }}
        classNames={{
            control: () => clsx(inputStyles, selectSize[size], 'flex cursor-pointer items-center justify-between'),
            valueContainer: () => 'gap-1',
        }}
    />
)
