import { Dayjs } from 'dayjs'

import { getToday } from './getToday'
import { isBefore } from './isBefore'

export default function getMonthRangeFromToday(date: Dayjs) {
    const today = getToday()
    if (isBefore(date.startOf('month'), today)) {
        return {
            start: today,
            end: today.add(31, 'day'),
        }
    } else {
        return { start: date.startOf('month'), end: date.endOf('month').add(1, 'day') }
    }
}
