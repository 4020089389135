import { Button } from '~/components'
import { CloseIcon } from '~/icons'

type Props = {
    onClose: () => void
}

export const SuggestionsInfoCard = ({ onClose }: Props) => (
    <div className="rounded bg-gray-100 px-3 py-1 pb-3 shadow-md">
        <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
                <p className="text-sm">Hvorfor ser jeg disse pasientene?</p>
                <Button size="icon" onClick={onClose} color="custom">
                    <CloseIcon width={20} />
                </Button>
            </div>
            <p className="text-sm">
                Pasientene nedenfor er valgt basert på passende operasjonstype og passende/uspesifisert kirurg. De sorteres dynamisk ut ifra prioritet og kort
                varsel, samt klarert-status og antall dager de har ventet.
            </p>
        </div>
    </div>
)
