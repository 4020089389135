import { ComponentProps, FC } from 'react'

export const MailIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33343 3.98335C3.1522 3.98335 2.97839 4.05534 2.85024 4.18349C2.79664 4.23709 2.75286 4.29868 2.72007 4.36546L8.0001 7.88548L13.2801 4.36546C13.2473 4.29868 13.2036 4.23709 13.15 4.18349C13.0218 4.05534 12.848 3.98335 12.6668 3.98335H3.33343ZM13.3501 5.88122L8.36065 9.20752C8.14232 9.35307 7.85788 9.35307 7.63954 9.20752L2.6501 5.88122V11.3333C2.6501 11.5146 2.72209 11.6884 2.85024 11.8165C2.97839 11.9447 3.1522 12.0167 3.33343 12.0167H12.6668C12.848 12.0167 13.0218 11.9447 13.15 11.8165C13.2781 11.6884 13.3501 11.5146 13.3501 11.3333V5.88122ZM1.931 3.26425C2.30295 2.89231 2.80742 2.68335 3.33343 2.68335H12.6668C13.1928 2.68335 13.6972 2.89231 14.0692 3.26425C14.4411 3.6362 14.6501 4.14067 14.6501 4.66668V11.3333C14.6501 11.8594 14.4411 12.3638 14.0692 12.7358C13.6972 13.1077 13.1928 13.3167 12.6668 13.3167H3.33343C2.80742 13.3167 2.30295 13.1077 1.931 12.7358C1.55906 12.3638 1.3501 11.8594 1.3501 11.3333V4.66668C1.3501 4.14067 1.55906 3.6362 1.931 3.26425Z"
            fill="currentColor"
        />
    </svg>
)
