import { HeroTooltip } from '~/hero-ui/stories/HeroTooltip/HeroTooltip'
import { useDurationQuery } from '~/hooks/tanstack-query/optimus/useDurationQuery'
import { HourglassEmptyIcon } from '~/icons'
import { BlockSchedule } from '~/store/selectors'
import { formatDurationFromIso } from '~/utils/extendedDayjs'

type Props = {
    blockSchedule: BlockSchedule | null
}

export const Duration = ({ blockSchedule }: Props) => {
    // 'can' refers to the current limitation of Optimus API to only provide duration data for block schedules
    // with the rule_definition_id of 'max_count_of_surgeries_per_patient_group'
    const canGenerateData = !!blockSchedule?.id && blockSchedule.rule_instance?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'

    const { data, isLoading, isPending } = useDurationQuery({
        request: { blockScheduleId: blockSchedule?.id ?? 0, updatedAt: blockSchedule?.updated_at ?? '' },
        isEnabled: canGenerateData,
    })

    return (
        <>
            {isPending && <div data-test="duration-chart-isloading" hidden />}

            <HeroTooltip side="top" tooltipContent={<p>{data?.description ?? 'loading'}</p>} className="w-48">
                <div
                    className={`mb-1 flex w-fit items-center gap-1 rounded border border-slate-200 p-1 ${isLoading ? 'animate-pulse-fast bg-gradient-to-l from-slate-200 via-slate-50 to-slate-200 bg-large' : ''}`}
                    data-test="duration-chart-loaded"
                >
                    <HourglassEmptyIcon height={16} className="text-slate-500" />
                    <p className="text-nowrap">{data && formatDurationFromIso(data?.duration)}</p>
                </div>
            </HeroTooltip>
        </>
    )
}
