import dayjs from 'dayjs'

import { getDayOvernight } from '~/store/slices/filterSlice'

import { AnyScheduledSurgery, formatPersonalId, formatPersonalIdWithLastName, getAssistants, getSurgeons } from '../dips'
import { day, format, formatDuration } from '../extendedDayjs'
import { isNotNullish, isNullish } from '../guards'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

export const getCheckInTime = (surgery: AnyScheduledSurgery) =>
    isNotNullish(surgery.contact?.checkInTime) ? format(day(surgery.contact?.checkInTime), 'HH:mm') : '--:--'

const getTentativeDate = (surgery: AnyScheduledSurgery) =>
    isNullish(surgery.contact?.checkInTime) || ['NotTentative', undefined].includes(surgery.contact?.tentative)
        ? ''
        : format(day(surgery.contact!.checkInTime), surgery.contact!.tentative === 'TentativeMonth' ? 'MMM YYYY' : 'DD MMM YYYY')

export const getFormattedSurgery = (surgery: AnyScheduledSurgery) => {
    const patient = surgery.patient
    const patientName = formatPersonalIdWithLastName(patient)
    const patientNameDetailed = `${surgery.patient?.firstName} ${surgery.patient?.lastName} (${formatPersonalId(surgery.patient?.nationalId ?? '')})`

    const asa = surgery.surgeryOrderDetails?.asa
    const bmi = surgery.surgeryOrderDetails?.bmi
    const nprCodeName = surgery.contact?.levelOfCareNpr?.nprCodeName
    const dayOvernight = getDayOvernight(nprCodeName)

    const tentativeDate = getTentativeDate(surgery)
    const checkInTime = getCheckInTime(surgery)
    const plannedStartTime = 'plannedTheatreInTime' in surgery ? format(day(surgery.plannedTheatreInTime), 'HH:mm') : '--:--'
    const knifeTimeDuration = dayjs.duration(surgery.plannedProcedureDuration ?? zeroDurationToken)
    const knifeTime = knifeTimeDuration.asSeconds() > 0 ? formatDuration(knifeTimeDuration) : '- min'

    const practitioners = [...getSurgeons(surgery.surgeryResources), ...getAssistants(surgery.surgeryResources)]

    return {
        patient,
        patientName,
        patientNameDetailed,
        asa,
        bmi,
        dayOvernight,
        tentativeDate,
        checkInTime,
        knifeTime,
        plannedStartTime,
        practitioners,
    }
}
