import { ComponentProps, FC } from 'react'

export const HourglassEmptyIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.73574 1.73562C3.98266 1.4887 4.31756 1.34998 4.66676 1.34998H11.3334C11.6826 1.34998 12.0175 1.48869 12.2645 1.73562C12.5114 1.98254 12.6501 2.31744 12.6501 2.66664V3.99998C12.6501 5.23323 12.1602 6.41598 11.2881 7.28802C11.0111 7.56506 10.7027 7.80353 10.3713 7.99998C10.7027 8.19642 11.0111 8.43489 11.2881 8.71193C12.1602 9.58397 12.6501 10.7667 12.6501 12V13.3333C12.6501 13.6825 12.5114 14.0174 12.2645 14.2643C12.0175 14.5113 11.6826 14.65 11.3334 14.65H4.66676C4.31756 14.65 3.98266 14.5113 3.73574 14.2643C3.48882 14.0174 3.3501 13.6825 3.3501 13.3333V12C3.3501 10.7667 3.84001 9.58397 4.71205 8.71193C4.98909 8.43489 5.29748 8.19642 5.62889 7.99998C5.29748 7.80353 4.98909 7.56506 4.71205 7.28802C3.84001 6.41598 3.3501 5.23323 3.3501 3.99998V2.66664C3.3501 2.31744 3.48882 1.98254 3.73574 1.73562ZM8.0001 8.64998C7.11162 8.64998 6.25954 9.00292 5.63129 9.63117C5.00304 10.2594 4.6501 11.1115 4.6501 12V13.3333C4.6501 13.3377 4.65185 13.342 4.65498 13.3451C4.6581 13.3482 4.66234 13.35 4.66676 13.35H11.3334C11.3379 13.35 11.3421 13.3482 11.3452 13.3451C11.3483 13.342 11.3501 13.3377 11.3501 13.3333V12C11.3501 11.1115 10.9972 10.2594 10.3689 9.63117C9.74066 9.00292 8.88857 8.64998 8.0001 8.64998ZM4.66676 2.64998C4.66234 2.64998 4.6581 2.65173 4.65498 2.65486C4.65185 2.65798 4.6501 2.66222 4.6501 2.66664V3.99998C4.6501 4.88845 5.00304 5.74054 5.63129 6.36878C6.25954 6.99703 7.11162 7.34998 8.0001 7.34998C8.88857 7.34998 9.74066 6.99703 10.3689 6.36878C10.9972 5.74054 11.3501 4.88845 11.3501 3.99998V2.66664C11.3501 2.66222 11.3483 2.65798 11.3452 2.65486C11.3421 2.65173 11.3379 2.64998 11.3334 2.64998H4.66676Z"
            fill="currentColor"
        />
    </svg>
)
