import { Dayjs } from 'dayjs'
import moize from 'moize'

import env from '~/env'
import { isStringWithContent } from '~/utils/guards'

import { day } from './day'

/**
 * Retrieves the current date using the Dayjs library.
 * It first checks if the VITE_TODAY environment variable is set,
 * and if so, uses it to create a new instance of Dayjs.
 * If the environment variable is not set or is invalid,
 * it falls back to creating a new instance based on the current system date.
 *
 * @returns {Dayjs} - The current date as an instance of Dayjs.
 */
export const getNow = moize(
    function getNow(): Dayjs {
        const viteToday = env.VITE_TODAY

        if (isStringWithContent(viteToday)) {
            const envToday = day(viteToday)

            if (envToday.isValid()) {
                return envToday
            }

            console.warn(`The VITE_TODAY environment variable is set to an invalid date: ${viteToday}. Using the current system date instead.`)
        }

        return day(new Date())
    },
    { maxAge: 1000, maxSize: 2000 }
)
