import { Dayjs } from 'dayjs'

import { SurgeryMetadata, UnScheduledSurgery } from '~/store/selectors'
import { diff } from '~/utils/extendedDayjs'
import { getNow } from '~/utils/extendedDayjs/helpers/getNow'

// Defining the minimal required properties for sorting, so that it's easier and safer to create objects in testings
export type SurgeryForSorting = {
    creationTime?: UnScheduledSurgery['creationTime']
    surgeryMetadata?: {
        patient_short_notice?: SurgeryMetadata['patient_short_notice']
        patient_prioritized?: SurgeryMetadata['patient_prioritized']
        patient_ready?: SurgeryMetadata['patient_ready']
    }
}

function getDaysWaitedScore(surgery: SurgeryForSorting) {
    if (surgery.creationTime === undefined) return 0

    const age = diff(getNow(), surgery.creationTime, 'days')

    return age / 100
}

function getShortNoticeScore(surgery: SurgeryForSorting) {
    return surgery.surgeryMetadata?.patient_short_notice ? 1 : 0
}

/**
 * This function calculates how relevant short_notice surgeries should be
 * It will give a score of 1 if the surgery is today, and 0 if the surgery is 14 days or more away.
 * https://www.desmos.com/calculator/m3aakkuod0
 */
function getShortNoticeRelevancy(daysUntilBooking: number) {
    const rawWeight = 1 - daysUntilBooking / 14
    return Math.max(0, Math.min(1, rawWeight))
}

function getClearedScore(surgery: SurgeryForSorting) {
    return surgery.surgeryMetadata?.patient_ready ? 1 : 0
}

type Term = {
    weight: number
    fn: (surgery: SurgeryForSorting) => number
}

function getScore(rankers: Term[], surgery: SurgeryForSorting) {
    return rankers.map(({ weight, fn }) => weight * fn(surgery)).reduce((acc, score) => acc + score, 0)
}

export function createSorter(bookingDate: Dayjs) {
    const daysUntilBooking = diff(bookingDate, getNow(), 'days')
    const shortNoticeRelevancy = getShortNoticeRelevancy(daysUntilBooking)

    const terms: Term[] = [
        { weight: 1.0 * shortNoticeRelevancy, fn: getShortNoticeScore },
        { weight: 0.5, fn: getClearedScore },
        { weight: 0.4, fn: getDaysWaitedScore },
    ].filter(Boolean)

    return function sortByScore(surgery1: SurgeryForSorting, surgery2: SurgeryForSorting) {
        return getScore(terms, surgery2) - getScore(terms, surgery1)
    }
}
