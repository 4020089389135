import { ReactNode } from 'react'

import { CloseIcon } from '~/icons'

const variants = {
    filled: 'bg-diBlue-500 text-white border-diBlue-500',
    outlined: 'text-diBlue-500 border-diBlue-500',
    none: '',
}

type Props = {
    children: ReactNode
    icon: ReactNode
    variant: keyof typeof variants
    onRemove: () => void
}

export const PractitionerTag = ({ children, icon, onRemove, variant }: Props) => (
    <div className={`inline-flex items-center justify-between rounded border px-2 py-0.5 font-semibold ${variants[variant]}`}>
        {icon}
        <div className="ml-0.5 text-xs">{children}</div>
        <div onClick={onRemove} className="ml-1.5 cursor-pointer">
            <CloseIcon className="h-4 w-4" />
        </div>
    </div>
)
