import { ComponentProps, FC } from 'react'

export const CalendarShareIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6667 1.35001C10.3077 1.35001 10.0167 1.64102 10.0167 2.00001V2.68334H5.9834V2.00001C5.9834 1.64102 5.69238 1.35001 5.3334 1.35001C4.97441 1.35001 4.6834 1.64102 4.6834 2.00001V2.68334H4.00006C3.47405 2.68334 2.96958 2.8923 2.59764 3.26424C2.22569 3.63619 2.01673 4.14066 2.01673 4.66667V8.66667C2.01673 9.02566 2.30775 9.31667 2.66673 9.31667C3.02572 9.31667 3.31673 9.02566 3.31673 8.66667V7.98334H12.6834V12.6667C12.6834 12.8479 12.6114 13.0217 12.4833 13.1499C12.3551 13.278 12.1813 13.35 12.0001 13.35H8.00006C7.64108 13.35 7.35006 13.641 7.35006 14C7.35006 14.359 7.64108 14.65 8.00006 14.65H12.0001C12.5261 14.65 13.0305 14.441 13.4025 14.0691C13.7744 13.6972 13.9834 13.1927 13.9834 12.6667V4.66667C13.9834 4.14066 13.7744 3.63619 13.4025 3.26424C13.0305 2.8923 12.5261 2.68334 12.0001 2.68334H11.3167V2.00001C11.3167 1.64102 11.0257 1.35001 10.6667 1.35001ZM11.3167 3.98334H12.0001C12.1813 3.98334 12.3551 4.05533 12.4833 4.18348C12.6114 4.31163 12.6834 4.48544 12.6834 4.66667V6.68334H3.31673V4.66667C3.31673 4.48544 3.38873 4.31163 3.51688 4.18348C3.64503 4.05533 3.81883 3.98334 4.00006 3.98334H4.6834V4.66667C4.6834 5.02566 4.97441 5.31667 5.3334 5.31667C5.69238 5.31667 5.9834 5.02566 5.9834 4.66667V3.98334H10.0167V4.66667C10.0167 5.02566 10.3077 5.31667 10.6667 5.31667C11.0257 5.31667 11.3167 5.02566 11.3167 4.66667V3.98334Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.46651 11.362C3.21267 11.1082 3.21267 10.6966 3.46651 10.4428C3.72035 10.1889 4.13191 10.1889 4.38575 10.4428L6.50707 12.5641C6.76091 12.8179 6.76091 13.2295 6.50707 13.4833L4.38575 15.6046C4.13191 15.8585 3.72035 15.8585 3.46651 15.6046C3.21267 15.3508 3.21267 14.9392 3.46651 14.6854L4.47821 13.6737L1.33341 13.6737C0.974422 13.6737 0.683407 13.3827 0.683407 13.0237C0.683407 12.6647 0.974422 12.3737 1.33341 12.3737L4.47821 12.3737L3.46651 11.362Z"
            fill="currentColor"
        />
    </svg>
)
