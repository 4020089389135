import { ComponentProps, FC } from 'react'

export const RepeatIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8736 1.54036C11.1275 1.28652 11.539 1.28652 11.7929 1.54036L13.7929 3.54036C14.0467 3.7942 14.0467 4.20575 13.7929 4.45959L11.7929 6.4596C11.539 6.71344 11.1275 6.71344 10.8736 6.4596C10.6198 6.20575 10.6198 5.7942 10.8736 5.54036L11.764 4.64998H4.6666C4.30856 4.64998 3.96518 4.79221 3.71201 5.04538C3.45883 5.29856 3.3166 5.64193 3.3166 5.99998V7.99998C3.3166 8.35896 3.02559 8.64998 2.6666 8.64998C2.30762 8.64998 2.0166 8.35896 2.0166 7.99998V5.99998C2.0166 5.29715 2.2958 4.62311 2.79277 4.12614C3.28974 3.62917 3.96378 3.34998 4.6666 3.34998H11.764L10.8736 2.45959C10.6198 2.20575 10.6198 1.7942 10.8736 1.54036ZM12.6833 9.99998V7.99998C12.6833 7.64099 12.9743 7.34998 13.3333 7.34998C13.6923 7.34998 13.9833 7.64099 13.9833 7.99998V9.99998C13.9833 10.7028 13.7041 11.3768 13.2071 11.8738C12.7101 12.3708 12.0361 12.65 11.3333 12.65H4.23584L5.12622 13.5404C5.38006 13.7942 5.38006 14.2058 5.12622 14.4596C4.87238 14.7134 4.46082 14.7134 4.20698 14.4596L2.20698 12.4596C1.95314 12.2058 1.95314 11.7942 2.20698 11.5404L4.20698 9.54036C4.46082 9.28652 4.87238 9.28652 5.12622 9.54036C5.38006 9.7942 5.38006 10.2058 5.12622 10.4596L4.23584 11.35H11.3333C11.6913 11.35 12.0347 11.2077 12.2879 10.9546C12.541 10.7014 12.6833 10.358 12.6833 9.99998Z"
            fill="currentColor"
        />
    </svg>
)
