import clsx from 'clsx'
import sortBy from 'lodash/sortBy'

import { HolidayTag, Show, Table, Tag } from '~/components'
import { useImportOccupancyEntities } from '~/hooks/useImportOccupancyEntities'
import { Practitioner, selectActiveFilteredLocations, selectGetActiveOnCallPractitioners, selectWeek, selectWeekDates } from '~/store/selectors'
import { useStore } from '~/store/store'
import { format, getToday, isSameDate } from '~/utils/extendedDayjs'
import { isHoliday } from '~/utils/holidays'
import { getHolidayStyles } from '~/utils/utils'

import { Cell } from './Cell'

function formatOnCallPractitioners(practitioners: Practitioner[] | undefined) {
    if (!practitioners) return ''
    // here we sort the on-call practitioners by their short name to ensure the same result every time (for screenshot testing)
    const sorted = sortBy(practitioners, p => p?.short_name)
    const shortName = sorted?.[0]?.short_name
    return sorted?.length > 1 ? `${shortName} +` : shortName
}

export function Calendar() {
    const { startTime, endTime } = useStore(selectWeek)
    const weekDates = useStore(selectWeekDates)
    const activeLocations = useStore(selectActiveFilteredLocations)
    const getActiveOnCallPractitioners = useStore(selectGetActiveOnCallPractitioners)

    const { isLoading } = useImportOccupancyEntities(startTime, endTime)

    return (
        <Table
            isLoading={isLoading}
            data-test={isLoading ? '' : 'or-schedule-page'}
            header={{
                label: 'Stuer',
                row: weekDates,
                rowClassName: ({ date }) =>
                    clsx('w-[calc((100vw-116px)/5)]', getHolidayStyles(date), {
                        'calendar-today': isSameDate(date, getToday()),
                    }),
                rowRender: ({ date }) => {
                    const dateIsHoliday = isHoliday(date)
                    const onCallPractitioners = getActiveOnCallPractitioners.byDate(date)

                    return (
                        <div className="flex h-[100%] flex-col gap-1">
                            <div className="whitespace-nowrap capitalize">{format(date, 'dddd DD.MM')}</div>
                            <Show condition={onCallPractitioners.length > 0 && !dateIsHoliday}>
                                <div className="flex justify-center">
                                    <Tag color="on-call">
                                        <span>
                                            <span className="font-bold">Ox: </span>
                                            {formatOnCallPractitioners(onCallPractitioners)}
                                        </span>
                                    </Tag>
                                </div>
                            </Show>
                            <Show condition={dateIsHoliday}>
                                <HolidayTag />
                            </Show>
                        </div>
                    )
                },
            }}
            body={{
                rows: activeLocations,
                rowRender: ({ room_code }) => (
                    <span className="capitalize" data-test={`room-code-${room_code}`}>
                        {room_code}
                    </span>
                ),
                cellClassName: (_location, { id: date }) => clsx('align-top hover:bg-gray-100', getHolidayStyles(date)),
                cellRender: (location, { date }) => {
                    return <Cell date={date} location={location} />
                },
            }}
        />
    )
}
