import { ComponentProps, FC } from 'react'

export const ColumnsIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66676 2.64998C2.66234 2.64998 2.6581 2.65173 2.65498 2.65486C2.65185 2.65798 2.6501 2.66222 2.6501 2.66664V13.3333C2.6501 13.3377 2.65185 13.342 2.65498 13.3451C2.6581 13.3482 2.66234 13.35 2.66676 13.35H5.3501V2.64998H2.66676ZM2.66676 1.34998C2.31756 1.34998 1.98266 1.4887 1.73574 1.73562C1.48882 1.98254 1.3501 2.31744 1.3501 2.66664V13.3333C1.3501 13.6825 1.48882 14.0174 1.73574 14.2643C1.98266 14.5113 2.31756 14.65 2.66676 14.65H13.3334C13.6826 14.65 14.0175 14.5113 14.2645 14.2643C14.5114 14.0174 14.6501 13.6825 14.6501 13.3333V2.66664C14.6501 2.31744 14.5114 1.98254 14.2645 1.73562C14.0175 1.48869 13.6826 1.34998 13.3334 1.34998H2.66676ZM6.6501 2.64998V13.35H9.3501V2.64998H6.6501ZM10.6501 2.64998V13.35H13.3334C13.3379 13.35 13.3421 13.3482 13.3452 13.3451C13.3483 13.342 13.3501 13.3377 13.3501 13.3333V2.66664C13.3501 2.66222 13.3483 2.65798 13.3452 2.65486C13.3421 2.65173 13.3379 2.64998 13.3334 2.64998H10.6501Z"
            fill="currentColor"
        />
    </svg>
)
