import uniqBy from 'lodash/uniqBy'

import { DipsSchemas } from '~/clients/dips-client'

import { isNotNullish } from '../guards'

export const practitionerResourceTypeIds = [220716, 104600, 223475] as const
type PractitionerResourceTypeId = (typeof practitionerResourceTypeIds)[number]

export const practitionerResourceNames = {
    Surgeon: 220716,
    Assistant: 104600,
    FirstAssistant: 223475,
    SecondAssistant: 104600,
} as const satisfies Record<string, PractitionerResourceTypeId>

const locationResourceTypeIds = [104605] as const
type LocationResourceTypeId = (typeof locationResourceTypeIds)[number]

const locationResourceNames = {
    TheaterRoom: 104605,
} satisfies Record<string, LocationResourceTypeId>

export const surgeryResourceNames = { ...practitionerResourceNames, ...locationResourceNames } as const

const practitionerTypePrefixes = {
    [practitionerResourceNames.Surgeon]: 'H',
    [practitionerResourceNames.Assistant]: 'A',
    [practitionerResourceNames.FirstAssistant]: 'A',
} satisfies Record<PractitionerResourceTypeId, string>

export function getPractitionerTypePrefix(practitionerType: PractitionerResourceTypeId) {
    return practitionerTypePrefixes[practitionerType]
}

export type MinimalPractitioner = {
    short_name: string
    name?: string
    practitionerType?: PractitionerResourceTypeId // if provided, will sort by them; otherwise or afterwards by name
}

type SurgeryResource = DipsSchemas['SurgeryResource']
type SurgeryResourceV2 = DipsSchemas['SurgeryResourceV2']

export function getTheaterRoom(resources: SurgeryResource[] | null | undefined): SurgeryResource | undefined {
    return isNotNullish(resources) ? resources.find(resource => resource.typeId === surgeryResourceNames.TheaterRoom) : undefined
}

function toPractitioner(practitioner: SurgeryResource & { typeId: PractitionerResourceTypeId }): MinimalPractitioner {
    return {
        short_name: practitioner.shortName ?? '???',
        name: practitioner.name ?? undefined,
        practitionerType: practitioner.typeId, // not to be confused with resourceId
    }
}

/**
 * Checks if a Resource is a Surgeon Resource.
 * What a Surgeon Resource is, is defined by both the DIPS API and by the hospital settings.
 * This means that this function might need to be tweaked to enable hospital-specific configurations in the future.
 * Refer to: https://deepinsight-no.slack.com/archives/C04V3LBL7V0/p1730887666575479
 */
export function isMainSurgeon(resource: SurgeryResource | null | undefined): resource is SurgeryResource & { typeId: PractitionerResourceTypeId } {
    return Boolean(resource && resource.typeId === surgeryResourceNames.Surgeon)
}

export function isMainSurgeonV2(
    resource: SurgeryResourceV2 | null | undefined
): resource is SurgeryResourceV2 & { resourceTypeId: PractitionerResourceTypeId } {
    return Boolean(resource && resource.resourceTypeId === surgeryResourceNames.Surgeon)
}

/**
 * Converts a Resource specifically to a "Main Surgeon" Resource ("Hovedoperatør").
 * What a "Main Surgeon Resource" is, is defined by both the DIPS API and by the hospital settings.
 * This means that this function might need to be tweaked to enable hospital-specific configurations in the future.
 * Refer to: https://deepinsight-no.slack.com/archives/C04V3LBL7V0/p1730887666575479
 */
export function toMainSurgeon(resource: SurgeryResource) {
    return { ...resource, typeId: surgeryResourceNames.Surgeon, assignedResourceTypeName: 'Hovedoperatør' } as const
}

/**
 * Converts a Resource specifically to an "Assistant Surgeon" Surgeon Resource ("Assistent").
 * What an "Assistant Surgeon" Resource is, is defined by both the DIPS API and by the hospital settings.
 * This means that this function might need to be tweaked to enable hospital-specific configurations in the future.
 * Refer to: https://deepinsight-no.slack.com/archives/C04V3LBL7V0/p1730887666575479
 */
export function toAssistant(resource: SurgeryResource) {
    return { ...resource, typeId: surgeryResourceNames.Assistant, assignedResourceTypeName: 'Assistent' } as const
}

export function isAssistant(resource: SurgeryResource | null | undefined): resource is SurgeryResource & { typeId: PractitionerResourceTypeId } {
    return (
        resource?.typeId === surgeryResourceNames.Assistant ||
        resource?.typeId === surgeryResourceNames.FirstAssistant ||
        resource?.typeId === surgeryResourceNames.SecondAssistant
    )
}

export function isAssistantV2(resource: SurgeryResourceV2 | null | undefined): resource is SurgeryResourceV2 & { resourceTypeId: PractitionerResourceTypeId } {
    return (
        resource?.resourceTypeId === surgeryResourceNames.Assistant ||
        resource?.resourceTypeId === surgeryResourceNames.FirstAssistant ||
        resource?.resourceTypeId === surgeryResourceNames.SecondAssistant
    )
}

export function isPractitionerSlotV1(slot: SurgeryResource | null | undefined) {
    return isAssistant(slot) || isMainSurgeon(slot)
}

export function isPractitionerSlotV2(slot: SurgeryResourceV2 | null | undefined) {
    return isAssistantV2(slot) || isMainSurgeonV2(slot)
}

export function isPractitionerSlot(slot: SurgeryResource | null | undefined) {
    return isPractitionerSlotV1(slot) || isPractitionerSlotV2(slot)
}

export function setResource(slot: SurgeryResource, resource: DipsSchemas['ResourceDto']) {
    return {
        ...slot,
        resourceId: resource.id,
        shortName: resource.shortName,
        name: resource.name,
    }
}

export function isTheaterRoom(resource: SurgeryResource | null | undefined): resource is SurgeryResource & { typeId: LocationResourceTypeId } {
    return Boolean(resource && resource.typeId === surgeryResourceNames.TheaterRoom)
}

export function isPractitioner(resource: SurgeryResource | null | undefined): resource is SurgeryResource & { typeId: PractitionerResourceTypeId } {
    return isNotNullish(resource?.typeId) && Object.values(practitionerResourceNames).includes(resource.typeId)
}

export function getSurgeons(resources: (SurgeryResource | null | undefined)[] | null | undefined): MinimalPractitioner[] {
    return resources?.filter(isMainSurgeon).map(toPractitioner) ?? []
}

export function getAssistants(resources: (SurgeryResource | null | undefined)[] | null | undefined): MinimalPractitioner[] {
    return resources?.filter(isAssistant).map(toPractitioner) ?? []
}
export function getSurgeonsAndAssistants(resources: (SurgeryResource | null | undefined)[] | null | undefined): MinimalPractitioner[] {
    const surgeons = getSurgeons(resources)
    const assistants = getAssistants(resources)
    return [...surgeons, ...assistants]
}

export function getFirstAssistants(resources: SurgeryResource[] | null | undefined): MinimalPractitioner[] {
    function isFirstAssistant(resource: SurgeryResource): resource is SurgeryResource & { typeId: PractitionerResourceTypeId } {
        return resource.typeId === surgeryResourceNames.FirstAssistant
    }
    return resources?.filter(isFirstAssistant).map(toPractitioner) ?? []
}

const order: (PractitionerResourceTypeId | undefined)[] = [
    surgeryResourceNames.Surgeon,
    surgeryResourceNames.FirstAssistant,
    surgeryResourceNames.SecondAssistant,
    surgeryResourceNames.Assistant,
    undefined,
]

export function comparePractitionerOrder(a: PractitionerResourceTypeId | undefined, b: PractitionerResourceTypeId | undefined): number {
    return order.indexOf(a) - order.indexOf(b)
}

export function getUniquePractitioners(practitioners: MinimalPractitioner[]) {
    const uniquePractitioners = uniqBy(
        practitioners.filter(p => !!p.short_name),
        'short_name'
    ).map(p => ({ ...p, short_name: p.short_name.toLocaleUpperCase() }))

    return uniquePractitioners.sort((a, b) => {
        const comparison = comparePractitionerOrder(a.practitionerType, b.practitionerType)
        return comparison !== 0 ? comparison : a.short_name.localeCompare(b.short_name)
    })
}
