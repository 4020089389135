import { PillsSelect } from '~/components'
import { Option, selectASAValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { shouldShowASAColumn } from '../../shared/utils'

const ASAOptions: Option[] = [
    { label: 'ASA I', value: 'ASA I' },
    { label: 'ASA II', value: 'ASA II' },
    { label: 'ASA III', value: 'ASA III' },
    { label: 'ASA IV', value: 'ASA IV' },
    { label: 'ASA V', value: 'ASA V' },
    // { label: 'ASA VI', value: 'ASA VI' }, // asa 6 means brain dead
]

export const AsaFilter = () => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const asaValues = useStore(selectASAValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'asa', value, label })
        setSelectedPage(1)
    }

    const shouldShowFilter = shouldShowASAColumn(departmentKey)

    if (!shouldShowFilter) {
        return null
    }

    return <PillsSelect title="ASA" values={asaValues} options={ASAOptions} onChange={({ value, label }) => handleOnChange(value, label)} />
}
