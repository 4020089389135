import { ComponentProps, FC } from 'react'

export const HomeIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5405 1.54036C7.79434 1.28652 8.2059 1.28652 8.45974 1.54036L14.4597 7.54036C14.6456 7.72625 14.7012 8.00583 14.6006 8.24872C14.5 8.49161 14.263 8.64998 14.0001 8.64998H13.3168V12.6666C13.3168 13.1927 13.1078 13.6971 12.7359 14.0691C12.3639 14.441 11.8595 14.65 11.3335 14.65H4.66678C4.14077 14.65 3.6363 14.441 3.26435 14.0691C2.89241 13.6971 2.68345 13.1927 2.68345 12.6666V8.64998H2.00012C1.73722 8.64998 1.5002 8.49161 1.39959 8.24872C1.29899 8.00583 1.3546 7.72625 1.5405 7.54036L7.5405 1.54036ZM3.53689 7.38245C3.79623 7.46783 3.98345 7.71204 3.98345 7.99998V12.6666C3.98345 12.8479 4.05544 13.0217 4.18359 13.1498C4.31174 13.278 4.48555 13.35 4.66678 13.35H5.35012V9.99998C5.35012 9.47396 5.55908 8.96949 5.93102 8.59755C6.30297 8.2256 6.80744 8.01664 7.33345 8.01664H8.66678C9.1928 8.01664 9.69726 8.2256 10.0692 8.59755C10.4412 8.96949 10.6501 9.47396 10.6501 9.99998V13.35H11.3335C11.5147 13.35 11.6885 13.278 11.8166 13.1498C11.9448 13.0217 12.0168 12.8479 12.0168 12.6666V7.99998C12.0168 7.71204 12.204 7.46783 12.4633 7.38245L8.00012 2.91921L3.53689 7.38245ZM9.35012 13.35V9.99998C9.35012 9.81874 9.27812 9.64494 9.14997 9.51679C9.02182 9.38864 8.84801 9.31664 8.66678 9.31664H7.33345C7.15222 9.31664 6.97841 9.38864 6.85026 9.51679C6.72211 9.64494 6.65012 9.81874 6.65012 9.99998V13.35H9.35012Z"
            fill="currentColor"
        />
    </svg>
)
