import dayjs from 'dayjs'
import { useState } from 'react'
import { DateRange, DayPickerProps, Mode } from 'react-day-picker'

import { day } from '~/utils/extendedDayjs'

import { HeroDayPicker } from '../HeroDayPicker/HeroDayPicker'

type DiProps = Omit<DayPickerProps, 'mode'> & {
    /**
     * Determines if the calendar should only show weekdays
     */
    weekdaysOnly?: boolean
    /**
     * The selection mode of the date picker
     */
    mode: Mode
    /**
     * The currently selected date range
     */
    selected: DateRange | undefined
    /**
     * Callback fired when a date range is selected
     */
    onSelect: (range: DateRange) => void
}

/**
 * A date picker component that allows the user to select a single week or a range of weeks.
 */
export const HeroWeekPicker = ({ mode, selected, onSelect, ...rest }: DiProps) => {
    const [hoverRange, setHoverRange] = useState<DateRange | undefined>(undefined)

    const rootRenderer = (props: React.HTMLAttributes<HTMLDivElement>) => {
        return <div {...props} onMouseLeave={() => setHoverRange(undefined)} />
    }

    function hasDisabledDatesInRange(date: Date): boolean {
        const { disabled } = rest

        if (selected && Array.isArray(disabled) && disabled.length > 0) {
            return disabled.some(d => day(d as Date).isBetween(dayjs.min(day(selected.from), day(date)), dayjs.max(day(selected.to), day(date))))
        }

        return false
    }

    function getSelectedRange(date: Date): DateRange {
        // Returns selected week if mode is 'single', there is no previous selection or the range contains any disabled dates
        if (mode === 'single' || !selected?.from || !selected?.to || hasDisabledDatesInRange(date)) {
            return {
                from: day(date).startOf('isoWeek').toDate(),
                to: day(date).endOf('isoWeek').toDate(),
            }
        }

        // Returns only first week if selected twice
        if (day(selected.to).isSame(date, 'isoWeek')) {
            return {
                from: day(selected.to).startOf('isoWeek').toDate(),
                to: day(selected.to).endOf('isoWeek').toDate(),
            }
        }

        // Returns only last week if selected twice
        if (day(selected.from).isSame(date, 'isoWeek')) {
            return {
                from: day(selected.from).startOf('isoWeek').toDate(),
                to: day(selected.from).endOf('isoWeek').toDate(),
            }
        }

        // Shortens the selected weeks when selecting a week in between
        if (day(date).isBetween(selected.from, selected.to, 'week')) {
            const dateToCompare = day(date).isoWeekday(1).toDate()
            const fromDiff = day(dateToCompare).diff(selected.from, 'days')
            const toDiff = day(dateToCompare).diff(selected.to, 'days')

            return {
                from: fromDiff < toDiff ? day(date).startOf('isoWeek').toDate() : selected.from,
                to: toDiff <= fromDiff ? day(date).endOf('isoWeek').toDate() : selected.to,
            }
        }
        // Extends the selected weeks when selecing a preceding or subsequent week
        return {
            from: day(date).isBefore(selected.from) ? day(date).startOf('isoWeek').toDate() : selected.from,
            to: day(date).isAfter(selected.to) ? day(date).endOf('isoWeek').toDate() : selected.to,
        }
    }

    function getSelectionDiff(date: Date): DateRange | undefined {
        const weekRange = {
            from: day(date).startOf('isoWeek').toDate(),
            to: day(date).endOf('isoWeek').toDate(),
        }
        // Returns hovered week if mode is 'single', there is no previous selection or the range contains any disabled dates
        if (mode === 'single' || !selected?.from || !selected?.to || hasDisabledDatesInRange(date)) {
            return weekRange
        }

        // No difference between selected and hovered week
        if ((weekRange?.from === selected?.from && weekRange?.to === selected?.to) || day(date).isBetween(selected?.from, selected?.to, 'day', '[]')) {
            return undefined
        }

        // Extends start of range when hovering over preceding weeks
        if (day(date).isBefore(selected?.from)) {
            return {
                from: day(date).startOf('isoWeek').toDate(),
                to: day(selected?.from).add(-1, 'day').toDate(),
            }
        }

        // Extends end of range when hovering over subsequent weeks
        if (day(date).isAfter(selected?.to)) {
            return {
                from: day(selected?.to).add(1, 'day').toDate(),
                to: day(date).endOf('isoWeek').toDate(),
            }
        }

        return undefined
    }

    function handleSelect(selected: DateRange | undefined, triggerDate: Date): void {
        onSelect(getSelectedRange(triggerDate))
    }

    function handleOnMouseEnter(date: Date): void {
        setHoverRange(getSelectionDiff(date))
    }

    return (
        <HeroDayPicker
            mode="range"
            selected={selected}
            onSelect={handleSelect}
            onDayMouseEnter={handleOnMouseEnter}
            numberOfMonths={2}
            fixedWeeks={false}
            showOutsideDays={false}
            modifiers={{
                hoverRange,
                hoverRangeStart: hoverRange?.from,
                hoverRangeEnd: hoverRange?.to,
                selectedRange: selected,
                selectedRangeStart: selected?.from,
                selectedRangeEnd: selected?.to,
            }}
            components={{
                Root: rootRenderer,
            }}
            {...rest}
        />
    )
}
