import { useConfirmDialog } from '~/components'

import { DatePractitionerCell } from '../utils'
import { useCreateKeyboardShortcuts } from './useCreateKeyboardShortcuts'

type Props = {
    selectedCells: DatePractitionerCell[]
}

export const KeyboardInteraction = ({ selectedCells }: Props) => {
    const [confirm, AlertDialog] = useConfirmDialog()
    useCreateKeyboardShortcuts({ selectedCells, confirm })

    return <AlertDialog />
}
