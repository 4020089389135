import { useMemo } from 'react'

import { Statuses } from '~/app/pages/WaitingList/shared/Statuses'
import { formatStatuses } from '~/app/pages/WaitingList/shared/utils'
import { Tag } from '~/components'
import { ChildIcon, ClockFastIcon } from '~/icons'
import { selectGetAgeGroups, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getPatientAgeGroup } from '~/store/utils/patientHelpers'
import { formatPersonalId, getAge } from '~/utils/dips'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'

export function BookingPatientCard({ surgery }: { surgery: UnScheduledSurgery }) {
    const ageGroups = useStore(state => state.di.entities.ageGroups)
    const getAgeGroup = useStore(selectGetAgeGroups)
    const { asa, dayOvernight } = useMemo(() => getFormattedSurgery(surgery), [surgery])
    const patientAgeGroup = surgery.patient && ageGroups ? getPatientAgeGroup(surgery.patient, Object.values(ageGroups.byId)) : undefined
    const isShortNotice = surgery.contact?.isShortNotice
    const ageGroup = getAgeGroup.byCode(patientAgeGroup?.age_group_code)
    const statuses = formatStatuses(surgery)

    return (
        <div className="flex flex-col gap-2 rounded border p-3 pt-2">
            <div className="flex gap-2">
                <div className="font-semibold">
                    {surgery.patient?.firstName} {surgery.patient?.lastName}
                </div>
                <div className="grow">
                    {getAge(surgery.patient)} år - {formatPersonalId(surgery.patient?.nationalId ?? '')}
                </div>
                <div>{surgery.patient?.municipality}</div>
            </div>

            <div className="font-normal text-gray-700">{surgery.contact?.coordinationComment ?? ''}</div>

            <div className="flex gap-2">
                <div className="align-center flex grow gap-1">
                    {asa && (
                        <Tag color="ASA" textSize="sm">
                            {asa}
                        </Tag>
                    )}
                    {isShortNotice && (
                        <Tag color="ASA" textSize="sm">
                            <ClockFastIcon />
                            Kort Varsel
                        </Tag>
                    )}
                    {patientAgeGroup && patientAgeGroup.age_group_code === 'child' && (
                        <Tag color="ASA" textSize="sm">
                            <ChildIcon />
                            {ageGroup?.display_name || 'Barn'}
                        </Tag>
                    )}
                    <Tag color="none" textSize="sm">
                        {dayOvernight?.icon} {dayOvernight?.label}
                    </Tag>
                </div>
                <div>
                    <Statuses statuses={statuses} isScheduledItem={true} />
                </div>
            </div>
        </div>
    )
}
