import { ReactNode } from 'react'

import { CircleCheckIcon, CircleDashedIcon, ProgressHalfIcon } from '~/icons'
import { PlanningPeriod } from '~/store/selectors/entities'

import { getProgressStatus, ProgressStatus } from './utils'

const icon: Record<ProgressStatus, ReactNode> = {
    completed: <CircleCheckIcon className="text-[#30A46C]" />,
    current: <ProgressHalfIcon className="text-[#1EA7FF]" />,
    future: <CircleDashedIcon className="text-[#80838D]" />,
}

type Props = {
    planningPeriod: PlanningPeriod
}

export function ProgressIcon({ planningPeriod }: Props) {
    return icon[getProgressStatus(planningPeriod)] ?? null
}
