import { BlockSchedule, GetResolvedSurgeryTypeGroup, GetSelectRuleDefinitionOptions } from '~/store/selectors'
import { RuleInstanceCreate } from '~/store/utils/blockEvaluation'

import { rulesResolver } from '../../blockEvaluation'
import { day, getToday } from '../../extendedDayjs'
import { BlockUpdatedData } from '../analytics'

export function getUpdateBlockScheduleEventData(
    blockSchedules: BlockSchedule[],
    ruleInstance: RuleInstanceCreate,
    getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup,
    getRuleDefinitions: GetSelectRuleDefinitionOptions
): BlockUpdatedData {
    const blocks = blockSchedules.map(block => {
        // Previous rule instances and type is sourced from the rule definition of the block
        const previous_rule_type = getRuleDefinitions.find(rule => rule.value === block.rule_instance?.rule_definition_id)?.label ?? ''
        const previous_rule_instances = rulesResolver({ rules: block?.rule_instance, getSurgeryTypeGroup })

        // Updated rule type is sourced from the updated rule instance
        const updated_rule_type = getRuleDefinitions.find(rule => rule.value === ruleInstance?.rule_definition_id)?.label ?? ''
        const updated_rule_instances = rulesResolver({ rules: ruleInstance, getSurgeryTypeGroup })

        return {
            date: block?.date ?? '',
            room_code: block.location?.room_code ?? '',
            number_of_days_before_start: day(block?.start_time).diff(getToday(), 'day'),
            previous_rule_type,
            updated_rule_type,
            previous_rule_instances,
            updated_rule_instances,
        }
    })

    return { numberOfEdits: blocks.length, blocks }
}
