import { ComponentProps, FC } from 'react'

export const CaretUpIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9.58579 9.33334C10.4767 9.33334 10.9229 8.2562 10.2929 7.62624L8.70711 6.04045C8.31658 5.64993 7.68342 5.64993 7.29289 6.04045L5.70711 7.62624C5.07714 8.2562 5.52331 9.33334 6.41421 9.33334H9.58579Z"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
