import { useMemo } from 'react'

import { getPractitionerTypePrefix, getUniquePractitioners, MinimalPractitioner } from '~/utils/dips'

import { PractitionerTag } from './PractitionerTag'
import { TagProps } from './Tag'

type Props = {
    practitioners: MinimalPractitioner[]
    withPrefix?: boolean
    color?: TagProps['color']
    size?: TagProps['size']
}

export const PractitionerTags = ({ practitioners, withPrefix = false, color = 'practitioner', size = 'xs' }: Props) => {
    const uniquePractitioners = useMemo(() => getUniquePractitioners(practitioners), [practitioners])

    if (uniquePractitioners.length === 0) return null

    return (
        <div className="flex flex-wrap gap-1">
            {uniquePractitioners.map(p => (
                <PractitionerTag
                    key={p.short_name}
                    shortName={p.short_name}
                    fullName={p.name ?? p.short_name}
                    prefix={withPrefix && p.practitionerType ? getPractitionerTypePrefix(p.practitionerType) : undefined}
                    color={color}
                    size={size}
                />
            ))}
        </div>
    )
}
