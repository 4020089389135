import { isCountBasedRuleEvaluation, OccupancyData } from '~/store/selectors'

import { hasMatchingSurgeryType } from './matchesSurgeryType'

/**
 * Gets whether the specified occupancy has space for a surgery of the specified type; or for any if no type was specified.
 */
export function occupancyHasAvailabilityForSurgery(occupancy: OccupancyData, surgeryType: { id: number } | undefined): boolean {
    if (surgeryType === undefined) {
        return occupancy.evaluations.some(rule => isCountBasedRuleEvaluation(rule) && rule.status === 'Available')
    } else {
        return occupancy.evaluations.some(rule => isCountBasedRuleEvaluation(rule) && rule.status === 'Available' && hasMatchingSurgeryType(rule, surgeryType))
    }
}
