import { Dayjs } from 'dayjs'

import { getNow } from './getNow'

/**
 * Retrieves the current date using the Dayjs library.
 * It first checks if the VITE_TODAY environment variable is set,
 * and if so, uses it to create a new instance of Dayjs.
 * If the environment variable is not set or is invalid,
 * it falls back to creating a new instance based on the current system date.
 *
 * @returns {Dayjs} - The current date as an instance of Dayjs.
 */
export function getToday(): Dayjs {
    return getNow().startOf('day')
}
