import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'

import { Button, Show } from '~/components'
import env from '~/env'
import { useUnScheduledSuggestions } from '~/hooks/booking/useUnScheduledSuggestions'
import { HelpIcon } from '~/icons'
import { OccupancyData, UnScheduledSurgery } from '~/store/selectors'
import { selectPlanningPeriods } from '~/store/selectors/planningPeriods'
import { useStore } from '~/store/store'
import { trackEvent } from '~/utils/analytics'
import { MinimalPractitioner } from '~/utils/dips'

import { AvailableCard } from './AvailableCard'
import { BookingDialog } from './BookingDialog/BookingDialog'
import { EmptyItem } from './EmptyItem'
import { LoadingCard } from './LoadingCard'
import { SuggestionsInfoCard } from './SuggestionsInfoCard'
import { SurgeryCard } from './SurgeryCard'
import { SurgerySearchDialog } from './SurgerySearchDialog'

const MAX_LIST_LENGTH = 5

type Props = {
    occupancyData: OccupancyData | null
    scheduledPractitioners: MinimalPractitioner[]
    date: Dayjs
}

export const BookingWaitingList = ({ scheduledPractitioners, occupancyData, date }: Props) => {
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)
    const [showSuggestionsInfo, setShowSuggestionsInfo] = useState<boolean>(false)
    const [selectedSurgery, setSelectedSurgery] = useState<UnScheduledSurgery>()
    const [displayedSuggestionsCount, setDisplayedSuggestionsCount] = useState<number>(MAX_LIST_LENGTH)
    const { unScheduledSuggestions, suggestionCount, isLoading } = useUnScheduledSuggestions({
        scheduledPractitioners,
        occupancyData,
    })
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

    const onCloseDialog = () => {
        setShowBookingDialog(false)
        setSelectedSurgery(undefined)
    }

    const hasSuggestions = suggestionCount > 0

    const getPlanningPeriods = useStore(selectPlanningPeriods)

    const { is_published: periodIsPublished } = getPlanningPeriods.byDate(date) ?? { is_published: true }

    const hideAvailability = !periodIsPublished

    const displayedSuggestions = unScheduledSuggestions.slice(0, displayedSuggestionsCount)

    const sensorRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            const observer = new IntersectionObserver(
                entries => {
                    if (entries[0]?.isIntersecting) {
                        setDisplayedSuggestionsCount(prev => prev + MAX_LIST_LENGTH)
                    }
                },
                { threshold: 0 }
            )

            observer.observe(node)
        }
    }, [])

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
                <p className="flex items-center gap-2" data-test="suggestion-count" data-count={suggestionCount} data-is-loading={isLoading}>
                    <span className="text-base">Mulige pasienter</span>
                    <span className="rounded-lg bg-gray-100 px-2">{suggestionCount}</span>
                    <Show condition={hasSuggestions}>
                        <Button size="icon" color="custom" onClick={() => setShowSuggestionsInfo(prev => !prev)}>
                            <HelpIcon />
                        </Button>
                    </Show>
                </p>
                {env.VITE_FF_OPERATIONAL_PLANNER_BOOKING_SEARCH_SURGERIES && (
                    <div className="flex gap-4">
                        <Button className="border border-gray-200 bg-gray-100" color="custom" onClick={() => setShowSearchModal(true)}>
                            Søk etter pasient
                        </Button>
                    </div>
                )}
            </div>
            {hasSuggestions && showSuggestionsInfo && <SuggestionsInfoCard onClose={() => setShowSuggestionsInfo(false)} />}
            <div className="flex flex-col" data-test="BookingDrawerAvailability">
                {occupancyData && !hideAvailability && (
                    <>
                        <AvailableCard occupancyData={occupancyData} />
                        <div data-test="booking-drawer-available-loaded" className="hidden" />
                    </>
                )}
            </div>
            <Show condition={!isLoading} fallback={<LoadingCard />}>
                {hasSuggestions ? (
                    <>
                        {displayedSuggestions.map(surgery => (
                            <SurgeryCard
                                surgery={surgery}
                                key={surgery.bookingId}
                                onClick={surg => {
                                    void trackEvent('OP_SUGGESTION__CLICK', {
                                        bookingId: surg.bookingId,
                                        index: unScheduledSuggestions.findIndex(s => s.bookingId === surg.bookingId),
                                    })
                                    setSelectedSurgery(surg)
                                    setShowBookingDialog(true)
                                }}
                            />
                        ))}
                        {displayedSuggestionsCount < suggestionCount && <div className="h-48" ref={sensorRef} />}
                    </>
                ) : (
                    <EmptyItem>
                        <p>
                            Vi fant ingen pasienter som passer disse operasjonstypene på ventelisten
                            {scheduledPractitioners.length > 0 && ' til '}
                            {scheduledPractitioners.length > 0 && (
                                <span className="font-semibold">{scheduledPractitioners.map(prac => prac.short_name).join(', ')}</span>
                            )}{' '}
                            eller med uspesifisert kirurg.
                        </p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-waiting-list-loaded" className="hidden" />
            </Show>
            {selectedSurgery && occupancyData && (
                <BookingDialog
                    key={selectedSurgery.bookingId}
                    isOpen={showBookingDialog}
                    onCloseDialog={onCloseDialog}
                    surgery={selectedSurgery}
                    occupancyData={occupancyData}
                />
            )}
            <SurgerySearchDialog
                isOpen={showSearchModal}
                onClose={() => setShowSearchModal(false)}
                onClickSurgery={surgery => {
                    setSelectedSurgery(surgery)
                    setShowSearchModal(false)
                    setShowBookingDialog(true)
                }}
            />
        </div>
    )
}
