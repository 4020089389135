import { useMemo } from 'react'

import { PractitionerTags } from '~/components'
import { AnyScheduledSurgery } from '~/utils/dips'
import { getFormattedSurgery } from '~/utils/transformations/surgeryInfo'

import { Statuses } from '../../WaitingList/shared/Statuses'
import { formatStatuses } from '../../WaitingList/shared/utils'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

type Props = {
    surgery: AnyScheduledSurgery
}

export const SurgeryRow = ({ surgery }: Props) => {
    const statuses = formatStatuses(surgery)
    const { checkInTime, practitioners } = useMemo(() => getFormattedSurgery(surgery), [surgery])

    return (
        <div className="grid grid-cols-[auto_1fr_auto] gap-1 whitespace-nowrap" data-test="ScheduledSurgery">
            <div className="flex items-center text-xs">
                <span className="flex items-center gap-0.5 rounded-full bg-gray-100 px-1.5 text-gray-800" data-tooltip="Oppmøtetid">
                    {checkInTime}
                </span>
            </div>
            <div className="grid grid-cols-[auto_1fr] gap-1">
                <div className="flex items-center">
                    <PractitionerTags practitioners={practitioners} withPrefix={true} />
                </div>
                <div className="relative mt-[1px] overflow-hidden text-ellipsis whitespace-nowrap">{surgery.surgeryType?.name ?? 'Ingen operasjonstype!'}</div>
            </div>
            <div>
                <Statuses statuses={statuses} isScheduledItem={true} />
            </div>
        </div>
    )
}
