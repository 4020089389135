import { ComponentProps, FC } from 'react'

export const UserIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.65486 2.3214C6.27686 1.69941 7.12046 1.34998 8.0001 1.34998C8.87973 1.34998 9.72334 1.69941 10.3453 2.3214C10.9673 2.9434 11.3168 3.78701 11.3168 4.66664C11.3168 5.54628 10.9673 6.38988 10.3453 7.01188C9.72334 7.63388 8.87973 7.98331 8.0001 7.98331C7.12046 7.98331 6.27686 7.63388 5.65486 7.01188C5.03286 6.38988 4.68343 5.54628 4.68343 4.66664C4.68343 3.78701 5.03286 2.9434 5.65486 2.3214ZM8.0001 2.64998C7.46524 2.64998 6.9523 2.86245 6.5741 3.24064C6.1959 3.61884 5.98343 4.13179 5.98343 4.66664C5.98343 5.2015 6.1959 5.71444 6.5741 6.09264C6.9523 6.47084 7.46524 6.68331 8.0001 6.68331C8.53495 6.68331 9.0479 6.47084 9.4261 6.09264C9.8043 5.71444 10.0168 5.2015 10.0168 4.66664C10.0168 4.13179 9.8043 3.61884 9.4261 3.24064C9.0479 2.86245 8.53495 2.64998 8.0001 2.64998ZM6.66676 10.65C6.13191 10.65 5.61896 10.8624 5.24077 11.2406C4.86257 11.6188 4.6501 12.1318 4.6501 12.6666V14C4.6501 14.359 4.35908 14.65 4.0001 14.65C3.64111 14.65 3.3501 14.359 3.3501 14V12.6666C3.3501 11.787 3.69953 10.9434 4.32153 10.3214C4.94352 9.69941 5.78713 9.34998 6.66676 9.34998H9.33343C10.2131 9.34998 11.0567 9.69941 11.6787 10.3214C12.3007 10.9434 12.6501 11.787 12.6501 12.6666V14C12.6501 14.359 12.3591 14.65 12.0001 14.65C11.6411 14.65 11.3501 14.359 11.3501 14V12.6666C11.3501 12.1318 11.1376 11.6188 10.7594 11.2406C10.3812 10.8624 9.86828 10.65 9.33343 10.65H6.66676Z"
            fill="currentColor"
        />
    </svg>
)
