import { useMemo } from 'react'

import { MultipleSelectWithTitle } from '~/components'
import { selectSurgeryTypeGroup } from '~/store/selectors'
import { selectSurgeryCategoryValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

import { selectAllWaitingListItems } from '../../selectors/waitingListItems'
import { getSurgeryCategoryName } from '../../shared/utils'

function useSurgeryCategoryOptions() {
    const surgeries = useStore(selectAllWaitingListItems)
    const getSurgeryTypeGroup = useStore(selectSurgeryTypeGroup)

    const surgeryCategoryOptions = useMemo(() => {
        const surgeryCategoryNames = surgeries.map(surgery => getSurgeryCategoryName(surgery, getSurgeryTypeGroup)).filter(Boolean)
        const uniqueSurgeryCategoryNames = Array.from(new Set(surgeryCategoryNames))

        return uniqueSurgeryCategoryNames.map(name => ({ value: name, label: name })).sort((a, b) => a.label.localeCompare(b.label))
    }, [getSurgeryTypeGroup, surgeries])

    return surgeryCategoryOptions
}

export const SurgeryCategoryFilter = () => {
    const options = useSurgeryCategoryOptions()
    const selectedValues = useStore(selectSurgeryCategoryValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const removeAllFilterByType = useStore(state => state.appFilters.actions.removeAllFilterByType)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'surgery-category', value, label })
        setSelectedPage(1)
    }

    const handleEmptyFilter = () => {
        removeAllFilterByType('surgery-category')
        setSelectedPage(1)
    }

    return (
        <MultipleSelectWithTitle
            title="Operasjonstype kategori"
            values={selectedValues}
            options={options}
            onChange={({ value, label }) => handleOnChange(value, label)}
            emptyFilter={handleEmptyFilter}
        />
    )
}
