import { useEffect } from 'react'

import client from '~/clients/api-client'
import { useStore } from '~/store/store'

import { day } from './extendedDayjs'

const cache = new Map<number, string[]>()

async function fetchHolidays(year: number) {
    if (cache.has(year)) return

    const { data } = await client.getHolidaysPerYear(year)

    if (!data) return

    cache.set(year, data)
}

export function useFetchHolidaysPerYear() {
    const selectedDate = useStore(state => state.appFilters.selectedDate)
    const year = selectedDate.year()

    useEffect(() => {
        void fetchHolidays(year)
    }, [year])
}

export function isHoliday(...args: Parameters<typeof day>) {
    const date = day(...args)

    const year = date.year()
    const holidays = cache.get(year)

    return holidays?.includes(date.format('YYYY-MM-DD')) ?? false
}
