import { Dayjs } from 'dayjs'

import { ActionsList } from '~/components'
import { CalendarRepeatIcon, CheckIcon, EditIcon } from '~/icons'
import { Location, selectGetBlockLocks, selectGetOccupancies } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNullish } from '~/utils/guards'

import { hasAnyAvailability, hasAnyBooking } from '../utils'
import { useToggleBlockLock } from './useToggleBlockLock'

type Props = {
    date: Dayjs
    location: Location
    setShowCapacityEditor: (show: boolean) => void
    hide: () => void
}

export const ActionsMenu = ({ date, location, setShowCapacityEditor, hide }: Props) => {
    const { toggleBlockLock } = useToggleBlockLock()
    const occupancyData = useStore(selectGetOccupancies).byDateAndLocation(date, location)
    const blockLock = useStore(selectGetBlockLocks).byDateAndLocationId(date, location.id)

    if (!occupancyData || !date || !location) {
        return null
    }

    const hasBookingList = hasAnyBooking(occupancyData)
    const hasAvailabilities = hasAnyAvailability(occupancyData)
    const isBlockLocked = Boolean(blockLock)

    const hideBlockLock = isNullish(occupancyData.blockSchedule) || (hasBookingList && !hasAvailabilities)

    function openCapacityEditor() {
        requestAnimationFrame(() => setShowCapacityEditor(true))
    }

    return (
        <ActionsList
            items={[
                !hideBlockLock
                    ? {
                          icon: isBlockLocked ? <CalendarRepeatIcon /> : <CheckIcon />,
                          element: isBlockLocked ? 'Åpne stue for booking' : 'Marker stue som full',
                          onClick: () => {
                              void toggleBlockLock({ location, date, lockId: blockLock?.id, isBlockLocked })
                              hide()
                          },
                      }
                    : undefined,
                {
                    icon: <EditIcon />,
                    element: <div data-test="change">Endre kapasitet</div>,
                    onClick: openCapacityEditor,
                },
            ].filter(Boolean)}
        />
    )
}
