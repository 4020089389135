/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import {
    MaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxCountRuleCreate,
    MaxDurationOfSurgeriesPerSurgeryTypeGroup,
    MaxDurationRuleCreate,
    MaxWeightOfSurgeriesPerSurgeryTypeGroup,
    MaxWeightRuleCreate,
} from '~/clients/derived-api-types'
import { isNullish } from '~/utils/guards'

export type RuleDefinitionId =
    | 'max_count_of_surgeries_per_patient_group'
    | 'max_weight_of_surgeries_per_patient_group'
    | 'max_duration_of_surgeries_per_patient_group'

export function isRuleDefinitionId(ruleId: string | undefined | null): ruleId is RuleDefinitionId {
    if (isNullish(ruleId)) {
        return false
    }

    return ['max_count_of_surgeries_per_patient_group', 'max_weight_of_surgeries_per_patient_group', 'max_duration_of_surgeries_per_patient_group'].includes(
        ruleId
    )
}

type RuleInstanceCreateParameters = MaxCountOfSurgeriesPerSurgeryTypeGroup | MaxWeightOfSurgeriesPerSurgeryTypeGroup | MaxDurationOfSurgeriesPerSurgeryTypeGroup

export type RuleInstanceParameters = RuleInstanceCreateParameters | undefined | null

export type RuleInstanceCreate = MaxCountRuleCreate | MaxWeightRuleCreate | MaxDurationRuleCreate | undefined | null

export function createRuleInstance(localRuleId: string, ruleParameters: RuleInstanceCreateParameters): RuleInstanceCreate | null {
    switch (localRuleId) {
        case 'max_count_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxCountRuleCreate
        case 'max_weight_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxWeightRuleCreate
        case 'max_duration_of_surgeries_per_patient_group':
            return {
                name: '',
                rule_definition_id: localRuleId,
                parameters: ruleParameters,
            } as MaxDurationRuleCreate
        default:
            return null
    }
}
