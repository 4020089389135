import { offset, useFloating, useTransitionStyles } from '@floating-ui/react'
import { useMemo, useState } from 'react'

import { Show } from '~/components'

import { useAutoHide } from './useAutoHide'

export const useCopyToClipBoard = ({ copyText }: { copyText: string }) => {
    const [copyMessage, setCopyMessage] = useState('')
    const [showCopyMessage, setShowCopyMessage] = useAutoHide()

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(copyText)
            setCopyMessage('Kopiert!')
        } catch (err) {
            setCopyMessage('Klarte ikke å kopiere!')
        } finally {
            setShowCopyMessage()
        }
    }

    const { refs, floatingStyles, context } = useFloating({
        placement: 'top',
        open: showCopyMessage,
        middleware: [offset(5)],
    })

    const { isMounted, styles } = useTransitionStyles(context)

    const CopyNotice = useMemo(() => {
        return (
            <Show condition={isMounted}>
                <div ref={refs.setFloating} className="z-priority" style={{ ...floatingStyles, ...styles }}>
                    <span className="rounded bg-black px-3 py-2 text-xs text-white shadow">{copyMessage}</span>
                </div>
            </Show>
        )
    }, [copyMessage, floatingStyles, isMounted, refs.setFloating, styles])

    return { refs, copyToClipboard, CopyNotice }
}
