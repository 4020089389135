import { immer } from 'zustand/middleware/immer'

import { DipsSchemas } from '~/clients/dips-client'

import { UnifiedScheduledSurgeryWithId, UnScheduledSurgeryWithBookingId } from '../dips-entity.api'
import { Slice } from '../store'

type EntityTable<K extends string | number, T> = {
    byId: Record<K, T>
    allIds: K[]
} & Record<string, unknown>

function createEntityState<K extends string | number, T>(): EntityTable<K, T> {
    return {
        byId: {} as Record<K, T>,
        allIds: [] as K[],
    }
}

const entityStates = {
    scheduledSurgeries: createEntityState<string, UnifiedScheduledSurgeryWithId>(),
    unScheduledSurgeries: createEntityState<string, DipsSchemas['UnScheduledSurgery']>(),
    dipsSurgeryTypes: createEntityState<string, DipsSchemas['CodeListDto']>(),
    dipsDepartments: createEntityState<number, DipsSchemas['DepartmentDto']>(),
    dipsResources: createEntityState<number, DipsSchemas['ResourceDto']>(),
}

export type DipsEntityKey = keyof typeof entityStates

type State = {
    entities: typeof entityStates
}

type Actions = {
    actions: {
        addFullScheduledSurgeries: (entities: UnifiedScheduledSurgeryWithId[]) => void
        removeFullScheduledSurgeries: (ids: string[]) => void
        addUnScheduledSurgeries: (entities: UnScheduledSurgeryWithBookingId[]) => void
        removeUnScheduledSurgeries: (ids: string[]) => void
        removeSurgeryTypes: (ids: number[]) => void
        addDepartments: (entities: DipsSchemas['DepartmentDto'][]) => void
        addResources: (entities: DipsSchemas['ResourceDto'][]) => void
    }
}

export type DipsSlice = {
    dips: State & Actions
}

export const createDipsSlice: Slice<DipsSlice> = immer(set => ({
    dips: {
        entities: entityStates,
        actions: {
            addFullScheduledSurgeries: (entities: UnifiedScheduledSurgeryWithId[]) => {
                set(state => {
                    for (const entity of entities) {
                        state.dips.entities.scheduledSurgeries.byId[entity.id] = entity
                    }
                    state.dips.entities.scheduledSurgeries.allIds = Object.keys(state.dips.entities.scheduledSurgeries.byId)
                })
            },
            removeFullScheduledSurgeries: (ids: string[]) => {
                set(state => {
                    for (const id of ids) {
                        delete state.dips.entities.scheduledSurgeries.byId[id]
                    }

                    state.dips.entities.scheduledSurgeries.allIds = Object.keys(state.dips.entities.scheduledSurgeries.byId)
                })
            },
            addUnScheduledSurgeries: (entities: UnScheduledSurgeryWithBookingId[]) => {
                set(state => {
                    for (const entity of entities) {
                        state.dips.entities.unScheduledSurgeries.byId[entity.bookingId] = entity
                    }
                    state.dips.entities.unScheduledSurgeries.allIds = Object.keys(state.dips.entities.unScheduledSurgeries.byId)
                })
            },
            removeUnScheduledSurgeries: (ids: string[]) => {
                set(state => {
                    for (const id of ids) {
                        delete state.dips.entities.unScheduledSurgeries.byId[id]
                    }

                    state.dips.entities.unScheduledSurgeries.allIds = Object.keys(state.dips.entities.unScheduledSurgeries.byId)
                })
            },
            removeSurgeryTypes: (ids: number[]) => {
                set(state => {
                    for (const id of ids) {
                        delete state.dips.entities.dipsSurgeryTypes.byId[id]
                    }

                    state.dips.entities.dipsSurgeryTypes.allIds = Object.keys(state.dips.entities.dipsSurgeryTypes.byId)
                })
            },
            addDepartments: (entities: DipsSchemas['DepartmentDto'][]) => {
                set(state => {
                    for (const entity of entities) {
                        state.dips.entities.dipsDepartments.byId[entity.id] = entity
                    }

                    state.dips.entities.dipsDepartments.allIds = Object.keys(state.dips.entities.dipsDepartments.byId).map(Number)
                })
            },
            addResources: (entities: DipsSchemas['ResourceDto'][]) => {
                set(state => {
                    for (const entity of entities) {
                        if (entity.id) {
                            state.dips.entities.dipsResources.byId[entity.id] = entity
                        }
                    }

                    state.dips.entities.dipsResources.allIds = Object.keys(state.dips.entities.dipsResources.byId).map(Number)
                })
            },
        },
    },
}))
