import { ComponentProps, FC } from 'react'

export const PillOffIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.54048 1.54036C1.79432 1.28652 2.20588 1.28652 2.45972 1.54036L10.793 9.87369C10.7932 9.87381 10.7929 9.87357 10.793 9.87369L14.4597 13.5404C14.7136 13.7942 14.7136 14.2058 14.4597 14.4596C14.2059 14.7134 13.7943 14.7134 13.5405 14.4596L10.3334 11.2525L8.11841 13.4676C7.37513 14.196 6.3744 14.6016 5.33372 14.5964C4.29304 14.5911 3.29648 14.1754 2.5606 13.4395C1.82471 12.7036 1.40897 11.707 1.40372 10.6664C1.39846 9.62567 1.80411 8.62497 2.53253 7.88169L2.53712 7.877L4.74753 5.66664L1.54048 2.45959C1.28664 2.20575 1.28664 1.7942 1.54048 1.54036ZM5.66676 6.58588L3.45884 8.79381C2.97152 9.29223 2.70018 9.96264 2.7037 10.6598C2.70723 11.358 2.98614 12.0265 3.47983 12.5202C3.97353 13.0139 4.64211 13.2928 5.34028 13.2964C6.03741 13.2999 6.7078 13.0286 7.20622 12.5413L9.41419 10.3333L5.66676 6.58588ZM8.78726 3.46539L7.45638 4.79626C7.20254 5.0501 6.79099 5.0501 6.53715 4.79626C6.2833 4.54242 6.2833 4.13086 6.53715 3.87702L7.87512 2.53905C8.6184 1.81064 9.61913 1.40501 10.6598 1.41026C11.7005 1.41552 12.6971 1.83126 13.4329 2.56714C14.1688 3.30302 14.5846 4.29958 14.5898 5.34026C14.5951 6.38094 14.1894 7.38165 13.461 8.12493L13.4564 8.12962L12.1231 9.46293C11.8692 9.71677 11.4577 9.71677 11.2038 9.46293C10.95 9.20909 10.95 8.79753 11.2038 8.54369L12.5347 7.21283C13.022 6.7144 13.2933 6.04399 13.2898 5.34683C13.2863 4.64865 13.0074 3.98007 12.5137 3.48638C12.02 2.99269 11.3514 2.71377 10.6532 2.71025C9.9561 2.70672 9.28568 2.97807 8.78726 3.46539Z"
            fill="currentColor"
        />
    </svg>
)
