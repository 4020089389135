import { ComponentProps, FC } from 'react'

export const BookIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.6501 4.38989V11.6419C3.39836 11.3592 4.19433 11.2128 5.0001 11.2128C5.80587 11.2128 6.60184 11.3592 7.3501 11.6419V4.3899C6.61983 4.03283 5.81621 3.84614 5.0001 3.84614C4.18398 3.84614 3.38037 4.03283 2.6501 4.38989ZM8.6501 4.38989V11.6419C9.39836 11.3592 10.1943 11.2128 11.0001 11.2128C11.8059 11.2128 12.6018 11.3592 13.3501 11.6419V4.3899C12.6198 4.03283 11.8162 3.84614 11.0001 3.84614C10.184 3.84614 9.38037 4.03283 8.6501 4.38989ZM8.0001 3.26129C7.0715 2.79189 6.04402 2.54614 5.0001 2.54614C3.83278 2.54614 2.68603 2.85341 1.6751 3.43707C1.47399 3.55318 1.3501 3.76777 1.3501 3.99999V12.6667C1.3501 12.8989 1.47399 13.1135 1.6751 13.2296C1.87621 13.3457 2.12399 13.3457 2.3251 13.2296C3.1384 12.76 4.06098 12.5128 5.0001 12.5128C5.93922 12.5128 6.8618 12.76 7.6751 13.2296C7.87621 13.3457 8.12399 13.3457 8.3251 13.2296C9.1384 12.76 10.061 12.5128 11.0001 12.5128C11.9392 12.5128 12.8618 12.76 13.6751 13.2296C13.8762 13.3457 14.124 13.3457 14.3251 13.2296C14.5262 13.1135 14.6501 12.8989 14.6501 12.6667V3.99999C14.6501 3.76777 14.5262 3.55318 14.3251 3.43707C13.3142 2.85341 12.1674 2.54614 11.0001 2.54614C9.95617 2.54614 8.92869 2.79189 8.0001 3.26129Z"
            fill="currentColor"
        />
    </svg>
)
