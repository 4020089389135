import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import { Show } from '~/components'
import { CloseIcon, SearchIcon } from '~/icons'

type Props = {
    rawNeedle: string
    setNeedle: (value: string) => void
    placeholder?: string
}

export const SearchText = ({ rawNeedle, setNeedle, placeholder = 'Søk' }: Props) => {
    const [focused, setFocused] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (rawNeedle !== '') {
            setFocused(true)
        }
    }, [rawNeedle])

    function handleBlur() {
        if (rawNeedle === '') {
            setFocused(false)
        }
    }

    return (
        <div
            className={clsx(
                'flex cursor-text items-center justify-end gap-2 rounded border py-1 pl-[9px] pr-[7px] transition-all',
                focused ? 'w-64 outline outline-2 outline-indigo-700' : 'w-20 border-gray-300'
            )}
            onClick={() => inputRef.current?.focus()}
        >
            <SearchIcon className="h-5 w-5 shrink-0 text-gray-700" />
            <input
                data-test="search-text"
                className="w-full font-normal outline-none"
                id="search"
                type="text"
                placeholder={focused ? placeholder : 'Søk'}
                value={rawNeedle}
                ref={inputRef}
                onChange={e => setNeedle(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={handleBlur}
            />
            <Show condition={rawNeedle !== ''}>
                <CloseIcon
                    className={clsx('h-5 w-5 shrink-0 cursor-pointer text-gray-700 hover:text-gray-400', rawNeedle === '' ? 'hidden' : 'block')}
                    onClick={() => setNeedle('')}
                />
            </Show>
        </div>
    )
}
