import { ComponentProps, FC } from 'react'

export const CaretDownIcon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.41421 6.66666C5.52331 6.66666 5.07714 7.7438 5.70711 8.37376L7.29289 9.95955C7.68342 10.3501 8.31658 10.3501 8.70711 9.95955L10.2929 8.37376C10.9229 7.7438 10.4767 6.66666 9.58579 6.66666H6.41421Z"
            stroke="currentColor"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
