import { Dayjs, duration } from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import env from '~/env'
import { Location } from '~/store/selectors/entities'

// Temporary hardcoded implementation suffices.
// We can create an entity for it later when it's more apparent what kind of solution we need
const isoMonday = 1
const isoTuesday = isoMonday + 1
const isoWednesday = isoTuesday + 1
const isoThursday = isoWednesday + 1
const isoFriday = isoThursday + 1

function getOpeningHoursLDS(date: Dayjs, location: Location) {
    // from https://www.notion.so/deepinsighio/Opening-hours-at-LDS-1378ab88fe0d80e58b7de1564f531788

    if (location.room_code === 'OPR11' || location.room_code === 'OPR12') {
        return (
            {
                [isoMonday]: 7,
                [isoTuesday]: 7,
                [isoWednesday]: 7,
                [isoThursday]: 6.5,
                [isoFriday]: 6,
            }[date.isoWeekday()] ?? 0
        )
    } else {
        return (
            {
                [isoMonday]: 7.5,
                [isoTuesday]: 7.5,
                [isoWednesday]: 7.5,
                [isoThursday]: 7,
                [isoFriday]: 6.5,
            }[date.isoWeekday()] ?? 0
        )
    }
}

function getOpeningHoursNLSH(date: Dayjs, location: Location) {
    // from https://deepinsight-no.slack.com/archives/C06412J927N/p1736519908503399?thread_ts=1736515728.618859&cid=C06412J927N
    if (location.room_code.startsWith('K2')) {
        if (date.isoWeekday() === isoMonday || date.isoWeekday() === isoWednesday || date.isoWeekday() === isoThursday) {
            return 7.75 // 8:00-15:45
        } else if (date.isoWeekday() === isoTuesday) {
            return 7.25 // 8:30-15:45
        } else if (date.isoWeekday() === isoFriday) {
            if (location.room_code === 'K2 Stue 1') {
                return 4 // 8:30-12:30
            } else if (location.room_code === 'K2 Stue 2') {
                return 3.75 // 12:30-15:45
            } else {
                return 7.25 // 8:30-15:45
            }
        }
    } else if (location.room_code.startsWith('K8')) {
        if (date.isoWeekday() === isoMonday || date.isoWeekday() === isoWednesday || date.isoWeekday() === isoThursday) {
            return 8 // 7:45-15:45
        } else if (date.isoWeekday() === isoTuesday) {
            return 7.5 // 8.15-15:45
        } else if (date.isoWeekday() === isoFriday) {
            return 7.25 // 8.30-15:45
        }
    } else {
        console.error(`Opening hours not known for location '${location.room_code}'`)
    }

    return 0 // weekends
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getMockOpeningHours(date: Dayjs, _location: Location) {
    if (
        date.isoWeekday() === isoMonday ||
        date.isoWeekday() === isoTuesday ||
        date.isoWeekday() === isoWednesday ||
        date.isoWeekday() === isoThursday ||
        date.isoWeekday() === isoFriday
    ) {
        return 7.5
    }
    return 0
}
export function getOpeningHours(date: Dayjs, location: Location, tenant = env.VITE_SEGMENT_TENANT_NAME): Duration {
    let hours: number
    if (tenant && tenant.toLocaleLowerCase() === 'nlsh') {
        hours = getOpeningHoursNLSH(date, location)
    } else if (tenant && tenant.toLocaleLowerCase() === 'lds') {
        hours = getOpeningHoursLDS(date, location)
    } else {
        hours = getMockOpeningHours(date, location)
    }

    return duration({ hours })
}
