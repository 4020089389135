import { ComponentProps, FC } from 'react'

export const Message2Icon: FC<ComponentProps<'svg'>> = props => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.0001 3.31666C3.64206 3.31666 3.29868 3.45889 3.0455 3.71207C2.79233 3.96524 2.6501 4.30862 2.6501 4.66666V10C2.6501 10.358 2.79233 10.7014 3.0455 10.9546C3.29868 11.2078 3.64206 11.35 4.0001 11.35H6.0001C6.17249 11.35 6.33782 11.4185 6.45972 11.5404L8.0001 13.0808L9.54048 11.5404C9.66238 11.4185 9.82771 11.35 10.0001 11.35H12.0001C12.3581 11.35 12.7015 11.2078 12.9547 10.9546C13.2079 10.7014 13.3501 10.358 13.3501 10V4.66666C13.3501 4.30862 13.2079 3.96524 12.9547 3.71207C12.7015 3.45889 12.3581 3.31666 12.0001 3.31666H4.0001ZM2.12626 2.79283C2.62324 2.29586 3.29727 2.01666 4.0001 2.01666H12.0001C12.7029 2.01666 13.377 2.29586 13.8739 2.79283C14.3709 3.2898 14.6501 3.96384 14.6501 4.66666V10C14.6501 10.7028 14.3709 11.3769 13.8739 11.8738C13.377 12.3708 12.7029 12.65 12.0001 12.65H10.2693L8.45972 14.4596C8.20588 14.7135 7.79432 14.7135 7.54048 14.4596L5.73086 12.65H4.0001C3.29727 12.65 2.62324 12.3708 2.12626 11.8738C1.62929 11.3769 1.3501 10.7028 1.3501 10V4.66666C1.3501 3.96384 1.62929 3.2898 2.12626 2.79283ZM4.68343 6C4.68343 5.64101 4.97445 5.35 5.33343 5.35H10.6668C11.0257 5.35 11.3168 5.64101 11.3168 6C11.3168 6.35898 11.0257 6.65 10.6668 6.65H5.33343C4.97445 6.65 4.68343 6.35898 4.68343 6ZM4.68343 8.66666C4.68343 8.30768 4.97445 8.01666 5.33343 8.01666H9.33343C9.69242 8.01666 9.98343 8.30768 9.98343 8.66666C9.98343 9.02565 9.69242 9.31666 9.33343 9.31666H5.33343C4.97445 9.31666 4.68343 9.02565 4.68343 8.66666Z"
            fill="currentColor"
        />
    </svg>
)
