import { Dayjs } from 'dayjs'

import { DipsSchemas, getBaseHeaders, getClient } from '~/clients/dips-client'
import env from '~/env'
import { UnScheduledSurgery } from '~/store/selectors'

const getEnvHeaders = () => {
    if (env.VITE_BOOKING_SHOULD_FAIL) {
        return {
            'x-mock-should-fail': 'true',
            'x-mock-should-have-delay': 'false',
        }
    }
    return {}
}

type ScheduleSurgeryRequest = DipsSchemas['ScheduleSurgeryRequest']

async function bookSurgery(scheduleRequest: ScheduleSurgeryRequest) {
    return await getClient().POST('/api/v1/surgery/schedule', {
        body: scheduleRequest,
        headers: {
            ...getBaseHeaders(),
            ...getEnvHeaders(),
        },
        parseAs: 'text',
    })
}

type SuccessResponse = { isError: false; errorMessage: undefined; sagaId: string }
type ErrorResponse = { isError: true; errorMessage: string; sagaId: undefined }
type Response = SuccessResponse | ErrorResponse

function errorResponse(errorMessage: string): ErrorResponse {
    return { isError: true, errorMessage, sagaId: undefined }
}

export async function startScheduleSurgeryJob(
    waitingListItem: UnScheduledSurgery,
    newCheckInTime: Dayjs,
    plannedTheatreInTime: Dayjs,
    resourceSlots: DipsSchemas['ScheduleSurgeryParams']['surgeryResources']
): Promise<Response> {
    if (!waitingListItem.bookingId || !waitingListItem.contact?.version || !waitingListItem.treatmentLocationId || !waitingListItem.version) {
        return errorResponse('Waitinglist item not valid.')
    }

    if (resourceSlots.length === 0) {
        return errorResponse('No resources selected.')
    }

    const newSagaId = crypto.randomUUID()

    const scheduleRequest: ScheduleSurgeryRequest = {
        scheduleSurgeryParams: [
            {
                sagaId: newSagaId,
                bookingId: waitingListItem.bookingId,
                checkInTime: newCheckInTime.toISOString(),
                plannedTheatreInTime: plannedTheatreInTime.toISOString(),
                contactVersion: waitingListItem.contact.version,
                surgeryResources: resourceSlots,
                treatmentLocationId: waitingListItem.treatmentLocationId,
                version: waitingListItem.version,
            },
        ],
    }

    let response: Awaited<ReturnType<typeof bookSurgery>> | undefined

    try {
        response = await bookSurgery(scheduleRequest)
        const statusInfo = response.response
        // 202 is to handle DIPS prod while 200 is to handle mock DIPS. Mock DIPS should be updated to replicate prod
        if (statusInfo.status !== 202 && statusInfo.status !== 200) {
            // Sending the response to the console to pick it up in Sentry and see what's going on.
            console.info(`Booking request response: ${response.data}`)
            return errorResponse('Hero fikk ikke kontakt med DIPS. Prøv å bestille operasjonen på nytt.')
        }
    } catch (e) {
        if (!(e instanceof Error)) {
            // This is more to satisfy the compiler, but who knows.
            console.error(e)
            return errorResponse('Noe gikk galt. Ta kontakt med en administrator.')
        } else {
            const message = e.message?.trim()

            // Case to handle when parseAs: 'text' doesn't succeed.
            if (message.toLocaleLowerCase() === 'unexpected end of json input') {
                console.error(`Response from DIPS for /api/v1/surgery/schedule was not a valid JSON object. Status code: ${response?.response.status}`)
            } else {
                console.error(message)
                return errorResponse(message)
            }
        }
    }

    return { isError: false, sagaId: newSagaId, errorMessage: undefined }
}
