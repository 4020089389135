import * as Sentry from '@sentry/browser'
import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'

import { findBookedSurgery } from '~/app/pages/WaitingList/hooks/useFindBookedSurgery'
import { DipsSchemas } from '~/clients/dips-client'
import { UnScheduledSurgery } from '~/store/selectors'

import { pollSurgeryBookingStatus } from './pollSurgeryBookingStatus'
import { startScheduleSurgeryJob } from './startScheduleSurgeryJob'

type SagaStatus = 'Uninitialized' | 'Started' | 'Polling' | 'Verifying' | 'Success' | 'Error'

type ScheduleSurgeryParams = {
    /** Original, unchanged waitinglist item */
    waitingListItem: UnScheduledSurgery
    /** When the patient should arrive at the hospital */
    checkInTime: Dayjs
    /** When the patient should be brought into the operating room */
    plannedTheatreInTime: Dayjs
    /** Surgeon, Assistants and TheaterRooms */
    resourceSlots: DipsSchemas['ScheduleSurgeryParams']['surgeryResources']
}

/**
 * The full booking saga for a surgery. Involved three steps:
 * 1. Initial booking request
 * 2. Booking confirmation via polling
 *
 * When all three steps are completed, the booking is considered successful.
 */
export function useScheduleSurgerySaga() {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [sagaStatus, setSagaStatus] = useState<SagaStatus>('Uninitialized')

    const setStarted = useCallback(() => {
        setIsLoading(true)
        setIsError(false)
        setErrorMessage(null)
        setIsSuccess(false)
        setSagaStatus('Started')
    }, [])

    const setError = useCallback((errorMessage: string) => {
        console.error(errorMessage)
        Sentry.captureException(errorMessage)
        setIsLoading(false)
        setIsError(true)
        setErrorMessage(errorMessage)
        setIsSuccess(false)
        setSagaStatus('Error')
    }, [])

    const setSuccess = useCallback(() => {
        setIsLoading(false)
        setIsSuccess(true)
        setSagaStatus('Success')
    }, [])

    const scheduleSurgery = useCallback(
        async ({ checkInTime, plannedTheatreInTime, resourceSlots, waitingListItem }: ScheduleSurgeryParams) => {
            setStarted()

            // Step 1. Start the booking saga
            const step1 = await startScheduleSurgeryJob(waitingListItem, checkInTime, plannedTheatreInTime, resourceSlots)

            if (step1.isError) {
                setError(step1.errorMessage)
                return
            }

            setSagaStatus('Polling')

            // Step 2. Poll the booking saga until it is done
            const step2 = await pollSurgeryBookingStatus(step1.sagaId)

            if (step2.isError) {
                setError(step2.errorMessage)
                return
            }

            setSagaStatus('Verifying')
            // Step 3. Find the the ScheduledSurgery object in DIPS
            const step3 = await findBookedSurgery(waitingListItem.bookingId, checkInTime)
            if (step3.isError) {
                setError(step3.errorMessage)
            } else {
                setSuccess()
            }
        },
        [setError, setStarted, setSuccess]
    )

    return { scheduleSurgery, isLoading, isError, errorMessage, isSuccess, sagaStatus }
}
